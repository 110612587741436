<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">
                    Stack
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-4">
                        <form class="card"  >
                            <div class="card-header">
                                <h3 class="card-title" v-if="status">Add Stack</h3>
                                <h3 class="card-title" v-else>Update Stack</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="stack">Stack *</label>
                                            <input class="form-control form-control-sm" :class="{'is-invalid': errors.stack}" placeholder="Stack" v-model="stack.stack"/>
                                            <span v-if="errors.stack" class="invalid-feedback">{{ errors.stack[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="amount">Amount *</label>
                                            <input class="form-control form-control-sm" :class="{'is-invalid': errors.amount}" placeholder="Amount" v-model="stack.amount"/>
                                            <span v-if="errors.amount" class="invalid-feedback">{{ errors.amount[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="note">Description </label>
                                            <textarea class="form-control form-control-sm" :class="{'is-invalid': errors.description}" placeholder="Description" v-model="stack.description"></textarea>
                                            <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </form>
                    </div>
                      <div class="col-sm-8">
                        <form class="card" @submit.prevent="submitForm()">
                            <div class="card-header">
                                <h3 class="card-title">Denominations</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">Name</th>
                                                    <th class="text-center">Value</th>
                                                    <th class="text-center">Quantity</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(denomination,key) in denominations" :key="key">
                                                    <td class="text-center">{{ denomination.denomination_name }}</td>
                                                    <td class="text-center">{{ denomination.denomination_value }}</td>
                                                    <td>
                                                        <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.quantity}" placeholder="Quantity" v-model="denomination.quantity" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link to="/stacks" class="btn btn-sm btn-outline-danger" type="button"> <i class="fas fa-ban"></i> Discard </router-link>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                        <span v-else > <i class="fas fa-save"></i> Update </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    // import moment from 'moment'
    export default {
        name: "stack.Create",
        data() {
            return {
                status:true,
                stack: {
                    stack_id:"",
                    stack:"",
                    amount:"",
                    description: "",
                    denominations:[]
                },
                denominations: [],
                errors: [],
            };
        },
        computed: {
            amount: function () {
                let vm = this;
                return  vm.denominations.reduce((prev, cur) => +prev + +cur.amount, 0).toFixed(2);
            },
            exchangeAmount: function () {
                let vm = this;
                return  vm.denominations.reduce((prev, cur) => +prev + +cur.exchange_amount, 0).toFixed(2);
            }
        },

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (to.name == "Stacks.Create") {
                    vm.getDenominations();
                }
                else {
                    vm.status = false;
                    let uri = { uri: "showStack", data: { stack_id: to.params.stack_id } };
                    vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        vm.stack = response.data.data;
                        vm.getDenominations();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            });
        },

        methods: {
              submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },

            getDenominations() {
                let vm = this;
                let uri = { uri: "getDenominations"  };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.denominations = response.data.data;
                    if(!vm.status) {
                        vm.setData()
                    }
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            setData() {
                let vm = this;
                vm.denominations.forEach(denomination => {
                    vm.stack.stack_denominations.forEach(stack_denomination => {
                        if(denomination.denomination_id==stack_denomination.denomination_id) {
                            denomination.quantity = stack_denomination.quantity;
                        }
                    });
                });
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.stack.denominations = vm.denominations;
                let uri = { uri: "addStack", data: vm.stack };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Stack is successfully created");
                    vm.$router.push("/stacks");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            update() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.stack.stack_id = this.$route.params.stack_id;
                vm.stack.denominations = vm.denominations;
                let uri = { uri: "updateStack", data: vm.stack };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Stack is successfully updated");
                    vm.$router.push("/stacks");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
        },
    };
</script>
