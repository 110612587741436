<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Monthly Report</li>
                <li class="breadcrumb-right">
                    <a type="button" class="btn btn-sm btn-success" target="_blank"  @click="downloadExcel" :href="$store.getters.apiUrl+'emptyExcel'">
                        <i class="fa fa-download mr-1"></i>Download
                    </a>
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Monthly Report</h3>
                                <div class="card-tools">
                                    <label>Total no of days : {{ total_days }}</label>
                                    <label class="pl-4">Total no of working days : {{ total_working_days }}</label>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="from_date">Month</label>
                                            <input type="month" class="form-control form-control-sm" v-model="meta.month">
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group" style="margin-top: 26px !important;">
                                            <button class="btn btn-sm btn-primary mr-2" @click="index"><i class="fa fa-search mr-1"></i>Search</button>
                                            <button class="btn btn-sm btn-danger" @click="discard"><i class="fa fa-times mr-1"></i>Discard</button>
                                        </div>
                                    </div>
                                </div>

                                <div class="table-responsive">
                                    <table class="table table-bordered table-striped table-sm">
                                        <thead>
                                            <tr>
                                                <th class="text-center">#</th>
                                                <th @click="sort('start')">
                                                    Start Date & Time
                                                    <span>
                                                        <i v-if="meta.keyword=='start' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='start' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th @click="sort('end')">
                                                    End Date & Time
                                                    <span>
                                                        <i v-if="meta.keyword=='end' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='end' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th @click="sort('total_time')">
                                                    Total Time
                                                    <span>
                                                        <i v-if="meta.keyword=='total_time' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='total_time' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th class="text-center" @click="sort('active_tables')">
                                                    Tables
                                                    <span>
                                                        <i v-if="meta.keyword=='active_tables' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='active_tables' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th class="text-center" @click="sort('walkins')">
                                                    Walkins
                                                    <span>
                                                        <i v-if="meta.keyword=='walkins' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='walkins' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th class="text-center" @click="sort('buyins')">
                                                    Total BuyIn
                                                    <span>
                                                        <i v-if="meta.keyword=='buyins' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='buyins' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th class="text-center" @click="sort('avg_buyins')">
                                                    Avg BuyIn
                                                    <span>
                                                        <i v-if="meta.keyword=='avg_buyins' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='avg_buyins' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th class="text-center" @click="sort('experience_fees')">
                                                    Rake
                                                    <span>
                                                        <i v-if="meta.keyword=='experience_fees' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='experience_fees' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th class="text-center" @click="sort('experience_fees_per')">
                                                    Rake %
                                                    <span>
                                                        <i v-if="meta.keyword=='experience_fees_per' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='experience_fees_per' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th class="text-center" @click="sort('expense')">
                                                    Expense
                                                    <span>
                                                        <i v-if="meta.keyword=='expense' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='expense' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th class="text-center" @click="sort('expense_per')">
                                                    Expense %
                                                    <span>
                                                        <i v-if="meta.keyword=='expense_per' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='expense_per' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(session_log,key) in session_logs" :key="key">
                                                <td class="text-center">{{key+1}}</td>
                                                <td>{{ $formatDate(session_log.start, 'india') }}</td>
                                                <td>{{ $formatDate(session_log.end, 'india') }}</td>
                                                <td>{{ session_log.total_time }}</td>
                                                <td class="text-center">{{ session_log.active_tables }}</td>
                                                <td class="text-center">{{ session_log.walkins }}</td>
                                                <td class="text-center">{{ $formatCurrency(session_log.buyins) }}</td>
                                                <td class="text-center">{{ $formatCurrency(session_log.avg_buyins) }}</td>
                                                <td class="text-center">{{ $formatCurrency(session_log.experience_fees) }}</td>
                                                <td class="text-center">{{ session_log.experience_fees_per }}%</td>
                                                <td class="text-center">{{ $formatCurrency(session_log.expense) }}</td>
                                                <td class="text-center">{{ session_log.expense_per }}%</td>

                                            </tr>
                                            <tr>
                                                <th colspan="4" class="text-center">Total</th>
                                                <th class="text-center">{{ totalActiveTables }}</th>
                                                <th class="text-center">{{ totalWalkins }}</th>
                                                <th class="text-center">{{ $formatCurrency(totalBuyins) }}</th>
                                                <th class="text-center">-</th>
                                                <th class="text-center">{{ $formatCurrency(totalExperienceFees) }}</th>
                                                <th class="text-center">{{ totalExperienceFeesPer }}%</th>
                                                <th class="text-center">{{ $formatCurrency(totalExpense) }}</th>
                                                <th class="text-center">{{ totalExpensePer }}%</th>
                                            </tr>
                                            <tr>
                                                <th colspan="4" class="text-center">Average</th>
                                                <th class="text-center">{{ avgActiveTables }}</th>
                                                <th class="text-center">{{ avgWalkins }}</th>
                                                <th class="text-center">{{ $formatCurrency(avgBuyins) }}</th>
                                                <th class="text-center">-</th>
                                                <th class="text-center">{{ $formatCurrency(avgExperienceFees) }}</th>
                                                <th class="text-center">{{ avgExperienceFeesPer }}%</th>
                                                <th class="text-center">{{ $formatCurrency(avgExpense) }}</th>
                                                <th class="text-center">{{ avgExpensePer }}%</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import moment from "moment";
    export default {
        name: "Report.ExperienceReport",
        data() {
            return {
                status: true,
                meta: {
                    branch_id:this.$store.getters.user.branch_id,
                    month:"",
                    order_by: "asc",
                    keyword: "start",
                },
                session_logs:[],
                total_days:0,
                total_working_days:0,
                gst:0,
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.discard();
        },

        methods: {
            index() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getSessionLogsWithMonthlyReport", data: vm.meta })
                .then(function (response) {
                    vm.session_logs = response.data.session_logs;
                    vm.total_days = response.data.total_days;
                    vm.total_working_days = response.data.total_working_days;
                    vm.total_experience_fees = response.data.total_experience_fees;
                    vm.average_experience_fees = response.data.average_experience_fees;
                    vm.forecasted_experience_fees = response.data.forecasted_experience_fees;
                    vm.total_gst = response.data.total_gst;
                    vm.balance_experience_fees = response.data.balance_experience_fees;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            discard() {
                let vm = this;
                vm.meta.month = moment().format('YYYY-MM');
                vm.index()
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },
        },

        computed: {
            totalActiveTables() {
                return this.session_logs.reduce((total, session_log) => {
                    return total + session_log.active_tables;
                }, 0);
            },
            totalWalkins() {
                return this.session_logs.reduce((total, session_log) => {
                    return total + session_log.walkins;
                }, 0);
            },
            totalBuyins() {
                return this.session_logs.reduce((total, session_log) => {
                    return total + session_log.buyins;
                }, 0);
            },
            totalExperienceFees() {
                return this.session_logs.reduce((total, session_log) => {
                    return total + session_log.experience_fees;
                }, 0);
            },
            totalExperienceFeesPer() {
                return this.totalBuyins!=0 ? ((this.totalExperienceFees / this.totalBuyins)*100).toFixed(2) : 0
            },
            totalExpense() {
                return this.session_logs.reduce((total, session_log) => {
                    return total + session_log.expense;
                }, 0);
            },

            totalExpensePer() {
                return this.totalExperienceFees!=0 ? ((this.totalExpense / this.totalExperienceFees)*100).toFixed(2) : 0
            },
            avgActiveTables() {
                return this.total_working_days!=0 ? (this.totalActiveTables / this.total_working_days).toFixed(2) : 0;
            },
            avgWalkins() {
                return this.total_working_days!=0 ? (this.totalWalkins / this.total_working_days).toFixed(2) : 0;
            },
            avgBuyins() {
                return this.total_working_days!=0 ? (this.totalBuyins / this.total_working_days).toFixed(2) : 0;
            },
            avgExperienceFees() {
                return this.total_working_days!=0 ? (this.totalExperienceFees / this.total_working_days).toFixed(2) : 0;
            },
            avgExperienceFeesPer() {
                return this.avgBuyins!=0 ? ((this.avgExperienceFees / this.avgBuyins)*100).toFixed(2) : 0
            },
            avgExpense() {
                return this.total_working_days!=0 ? (this.totalExpense / this.total_working_days).toFixed(2) : 0;
            },

            avgExpensePer() {
                return this.avgExperienceFees!=0 ? ((this.avgExpense / this.avgExperienceFees)*100).toFixed(2) : 0
            },
        },
    };
</script>
