<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-mug-hot"></i>
                        Food & Beverages
                    </a>
                </li>
                <li class="breadcrumb-item active">Tax</li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-5">
                        <form class="card" @submit.prevent="submitForm()">
                            <div class="card-header">
                                <h3 class="card-title" v-if="status">New Tax</h3>
                                <h3 class="card-title" v-else>Update Tax</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="tax_name">Tax Name *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.tax_name}" placeholder="Tax Name" v-model="tax.tax_name" ref="tax_name" />
                                            <span v-if="errors.tax_name" class="invalid-feedback">{{ errors.tax_name[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="tax_value">Tax Value *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.tax_value}" placeholder="Tax Value" v-model="tax.tax_value" />
                                            <span v-if="errors.tax_value" class="invalid-feedback">{{ errors.tax_value[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="cgst_name">CGST Name *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.cgst_name}" placeholder="CGST Name" v-model="tax.cgst_name" />
                                            <span v-if="errors.cgst_name" class="invalid-feedback">{{ errors.cgst_name[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="cgst_value">CGST Value *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.cgst_value}" placeholder="CGST Value" v-model="tax.cgst_value" />
                                            <span v-if="errors.cgst_value" class="invalid-feedback">{{ errors.cgst_value[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="sgst_name">SGST Name *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.sgst_name}" placeholder="SGST Name" v-model="tax.sgst_name" />
                                            <span v-if="errors.sgst_name" class="invalid-feedback">{{ errors.sgst_name[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="sgst_value">SGST Value *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.sgst_value}" placeholder="SGST Value" v-model="tax.sgst_value" />
                                            <span v-if="errors.sgst_value" class="invalid-feedback">{{ errors.sgst_value[0] }}</span>
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="igst_name">IGST Name *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.igst_name}" placeholder="IGST Name" v-model="tax.igst_name" />
                                            <span v-if="errors.igst_name" class="invalid-feedback">{{ errors.igst_name[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="igst_value">IGST Value *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.igst_value}" placeholder="IGST Value" v-model="tax.igst_value" />
                                            <span v-if="errors.igst_value" class="invalid-feedback">{{ errors.igst_value[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button class="btn btn-sm btn-outline-danger" type="button" @click="discard()"><i class="fas fa-ban"></i> Discard</button>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                        <span v-else> <i class="fas fa-save"></i> Update </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-sm-7">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Taxes</h3>
                                <div class="card-tools">
                                    <div class="custom-control custom-switch custom-switch-off-success custom-switch-on-danger">
                                        <input type="checkbox" class="custom-control-input" id="trashed" v-model="meta.trashed" @click="isTrashed" />
                                        <label class="custom-control-label" for="trashed">Trashed</label>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <input class="form-control form-control-sm" type="text" placeholder="Search" v-model="meta.search" @keyup="search" v-if="!meta.trashed" />
                                </div>
                                <div class="table-responsive table-responsive-sm">
                                    <table class="table table-bordered table-striped table-sm text-nowrap">
                                        <thead>
                                            <tr>
                                                <th class="text-center">#</th>
                                                <th>TaxName</th>

                                                <th>CGST Name</th>

                                                <th>SGST Name</th>

                                                <th>IGST Name</th>

                                                <th class="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(tax,key) in taxs" :key="key">
                                                <td class="text-center">{{meta.from+key}}</td>
                                                <td>{{tax.tax_name}}</td>

                                                <td>{{tax.cgst_name}}</td>

                                                <td>{{tax.sgst_name}}</td>

                                                <td>{{tax.igst_name}}</td>

                                                <td class="text-center" v-if="!meta.trashed">
                                                    <button class="btn btn-xs btn-outline-success" data-toggle="tooltip" data-placement="bottom" title="Edit" @click="edit(tax)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <button class="btn btn-xs btn-outline-danger" data-toggle="tooltip" data-placement="bottom" title="Delete" @click="deleteTax(tax)">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                                <td class="text-center" v-else>
                                                    <button class="btn btn-xs btn-outline-success" @click="deleteTax(tax)" data-toggle="tooltip" data-placement="bottom" title="Trash">
                                                        <i class="fas fa-trash-restore"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr v-if="taxs.length <= 0">
                                                <td colspan="20" style="text-align: center;">No Records Found</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Taxs",
        components: { Pagination },
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "tax_name",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },
                tax: {
                    tax_name: "",
                    tax_value: "",
                    cgst_name: "",
                    cgst_value: "",
                    sgst_name: "",
                    sgst_value: "",
                    igst_name: "",
                    igst_value: "",
                },
                taxs: [],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.index();
        },

        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },

            edit(tax) {
                let vm = this;
                (vm.status = false), (vm.tax = tax), vm.$refs.tax.focus();
            },

            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },

            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateTaxes", data: vm.meta })
                    .then(function (response) {
                        vm.taxs = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            isTrashed() {
                let vm = this;
                vm.meta.trashed = !vm.meta.trashed;
                if (vm.meta.trashed) vm.meta.page = 1;
                vm.index();
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "addTax", data: vm.tax };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Tax is successfully created");
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            discard() {
                let vm = this;
                vm.tax = {};
                vm.errors = [];
                vm.status = true;
                vm.index();
            },

            update() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateTax", data: vm.tax };
                vm.$store
                .dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Tax is successfully updated");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            deleteTax(tax) {
                if (confirm("Do you want to continue... ?")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteTax", data: tax };
                    vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Tax is successfully deleted");
                        vm.discard();
                        vm.trashed();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
        },
    };
</script>
