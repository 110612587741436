<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-cogs"></i>
                        Configuration
                    </a>
                </li>
                <li class="breadcrumb-item active">Configuration</li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <form class="card" @submit.prevent="submitForm()">
                            <div class="card-header">
                                <h3 class="card-title">Update Configuration</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="membership_prefix">Membership prefix *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.membership_prefix}" placeholder="Membership prefix" v-model="configuration.membership_prefix" />
                                            <span v-if="errors.membership_prefix" class="invalid-feedback">{{ errors.membership_prefix[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="membership_suffix">Membership suffix *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.membership_suffix}" placeholder="Membership suffix" v-model="configuration.membership_suffix" />
                                            <span v-if="errors.membership_suffix" class="invalid-feedback">{{ errors.membership_suffix[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="membership_length">membership_length </label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.membership_length}" placeholder="Email Address" v-model="configuration.membership_length" />
                                            <span v-if="errors.membership_length" class="invalid-feedback">{{ errors.membership_length[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span> <i class="fas fa-save"></i> Update </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "Proj-Currency.Index",
        data() {
            return {
                configuration: {
                    configurtion_id: "",
                    membership_prefix: "",
                    membership_suffix: "",
                    membership_length: "",
                },
                errors: [],
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store
                    .dispatch("post", { uri: "showConfiguration" })
                    .then(function (response) {
                        vm.configuration = response.data.data;
                        vm.$refs.name.focus();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            });
        },

        methods: {
            submitForm() {
                let vm = this;
                {
                    vm.update();
                }
            },

            update() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateConfiguration", data: vm.configuration };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Configuration is successfully updated");
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            onImageChange(e) {
                e.preventDefault();
                let vm = this;
                let image = e.target.files[0];
                let reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = (e) => {
                    vm.configuration.logo = e.target.result;
                };
            },
        },
    };
</script>
