<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">
                    Customer
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <form class="card" @submit.prevent="submitForm()">
                            <div class="card-header">
                                <h3 class="card-title" v-if="status">New Customer</h3>
                                <h3 class="card-title" v-else>Update Customer</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="customer_no" class="form-label">Customer Membership No *</label>
                                            <input
                                                type="text"
                                                class="form-control form-control-sm"
                                                :class="{'is-invalid': errors.customer_membership_no}"
                                                placeholder="Customer Membership no"
                                                v-model="customer.customer_membership_no"
                                                disabled="disable"
                                            />
                                            <span v-if="errors.customer_membership_no" class="invalid-feedback">{{ errors.customer_membership_no[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="customer_name">Customer Name *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.customer_name}" placeholder="Customer Name" v-model="customer.customer_name" ref="customer_name" />
                                            <span v-if="errors.customer_name" class="invalid-feedback">{{ errors.customer_name[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="mobile_no">Mobile No *</label>
                                            <input type="number" class="form-control form-control-sm" :class="{'is-invalid': errors.mobile_no}" placeholder="Mobile No" v-model="customer.mobile_no" />
                                            <span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="email">Email *</label>
                                            <input type="email" class="form-control form-control-sm" :class="{'is-invalid': errors.email}" placeholder="Email Address" v-model="customer.email" />
                                            <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="date_of_birth">Date of Birth</label>
                                            <input type="date" class="form-control form-control-sm" :class="{'is-invalid': errors.date_of_birth}" placeholder="Date of Birth" v-model="customer.date_of_birth" />
                                            <span v-if="errors.date_of_birth" class="invalid-feedback">{{ errors.date_of_birth[0] }}</span>
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Group </label>
                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.group }" v-model="customer.group_id">
                                                <option value="">Select Group</option>
                                                <option v-for="(group,key) in groups" :key="key" :value="group.group_id">{{ group.group}}</option>
                                            </select>
                                            <span v-if="errors.group" class="invalid-feedback">{{ errors.group[0] }}</span>
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Occupation * </label>
                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.occupation }" v-model="customer.occupation_id">
                                                <option value="">Select Occupation</option>
                                                <option v-for="(occupation,key) in occupations" :key="key" :value="occupation.occupation_id">{{ occupation.occupation}}</option>
                                            </select>
                                            <span v-if="errors.occupation_id" class="invalid-feedback">{{ errors.occupation_id[0] }}</span>
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="city">City</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.city}" placeholder="City" v-model="customer.city" />
                                            <span v-if="errors.city" class="invalid-feedback">{{ errors.city[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="bank_name">Bank Name/Branch *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.bank_name}" placeholder="Bank Name/Branch" v-model="customer.bank_name" />
                                            <span v-if="errors.bank_name" class="invalid-feedback">{{ errors.bank_name[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="bank_name">Account Number *</label>
                                            <input type="number" class="form-control form-control-sm" :class="{'is-invalid': errors.account_no}" placeholder="Account Number" v-model="customer.account_no" />
                                            <span v-if="errors.account_no" class="invalid-feedback">{{ errors.account_no[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="bank_name">Account Holder Name *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.account_name}" placeholder="Account Holder Number" v-model="customer.account_name" />
                                            <span v-if="errors.account_name" class="invalid-feedback">{{ errors.account_name[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="bank_name">IFSC Code *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.ifsc_code}" placeholder="IFSC Code" v-model="customer.ifsc_code" />
                                            <span v-if="errors.ifsc_code" class="invalid-feedback">{{ errors.ifsc_code[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link to="/customers" class="btn btn-sm btn-outline-danger" type="button"> <i class="fas fa-ban"></i> Discard </router-link>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                        <span v-else> <i class="fas fa-save"></i> Update </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "Customers.Create",
        data() {
            return {
                status: true,
                customer: {
                    customer_name: "",
                    customer_membership_no: "",
                    email: "",
                    mobile_no: "",
                    alternate_no: "",
                    date_of_birth: "",
                    address: "",
                    group_id: "1",
                    occupation_id: "1",
                    account_no: "",
                    ifsc_code: "",
                    bank_name: "",
                    status: 0,
                },
                customers: [],
                errors: [],
                groups: [],
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.getGroups();
                vm.getOccupations();
                if (to.name == "Customers.Create") {
                    vm.$refs.customer_name.focus();
                    vm.getCustomerMemershipNo();
                } else {
                    vm.status = false;
                    let uri = { uri: "showCustomer", data: { customer_id: to.params.customer_id } };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function (response) {
                            vm.customer = response.data.data;
                            vm.customer.password = vm.customer.mobile_no;
                            vm.customer.avatar = vm.$store.getters.assetUrl + "/customers/" + vm.customer.avatar;
                            vm.$refs.customer.focus();
                        })
                        .catch(function (error) {
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            });
        },

        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },

            edit(customer) {
                this.$router.push("/customers/create/" + customer.customer_id + "/edit");
            },

            isTrashed() {
                let vm = this;
                vm.meta.trashed = !vm.meta.trashed;
                vm.index();
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "addCustomer", data: vm.customer };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Customer is successfully created");
                        vm.$router.push("/customers");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            update() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateCustomer", data: vm.customer };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Customer is successfully updated");
                        vm.$router.push("/customers");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            onImageChange(e) {
                e.preventDefault();
                let vm = this;
                let image = e.target.files[0];
                let reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = (e) => {
                    vm.customer.avatar = e.target.result;
                };
            },
            getCustomerMemershipNo() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "getCustomerMemershipNo", data: vm.customer };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.customer.customer_membership_no = response.data;
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getGroups() {
                let vm = this;
                let uri = { uri: "getGroups" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.groups = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getOccupations() {
                let vm = this;
                let uri = { uri: "getOccupations" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.occupations = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
        },
    };
</script>
