<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item active">Reports</li>
            </ol>
        </nav>
        <div class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card col-4">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-12">
                                        <strong>Reports</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <ul class="list-group">
                                    <li class="list-group-item">
                                        Day-Book
                                    </li>
                                    <li class="list-group-item">
                                        Transactions
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Reports.Index",

        data() {
            return {};
        },
    };
</script>
