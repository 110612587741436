<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Old Transactions</li>
            </ol>
        </nav>
        <section class="content">
            <form class="container-fluid" @submit.prevent="submitOldTransaction">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Old Transactions</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="customer_id">Customer
                                                <span data-toggle="modal" data-target="#exampleModal">New Customer</span>
                                            </label>
                                            <Customer :class="{'is-invalid': errors.customer_id}" :customClass="{'is-invalid': errors.customer_id}" :initialize="transaction.customer_name" @selectCustomer="selectCustomer($event)" ref="customer_id"></Customer>
                                            <span v-if="errors.customer_id" class="invalid-feedback">{{ errors.customer_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="transaction_date_time">Transaction Date & Time *</label>
                                            <input type="datetime-local" class="form-control form-control-sm" :class="{'is-invalid': errors.transaction_date_time}" v-model="transaction.transaction_date_time" />
                                            <span v-if="errors.transaction_date_time" class="invalid-feedback">{{ errors.transaction_date_time[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="transaction_type">Transaction Type *</label>
                                            <select class="form-control form-control-sm" :class="{'is-invalid': errors.transaction_type}" v-model="transaction.transaction_type" ref="transaction_type">
                                                <option value="">Select Transaction Type</option>
                                                <option v-if="!status" value="Open">Start Session</option>
                                                <option v-if="status" value="Deposit_BuyIn">Deposit & BuyIn</option>
                                                <option v-if="status" value="Deposit">Deposit</option>
                                                <option v-if="status" value="Withdraw">Withdraw</option>
                                                <option v-if="status" value="Withdraw">TDS</option>
                                                <option v-if="status" value="BuyIn">BuyIn</option>
                                                <option v-if="status" value="CashOut">CashOut</option>
                                                <option v-if="status" value="Tips">Tips</option>
                                                <option v-if="status" value="Experience Fees">Experience Fees</option>
                                                <option v-if="status" value="Close">End Session</option>
                                            </select>
                                            <span v-if="errors.transaction_type" class="invalid-feedback">{{ errors.transaction_type[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="payment_mode_id">Payment Mode</label>
                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.payment_mode_id }" v-model="transaction.payment_mode_id">
                                                <option value="">Select Payment Mode</option>
                                                <option v-for="(payment_mode,key) in payment_modes" :key="key" :value="payment_mode.payment_mode_id">{{ payment_mode.payment_mode}}</option>
                                            </select>
                                            <span v-if="errors.payment_mode_id" class="invalid-feedback">{{ errors.payment_mode_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="reference_id">Reference No </label>
                                            <input :disabled="transaction_status" type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.reference_id}" placeholder="Reference No" v-model="transaction.reference_id" />
                                            <span v-if="errors.reference_id" class="invalid-feedback">{{ errors.reference_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="amount">Amount </label>
                                            <input type="number" class="form-control form-control-sm" :class="{'is-invalid': errors.amount}" placeholder="Amount" v-model="transaction.amount" @keyup="calculateTotalAmount('N')" />
                                            <span v-if="errors.amount" class="invalid-feedback">{{ errors.amount[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="note">Note</label>
                                            <textarea class="form-control form-control-sm" :class="{'is-invalid': errors.note}" placeholder="Note" v-model="transaction.note"></textarea>
                                            <span v-if="errors.note" class="invalid-feedback">{{ errors.reference_id[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <i class="fas fa-save"></i> Submit 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </section>
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">New Customer</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="customer_name">Customer Name *</label>
                        <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.customer_name}" placeholder="Customer Name" v-model="customer.customer_name" />
                        <span v-if="errors.customer_name" class="invalid-feedback">{{ errors.customer_name[0] }}</span>
                    </div>
                    <div class="form-group">
                        <label for="mobile_no">Mobile Number *</label>
                        <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.mobile_no}" placeholder="Mobile Number" v-model="customer.mobile_no" />
                        <span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
                    </div>
                    <div class="form-group">
                        <label for="email">Email *</label>
                        <input type="email" class="form-control form-control-sm" :class="{'is-invalid': errors.email}" placeholder="Email Address" v-model="customer.email" />
                        <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" @click="closeCustomer" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" @click="saveCustomer" class="btn btn-primary" data-dismiss="modal">Save</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    let Customer = require('../../components/Customer.vue').default;
    import moment from 'moment';
    export default {
        name: "Transaction.Create",
        components: { Customer },
        data() {
            return {
                status: true,
                transaction: {
                    session_log_id:'',
                    branch_id:this.$store.getters.user.branch_id,
                    user_id:this.$store.getters.user.user_id,
                    customer_id:'',
                    customer_name:'',
                    transaction_date_time:'',
                    transaction_type:'',
                    payment_mode_id:'',
                    reference_id:'',
                    amount:'',
                    note:'',
                },
                customer:{
                    customer_name:'',
                    mobile_no:'',
                    email:'',
                },
                payment_modes:[],
                errors: [],
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.getPaymentModes()
                vm.$store.dispatch("post", { uri: "checkSession", data: vm.transaction })
                .then(function (response) {
                    let result = response.data;
                    if(result.status==0) {
                        vm.status = false;
                    }
                    else {
                        vm.status = true;
                        vm.transaction.session_log_id = result.session.session_log_id;
                        vm.transaction.transaction_date_time = moment(result.session.start).format('YYYY-MM-DDTHH:mm');
                    }
                    vm.$refs.customer_id.focus();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            });
        },

        methods: {

            selectCustomer(e){
                let vm = this;
                const customer = e.target.value;
                vm.transaction.customer_id = customer.customer_id;
                vm.transaction.customer_name = customer.customer_name;
                vm.$refs.transaction_type.focus()
            },

            getPaymentModes() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "paymentModes" })
                .then(function (response) {
                    vm.payment_modes = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            
            submitOldTransaction() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "submitOldTransaction", data: vm.transaction };
                vm.$store.dispatch("post", uri).then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Transaction is successfully updated");
                    if(vm.transaction.transaction_type=='Open' || vm.transaction.transaction_type=='Close') {
                        alert('Transaction is successfully updated')
                        location.reload()
                    }
                    else {
                        vm.transaction.transaction_type = "";
                        vm.transaction.payment_mode_id = "";
                        vm.transaction.reference_id = "";
                        vm.transaction.amount = "";
                        vm.transaction.note = "";
                        vm.errors = [];
                        vm.$refs.transaction_type.focus()
                    }
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            saveCustomer() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "addCustomer", data: vm.customer };
                vm.$store.dispatch("post", uri).then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", "Customer is successfully created");
                    vm.closeCustomer();
                    let customer = response.data;
                    vm.transaction.customer_id = customer.customer_id;
                    vm.transaction.customer_name = customer.customer_name;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            closeCustomer() {
                let vm = this;
                vm.customer.customer_name = "";
                vm.customer.mobile_no = "";
                vm.customer.email = "";
            }
        },
    };
</script>
