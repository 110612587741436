<template>
    <form @submit.prevent="addSession">
        <div class="card-body">
            <div class="row mt-2">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="table_id">Table *</label>
                        <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.table_id }" v-model="session.table_id" @change="getDenominations" ref="table_id">
                            <option value="">Select table</option>
                            <option v-for="(table,key) in tables" :key="key" :value="table.table_id">{{ table.table}}</option>
                        </select>
                        <span v-if="errors.table_id" class="invalid-feedback">{{ errors.table_id[0] }}</span>
                    </div>
                </div>
                <div class="col-sm-12">
                    <table class="table table-sm table-bordered table-striped">
                        <thead>
                            <tr>
                                <th class="text-center">Denomination</th>
                                <th class="text-center">Quantity</th>
                                <th class="text-center">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(denomination,key) in denominations" :key="key">
                                <td class="text-center">{{ denomination.denomination_value }}</td>
                                <td>
                                    <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.quantity}" v-model="denomination.quantity" @keyup="checkDenomination(denomination)" />
                                </td>
                                <td class="text-center">
                                    {{ denomination.amount = (denomination.quantity * denomination.denomination_value).toFixed(2) }}
                                </td>
                            </tr>
                            <tr>
                                <th class="text-center" colspan="2">Grand Total</th>
                                <th class="text-center">{{ amount }}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="card-footer" v-if="amount!=0">
            <div class="float-right">
                <button class="btn btn-sm btn-outline-success" type="submit">
                    <i class="fas fa-save"></i> Submit 
                </button>
            </div>
        </div>
    </form>
</template>

<script>
    import moment from 'moment'
    export default {
        name: "Session.Create",
        props: {
            session_log_id: {
                default: null
            }
        },
        data() {
            return {
                session: {
                    branch_id: this.$store.getters.user.branch_id,
                    user_id: this.$store.getters.user.user_id,
                    session_log_id:'',
                    session_type: "Experience Fees",
                    session_date: moment().format('YYYY-MM-DD'),
                    session_time: moment().format('HH:mm:ss'),
                    table_id: "",
                    denominations:[]
                },
                tables:[],
                denominations: [],
                errors: [],
            };
        },
        computed: {
            amount: function () {
                let vm = this;
                return  vm.denominations.reduce((prev, cur) => +prev + +cur.amount, 0);
            }
        },
        mounted() {
            let vm = this;
            vm.session.session_log_id = vm.session_log_id
            setInterval(vm.setSessionTime, 1000);
        },
        methods: {
            setSessionTime() {
                let vm = this;
                vm.session.session_time = moment().format('HH:mm');
            },

            getTables() {
                let vm = this;
                if (vm.$store.getters.branch_tables.length > 0) {
                    vm.tables = vm.$store.getters.branch_tables
                }
                else{
                    let uri = { uri: "getBranchTables", data: vm.session };
                    vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        vm.tables = response.data.data;
                        vm.$store.dispatch('setBranchTables', vm.tables);
                        vm.discard()
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

            getDenominations() {
                let vm = this;
                // if (vm.$store.getters.branch_denominations.length > 0) {
                //     vm.denominations = vm.$store.getters.branch_denominations
                // }
                // else{
                    let uri = { uri: "getBranchDenominations", data: vm.session  };
                    vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        vm.denominations = response.data;
                        vm.$store.dispatch('setBranchDenominations', vm.denominations);
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                // }
            },

            checkDenomination(denomination) {
                let vm = this;
                if (denomination.denomination_quantity < denomination.quantity) {
                    vm.$store.dispatch("error", 'Please enter valid quantity');
                    return denomination.quantity = 0;
                }
            },
            
            addSession() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.session.denominations = vm.denominations;
                let uri = { uri: "addSession", data: vm.session };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Session is successfully created");
                    vm.discard()
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            discard() {
                let vm = this;
                vm.session.table_id = "";
                vm.denominations = [];
                vm.session.denominations = [];
                vm.errors = [];
                vm.$refs.table_id.focus()
            }
        },
    };
</script>
