<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Pending transactions</li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Pending transactions</h3>
                                <div class="float-right">
                                    <button class="btn btn-sm btn-success" @click="index()">
                                        <i class="fas fa-redo"></i>
                                        Refresh
                                    </button>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <input class="form-control form-control-sm" type="text" placeholder="Search" v-model="meta.search" @keyup="index" />
                                </div>
                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th>T. No</th>
                                            <th>T. Date-Time</th>
                                            <th>T. Type</th>
                                            <th>User</th>
                                            <th>Customer</th>
                                            <th>Amount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(transaction,key) in transactions" :key="key">
                                            <td class="text-center">{{meta.from+key}}</td>
                                            <td>{{transaction.transaction_no}}</td>
                                            <td>{{transaction.transaction_date_time}}</td>
                                            <td>{{transaction.transaction_type}}</td>
                                            <td v-if="!transaction.user">-</td>
                                            <td v-else>{{transaction.user.name}}</td>
                                            <td>{{transaction.customer.customer_name}}</td>
                                            <td>{{transaction.amount}}</td>
                                            <td class="text-center">
                                                <button class="btn btn-xs btn-outline-success" data-placement="bottom" title="Approve" data-toggle="modal" data-target="#approval" @click="view(transaction)">
                                                    <i class="fas fa-check"></i>
                                                </button>
                                                <button class="btn btn-xs btn-outline-danger" data-placement="bottom" title="Reject" @click="reject_transaction(transaction)">
                                                    <i class="fas fa-ban"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr v-if="transactions.length <= 0">
                                            <td colspan="20" style="text-align: center;">No Records Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="modal fade" id="approval">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title">Transaction Approval</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <label for="customer_name">OTP *</label>
                                                    <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.otp}" placeholder="Enter OTP" v-model="approve.otp" ref="otp" />
                                                    <span v-if="errors.otp" class="invalid-feedback">{{ errors.otp[0] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                                            <button type="button" class="btn btn-success" data-dismiss="modal" @click="approve_transaction()">Approve</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "PendingTransaction.Index",
        components: { Pagination },
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "transaction_no",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },
                approve: {
                    transaction_id: "",
                    user_id: "",
                    otp: "",
                },
                reject: {
                    transaction_id: "",
                    user_id: "",
                },
                url: "",
                transactions: [],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.index();
            vm.schedule();
        },

        methods: {
            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "transactions/pending", data: vm.meta })
                    .then(function (response) {
                        vm.transactions = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            view(transaction) {
                let vm = this;
                vm.approve.transaction_id = transaction.transaction_id;
                if (transaction.transaction_type == "BuyIn") {
                    vm.url = "transactions/approveBuyIn";
                } else if (transaction.transaction_type == "CashOut") {
                    vm.url = "transactions/approveCashOut";
                } else {
                    vm.url = "transactions/approveWithdraw";
                }
            },

            approve_transaction() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.approve.user_id = vm.$store.getters.user.user_id;
                let uri = { uri: vm.url, data: vm.approve };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Transaction is successfully approved");
                        if (vm.url == "transactions/approveWithdraw") {
                            vm.$router.push("/pending-transactions");
                        } else {
                            vm.$router.push("/transaction-denomination/" + vm.approve.transaction_id + "/create");
                        }
                        vm.index();
                        vm.approve.transaction_id = "";
                        vm.approve.user_id = "";
                        vm.approve.otp = "";
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            reject_transaction(transaction) {
                let vm = this;
                let loader = vm.$loading.show();
                vm.reject.user_id = vm.$store.getters.user.user_id;
                vm.reject.transaction_id = transaction.transaction_id;
                if (transaction.transaction_type == "BuyIn") {
                    vm.url = "transactions/rejectBuyIn";
                } else {
                    vm.url = "transactions/rejectCashOut";
                }
                let uri = { uri: vm.url, data: vm.reject };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Transaction is successfully Rejected");
                        vm.$router.push("/pending-transactions");
                        vm.index();
                        vm.reject.transaction_id = "";
                        vm.reject.user_id = "";
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            schedule() {
                let vm = this;
                vm.timer = setInterval(
                    function () {
                        vm.index();
                    }.bind(),
                    60000
                );
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
        },
    };
</script>
