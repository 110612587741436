<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-mug-hot"></i>
                        Food & Beverages
                    </a>
                </li>
                <li class="breadcrumb-item active">Item Category</li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-4">
                        <form class="card" @submit.prevent="submitForm()">
                            <div class="card-header">
                                <h3 class="card-title" v-if="status">New Item Category</h3>
                                <h3 class="card-title" v-else>Update Item Category</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="item_category">Item Category *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.item_category}" placeholder="Item Category" v-model="item_category.item_category" />
                                            <span v-if="errors.item_category" class="invalid-feedback">{{ errors.item_category[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="description">Description</label>
                                            <textarea type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.description}" placeholder="Description" v-model="item_category.description"></textarea>
                                            <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button class="btn btn-sm btn-outline-danger" type="button" @click="discard()"><i class="fas fa-ban"></i> Discard</button>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                        <span v-else> <i class="fas fa-save"></i> Update </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-sm-8">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Item Categories</h3>
                                <div class="card-tools">
                                    <div class="custom-control custom-switch custom-switch-off-success custom-switch-on-danger">
                                        <input type="checkbox" class="custom-control-input" id="trashed" v-model="meta.trashed" @click="isTrashed"  />
                                        <label class="custom-control-label" for="trashed">Trashed</label>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <input class="form-control form-control-sm" type="text" placeholder="Search" v-model="meta.search" @keyup="search" v-if="!meta.trashed"/>
                                </div>
                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th>Item Catgeory</th>
                                            <th>Description</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item_category,key) in item_categories" :key="key">
                                            <td class="text-center">{{meta.from+key}}</td>
                                            <td>{{item_category.item_category}}</td>
                                            <td>{{item_category.description}}</td>
                                            <td class="text-center" v-if="!meta.trashed">
                                                <button class="btn btn-xs btn-outline-success" data-toggle="tooltip" data-placement="bottom" title="Edit" @click="edit(item_category)">
                                                    <i class="fas fa-pencil-alt" ></i>
                                                </button>
                                                <button class="btn btn-xs btn-outline-danger" data-toggle="tooltip" data-placement="bottom" title="Delete" @click="deleteItemCategory(item_category)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                            <td class="text-center" v-else>
                                                <button class="btn btn-xs btn-outline-success" data-toggle="tooltip" data-placement="bottom" title="Restore" @click="deleteItemCategory(item_category)">
                                                    <i class="fas fa-trash-restore"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr v-if="item_categories.length <= 0">
                                            <td colspan="4" style="text-align: center;">No Records Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "ItemCategory",
        components: { Pagination },
        data() {
            return {
                status: true,
                meta: {
                    branch_id:this.$store.getters.user.branch_id,
                    search: "",
                    order_by: "asc",
                    keyword: "item_category",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },
                item_category: {
                    branch_id:this.$store.getters.user.branch_id,
                    item_category_id: "",
                    item_category: "",
                    description:"",
                },
                item_categories: [],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.index();
        },

        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },

            edit(item_category) {
                let vm = this;
                (vm.status = false), (vm.item_category = item_category), vm.$refs.item_category.focus();
            },

            index() {
                let vm = this;
                vm.$store
                .dispatch("post", { uri: "paginateItemCategories", data: vm.meta })
                .then(function (response) {
                    vm.item_categories = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            isTrashed() {
                let vm = this;
                vm.meta.trashed = !vm.meta.trashed;
                if(vm.meta.trashed)
                    vm.meta.page = 1
                vm.index();
            },

            search(){
               let vm = this;
               vm.meta.page = 1;
               vm.index();
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "addItemCategory", data: vm.item_category };
                vm.$store
                .dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Item Category is successfully created");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            discard() {
                let vm = this;
                vm.item_category.item_category = "";
                vm.item_category.description = "";
                vm.errors = [];
                vm.status = true;
                vm.index();
            },

            update() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateItemCategory", data: vm.item_category };
                vm.$store
                .dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Item Category is successfully updated");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            deleteItemCategory(item_category) {
                if (confirm("Do you want to continue... ?")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteItemCategory", data: item_category };
                    vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Item Catgeory is successfully deleted");
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
        },
    };
</script>
