<template>
    <div>
        <div class="content-wrapper">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a href="#">
                            <i class="fa fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active">Logs</li>
                </ol>
            </nav>
            <section class="content">
                <div class="container-fluid">
                    <div class="row justify-content-md-center">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Logs</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row g-2">
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label for="user_id">User </label>
                                                <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.user }" v-model="meta.user_id">
                                                    <option value="">Select User</option>
                                                    <option v-for="(user, key) in users" :key="key" :value="user.user_id">{{ user.name }}</option>
                                                </select>
                                                <span v-if="errors.user_id" class="invalid-feedback">{{ errors.user_id[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label for="response_status">Response Status </label>
                                                <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.response_status }" v-model="meta.response_status">
                                                    <option value="">Select Response Status</option>
                                                    <option value="Success">Success</option>
                                                    <option value="Created">Created</option>
                                                    <option value="No Content">No Content</option>
                                                    <option value="Bad Request">Bad Request</option>
                                                    <option value="Unauthorized">Unauthorized</option>
                                                    <option value="Forbidden">Forbidden</option>
                                                    <option value="Not Found">Not Found</option>
                                                    <option value="Validation Error">Validation Error</option>
                                                    <option value="Server Error">Server Error</option>
                                                   
                                                </select>
                                                <span v-if="errors.response_status" class="invalid-feedback">{{ errors.response_status[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="form-group">
                                                <label for="from_date">From Date</label>
                                                <input class="form-control form-control-sm" type="date" placeholder="Search" v-model="meta.from_date" />
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="form-group">
                                                <label for="from_date">To Date</label>
                                                <input class="form-control form-control-sm" type="date" placeholder="Search" v-model="meta.to_date" />
                                            </div>
                                        </div>
                                        <div class="col-2 mt-auto">
                                            <div class="form-group">
                                                <button class="btn btn-sm btn-primary mr-2" @click="search"><i class="fa fa-search mr-1"></i>Search</button>
                                                <button class="btn btn-sm btn-danger" @click="resetSearch"><i class="fa fa-times mr-1"></i>Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                        <thead>
                                            <tr>
                                                <th class="text-center">#</th>
                                                <th @click="sort('user_id')">
                                                    User
                                                    <span>
                                                        <i v-if="meta.keyword == 'user_id' && meta.order_by == 'asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword == 'user_id' && meta.order_by == 'desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th @click="sort('url')">
                                                    url
                                                    <span>
                                                        <i v-if="meta.keyword == 'url' && meta.order_by == 'asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword == 'url' && meta.order_by == 'desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th @click="sort('duration')">
                                                    Duration
                                                    <span>
                                                        <i v-if="meta.keyword == 'duration' && meta.order_by == 'asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword == 'duration' && meta.order_by == 'desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th @click="sort('created_at')">
                                                    Date
                                                    <span>
                                                        <i v-if="meta.keyword == 'created_at' && meta.order_by == 'asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword == 'created_at' && meta.order_by == 'desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th class="text-center" @click="sort('response_status')">
                                                    Response Status
                                                    <span>
                                                        <i v-if="meta.keyword == 'response_status' && meta.order_by == 'asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword == 'response_status' && meta.order_by == 'desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th class="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="logs.length <= 0">
                                                <td colspan="7" class="text-center">No Records Found</td>
                                            </tr>
                                            <tr v-for="(log, key) in logs" :key="key">
                                                <td class="text-center">{{ meta.from + key }}</td>
                                                <td>{{ log.user?.name }}</td>
                                                <td>{{ log.url }}</td>
                                                <td>{{ log.duration }} mins</td>
                                                <td>{{ changeDateFormat(log.created_at) }}</td>
                                                <td v-if="log.response_status == 'Success'" class="text-center"><span class="badge badge-success">{{ log.response_status }}</span></td>
                                                <td v-if="log.response_status == 'Not Found'" class="text-center"><span class="badge badge-danger">{{ log.response_status }}</span></td>
                                                <td v-if="log.response_status == 'Validation Error'" class="text-center">
                                                    <span class="badge badge-secondary">{{ log.response_status }}</span>
                                                </td>
                                                <td v-if="log.response_status == 'Server Error'" class="text-center">
                                                    <span class="badge badge-warning">{{ log.response_status }}</span>
                                                </td>
                                                <td class="text-center">
                                                    <button class="btn btn-xs btn-outline-success" data-toggle="modal" data-target="#exampleModal">
                                                        <i class="fas fa-eye" @click="view(log)"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer">
                                    <div class="float-right">
                                        <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- view modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Url: {{ url }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card">
                                    <div class="card-header">
                                        <h6>Request Body</h6>
                                    </div>
                                    <div class="card-body hgt">
                                        <pre>{{ request_body_items }}</pre>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card">
                                    <div class="card-header">
                                        <h6>Response Body</h6>
                                    </div>
                                    <div class="card-body hgt">
                                        <pre>{{ response_body_items }}</pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Pagination from "@/components/Pagination.vue";
    import moment from "moment";
    export default {
        name: "ApiLogs",
        components: { Pagination },
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "api_log_id",
                    per_page: 20,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    user_id: "",
                    response_status:"",
                    from_date: "",
                    to_date: moment().format("YYYY-MM-DD"),
                    // to_date: '',
                },
                logs: [],
                users: [],
                errors: [],
                request_body_items: [],
                response_body_items: [],
                url: "",
            };
        },
        computed: {
            prettyJson() {
                console.log("hi");
                return 1;
            },
        },
        mounted() {
            let vm = this;
            vm.index();
            vm.getUsers();
        },

        methods: {
            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },

            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateApiLogs", data: vm.meta })
                    .then(function (response) {
                        console.log("response---", response.data.data);
                        vm.logs = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
            view(log) {
                console.log("log--", log);
                this.request_body_items = log.request_body;
                this.response_body_items = log.response_body;
                this.url = log.url;
            },
            getUsers() {
                let vm = this;
                if (vm.$store.getters.log_users.length > 0) {
                    vm.users = vm.$store.getters.log_users;
                } else {
                    let uri = { uri: "getLogUsers", data: vm.meta };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function (response) {
                            vm.users = response.data.users;
                            vm.$store.dispatch("setLogUsers", vm.users);
                        })
                        .catch(function (error) {
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
            resetSearch() {
                let vm = this;
                vm.meta.from_date = "";
                vm.meta.to_date = moment().format("YYYY-MM-DD");
                vm.meta.user_id = "";
                vm.errors = [];
                vm.index();
            },
            changeDateFormat(date) {
                if (date) {
                    // return moment(date).format('YYYY-MM-DD')
                    return moment(date).format("DD-MM-YYYY");
                    // console.log("date", moment(date))
                    // console.log("date", moment(date, 'YYYY-MM-DD HH:mm:ss').format("yyyy-MM-DD HH:mm"))
                }
            },
        },
    };
</script>
<style scoped>
    .hgt {
        height: 300px;
        overflow: hidden;
        overflow-y: scroll;
    }
</style>
