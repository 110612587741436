<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">
                    Expense
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <form class="card" @submit.prevent="submitForm()">
                            <div class="card-header">
                                <h3 class="card-title" v-if="status">New Expense</h3>
                                <h3 class="card-title" v-else>Update Expense</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="voucher_no">Voucher No *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.voucher_no}" placeholder="Voucher No" v-model="expense.voucher_no" readonly />
                                            <span v-if="errors.voucher_no" class="invalid-feedback">{{ errors.voucher_no[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="voucher_date">Voucher Date *</label>
                                            <input type="date" class="form-control form-control-sm" :class="{'is-invalid': errors.voucher_date}" placeholder="Voucher Date" v-model="expense.voucher_date" />
                                            <span v-if="errors.voucher_date" class="invalid-feedback">{{ errors.voucher_date[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="vendor_id">Vendor *</label>
                                            <search
                                                :class="{ 'is-invalid': errors.vendor_id }"
                                                :customClass="{ 'is-invalid': errors.vendor_id }"
                                                :initialize="expense.vendor_id"
                                                id="vendor_id"
                                                label="vendor_name"
                                                placeholder="Select Vendor"
                                                :data="vendors"
                                                @input=" vendor => expense.vendor_id = vendor"
                                                @selectsearch="setPaymentTerm(expense.vendor_id)"
                                            >
                                            </search>
                                            <span v-if="errors.vendor_id" class="invalid-feedback">{{ errors.vendor_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="payment_term">Payment Term *</label>
                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.payment_term_id }" v-model="expense.payment_term_id" @change="setReminderDate">
                                                <option value="">Select Payment Term</option>
                                                <option v-for="(payment_term,key) in payment_terms" :key="key" :value="payment_term.payment_term_id">{{ payment_term.payment_term}}</option>
                                            </select>
                                            <span v-if="errors.payment_term_id" class="invalid-feedback">{{ errors.payment_term_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="reminder_date">Reminder Date *</label>
                                            <input type="date" class="form-control form-control-sm" :class="{'is-invalid': errors.reminder_date}" placeholder="Reminder Date" v-model="expense.reminder_date" />
                                            <span v-if="errors.reminder_date" class="invalid-feedback">{{ errors.reminder_date[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="account_id">Account *</label>
                                            <select class="form-control form-control-sm" :class="{'is-invalid': errors.account_id}" v-model="expense.account_id">
                                                <option value="">Select Account</option>
                                                <option v-for="(account,key) in accounts" :key="key" :value="account.account_id">{{ account.account}}</option>
                                            </select>
                                            <span v-if="errors.account_id" class="invalid-feedback">{{ errors.account_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="category_id">Category *</label>
                                            <search
                                                :class="{ 'is-invalid': errors.category_id }"
                                                :customClass="{ 'is-invalid': errors.category_id }"
                                                :initialize="expense.category_id"
                                                id="category_id"
                                                label="category"
                                                placeholder="Select Category"
                                                :data="categories"
                                                @input=" category => expense.category_id = category"
                                            >
                                            </search>
                                            <span v-if="errors.category_id" class="invalid-feedback">{{ errors.category_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="payment_mode_id">Payment Mode *</label>
                                            <select class="form-control form-control-sm" :class="{'is-invalid': errors.payment_mode_id}" v-model="expense.payment_mode_id">
                                                <option value="">Select Payment Mode</option>
                                                <option v-for="(payment_mode,key) in payment_modes" :key="key" :value="payment_mode.payment_mode_id">{{ payment_mode.payment_mode}}</option>
                                            </select>
                                            <span v-if="errors.payment_mode_id" class="invalid-feedback">{{ errors.payment_mode_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="amount">Amount *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.amount}" placeholder="Amount" v-model="expense.amount" @keyup="calculate()" />
                                            <span v-if="errors.amount" class="invalid-feedback">{{ errors.amount[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="amount">TDS %*</label>
                                            <input type="number" class="form-control form-control-sm" max="100" :class="{'is-invalid': errors.tds}" placeholder="TDS" v-model="expense.tds" @keyup="calculate()" />
                                            <span v-if="errors.tds" class="invalid-feedback">{{ errors.tds[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="amount">TDS Amount</label>
                                            <input type="text" class="form-control form-control-sm" readonly :class="{'is-invalid': errors.tds_amount}" placeholder="TDS Amount" v-model="expense.tds_amount" />
                                            <span v-if="errors.tds_amount" class="invalid-feedback">{{ errors.tds_amount[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="amount">Payable Amount</label>
                                            <input type="text" class="form-control form-control-sm" readonly :class="{'is-invalid': errors.payable_amount}" placeholder="Payable Amount" v-model="expense.payable_amount" />
                                            <span v-if="errors.payable_amount" class="invalid-feedback">{{ errors.payable_amount[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="reference_no">Reference No</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.reference_no}" placeholder="Reference No" v-model="expense.reference_no" />
                                            <span v-if="errors.reference_no" class="invalid-feedback">{{ errors.reference_no[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="reference_date">Reference Date</label>
                                            <input type="date" class="form-control form-control-sm" :class="{'is-invalid': errors.reference_date}" placeholder="Reference Date" v-model="expense.reference_date" />
                                            <span v-if="errors.reference_date" class="invalid-feedback">{{ errors.reference_date[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="approved_by">Approved By</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.approved_by}" placeholder="Approved By" v-model="expense.approved_by" />
                                            <span v-if="errors.approved_by" class="invalid-feedback">{{ errors.approved_by[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="attachment">Attachment</label>
                                            <input type="file" class="form-control form-control-sm" :class="{'is-invalid': errors.attachment}" @change="onImageChange($event)" />
                                            <a :href="expense.attachment" target="_blank">{{expense.attachment_name}}</a>
                                            <span v-if="errors.attachment" class="invalid-feedback">{{ errors.attachment[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="note">Note</label>
                                            <textarea class="form-control form-control-sm" :class="{'is-invalid': errors.note}" placeholder="Note" v-model="expense.note"></textarea>
                                            <span v-if="errors.note" class="invalid-feedback">{{ errors.note[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link to="/expenses" class="btn btn-sm btn-outline-danger" type="button"> <i class="fas fa-ban"></i> Discard </router-link>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                        <span v-else> <i class="fas fa-save"></i> Update </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Search from "@/components/Search.vue";
    import moment from 'moment';
    export default {
        name: "Expenses.Create",
        components: { Search },
        data() {
            return {
                status: true,
                expense: {
                    branch_id: this.$store.getters.user.branch_id,
                    voucher_no: "",
                    voucher_date: "",
                    vendor_id: "",
                    account_id: "",
                    category_id: "",
                    payment_mode_id: "",
                    reference_no: "",
                    reference_date: "",
                    approved_by: "",
                    amount: "",
                    tds: 0,
                    tds_amount: 0.0,
                    payable_amount: 0.0,
                    payment_term_id: "",
                    reminder_date:"",
                    note: "",
                    attachment: "",
                },
                maxQuantity: 100,
                vendors: [],
                accounts: [],
                categories: [],
                payment_modes: [],
                payment_terms: [],
                errors: [],
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (to.name == "Expenses.Create") {
                    vm.getVoucherNo();
                } else {
                    vm.status = false;
                    let uri = { uri: "showExpense", data: { expense_id: to.params.expense_id } };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function (response) {
                            vm.expense = response.data.data;
                            vm.getVendors();
                        })
                        .catch(function (error) {
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            });
        },

        methods: {
            validateQuantity() {
                // Ensure quantity does not exceed maxQuantity
                if (this.expense.tds > this.maxQuantity) {
                    this.expense.tds = this.maxQuantity;
                }
            },
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },

            getVoucherNo() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getVoucherNo" })
                    .then(function (response) {
                        vm.expense.voucher_no = response.data;
                        vm.getVendors();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getVendors() {
                let vm = this;
                if(this.$store.getters.vendors.length>0){
                    vm.vendors = vm.$store.getters.vendors
					vm.getAccounts();
                }
                else{
                    vm.$store
                        .dispatch("post", { uri: "getVendors", data: vm.expense })
                        .then(function (response) {
                            vm.vendors = response.data.data;
                            vm.$store.dispatch('setVendors', vm.vendors);
                            vm.getAccounts();
                        })
                        .catch(function (error) {
                            vm.errors = error.response.data;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },

            getAccounts() {
                let vm = this;
                if(this.$store.getters.accounts.length>0){
                    vm.accounts = vm.$store.getters.accounts
					vm.getCategories();
                }
                else{
                    vm.$store
                        .dispatch("post", { uri: "getAccounts" })
                        .then(function (response) {
                            vm.accounts = response.data.data;
                            vm.$store.dispatch('setAccounts', vm.accounts);
                            vm.getCategories();
                        })
                        .catch(function (error) {
                            vm.errors = error.response.data;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },

            getCategories() {
                let vm = this;
                if(this.$store.getters.categories.length>0){
                    vm.categories = vm.$store.getters.categories
					vm.getPaymentModes();
                }
                else{
                    vm.$store
                        .dispatch("post", { uri: "getCategories" })
                        .then(function (response) {
                            vm.categories = response.data.data;
                            vm.$store.dispatch('setCategories', vm.categories);
                            vm.getPaymentModes();
                        })
                        .catch(function (error) {
                            vm.errors = error.response.data;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },

            getPaymentModes() {
                let vm = this;
                if(this.$store.getters.payment_modes.length>0){
                    vm.payment_modes = vm.$store.getters.payment_modes
					vm.getPaymentTerms();
                }
                else{
                    vm.$store
                        .dispatch("post", { uri: "paymentModes" })
                        .then(function (response) {
                            vm.payment_modes = response.data.data;
                            vm.$store.dispatch('setPaymentModes', vm.payment_modes);
                            vm.getPaymentTerms();
                        })
                        .catch(function (error) {
                            vm.errors = error.response.data;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },

            getPaymentTerms() {
                let vm = this;
                if(this.$store.getters.payment_terms.length>0){
                    vm.payment_terms = vm.$store.getters.payment_terms
                }
                else{
                    vm.$store
                        .dispatch("post", { uri: "getPaymentTerms" })
                        .then(function (response) {
                            vm.payment_terms = response.data.data;
                            vm.$store.dispatch('setPaymentTerms', vm.payment_terms);
                        })
                        .catch(function (error) {
                            vm.errors = error.response.data;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                const formData = new FormData();
                formData.append("branch_id", vm.$store.getters.user.branch_id);
                formData.append("voucher_no", vm.expense.voucher_no);
                formData.append("voucher_date", vm.expense.voucher_date);
                formData.append("vendor_id", vm.expense.vendor_id);
                formData.append("account_id", vm.expense.account_id);
                formData.append("category_id", vm.expense.category_id);
                formData.append("payment_mode_id", vm.expense.payment_mode_id);
                formData.append("reference_no", vm.expense.reference_no);
                formData.append("reference_date", vm.expense.reference_date);
                formData.append("approved_by", vm.expense.approved_by);
                formData.append("amount", vm.expense.amount);
                formData.append("tds", vm.expense.tds);
                formData.append("tds_amount", vm.expense.tds_amount);
                formData.append("payable_amount", vm.expense.payable_amount);
                formData.append("payment_term_id", vm.expense.payment_term_id);
                formData.append("reminder_date", vm.expense.reminder_date);
                formData.append("note", vm.expense.note);
                formData.append("attachment", vm.expense.attachment);
                let uri = { uri: "addExpense", data: formData };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Expense is successfully created");
                        vm.$router.push("/expenses");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            update() {
                let vm = this;
                let loader = vm.$loading.show();
                const formData = new FormData();
                formData.append("expense_id", vm.expense.expense_id);
                formData.append("branch_id", vm.$store.getters.user.branch_id);
                formData.append("voucher_no", vm.expense.voucher_no);
                formData.append("voucher_date", vm.expense.voucher_date);
                formData.append("vendor_id", vm.expense.vendor_id);
                formData.append("account_id", vm.expense.account_id);
                formData.append("category_id", vm.expense.category_id);
                formData.append("payment_mode_id", vm.expense.payment_mode_id);
                formData.append("reference_no", vm.expense.reference_no);
                formData.append("reference_date", vm.expense.reference_date);
                formData.append("approved_by", vm.expense.approved_by);
                formData.append("amount", vm.expense.amount);
                formData.append("tds", vm.expense.tds);
                formData.append("tds_amount", vm.expense.tds_amount);
                formData.append("payable_amount", vm.expense.payable_amount);
                formData.append("payment_term_id", vm.expense.payment_term_id);
                formData.append("reminder_date", vm.expense.reminder_date);
                formData.append("note", vm.expense.note);
                formData.append("attachment", vm.expense.attachment);
                let uri = { uri: "updateExpense", data: formData };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Expense is successfully updated");
                        vm.$router.push("/expenses");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            calculate() {
                let vm = this;
                vm.validateQuantity();
                vm.expense.tds_amount = ((vm.expense.amount * vm.expense.tds) / 100).toFixed(2);
                vm.expense.payable_amount = (vm.expense.amount - vm.expense.tds_amount).toFixed(2);
            },

            onImageChange(e) {
                e.preventDefault();
                let vm = this;
                vm.expense.attachment = e.target.files[0];
            },
            setPaymentTerm(vendor_id) {
                let vm = this;
                let vendors = vm.vendors.filter(function (vendor) {
                    return vendor.vendor_id == vendor_id;
                });
                if (vendors.length!=0) {
                    vm.expense.payment_term_id = vendors[0].payment_term_id ? vendors[0].payment_term_id : '';
                    vm.expense.category_id = vendors[0].category_id ? vendors[0].category_id : '';
                } 
            },

            setReminderDate() {
                let vm = this;
                let payment_terms = vm.payment_terms.filter(function (payment_term) {
                    return payment_term.payment_term_id == vm.expense.payment_term_id;
                });
                if(payment_terms.length!=0) {

                    vm.expense.reminder_date = payment_terms[0].days ? moment().add(payment_terms[0].days, 'days').format('YYYY-MM-DD') : '';
                }
            }
        },
    };
</script>
