<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">
                    User
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <form class="card" @submit.prevent="submitForm()">
                            <div class="card-header">
                                <h3 class="card-title" v-if="status">New User</h3>
                                <h3 class="card-title" v-else>Update User</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="tax_name">Name *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.name}" placeholder="Name" v-model="user.name" ref="name" />
                                            <span v-if="errors.name" class="invalid-feedback">{{ errors.name[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="mobile_no">Mobile Number *</label>
                                            <input type="number" class="form-control form-control-sm" :class="{'is-invalid': errors.mobile_no}" placeholder="Mobile Number" v-model="user.mobile_no" />
                                            <span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="email">Email </label>
                                            <input type="email" class="form-control form-control-sm" :class="{'is-invalid': errors.email}" placeholder="Email Address" v-model="user.email" />
                                            <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Role *</label>
                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.role_id }" v-model="user.role_id">
                                                <option value="">Select Role</option>
                                                <option v-for="(role,key) in roles" :key="key" :value="role.role_id">{{ role.role}}</option>
                                            </select>
                                            <span v-if="errors.role_id" class="invalid-feedback">{{ errors.role_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="address">Address</label>
                                            <textarea type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.address}" placeholder="Address" v-model="user.address"></textarea>
                                            <span v-if="errors.address" class="invalid-feedback">{{ errors.address[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link to="/users" class="btn btn-sm btn-outline-danger" type="button"> <i class="fas fa-ban"></i> Discard </router-link>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                        <span v-else> <i class="fas fa-save"></i> Update </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "Users.Create",
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "name",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },
                user: {
                    name: "",
                    email: "",
                    username: "",
                    mobile_no: "",
                    role_id: "",
                    branch_id: "",
                    password: "",
                    address: "",
                    avatar: "",
                    selected_branches: [],
                },

                selected_branch: {
                    user_id: "",
                    branch_id: "",
                    key: "",
                },

                branches: [],
                users: [],
                roles: [],
                errors: [],
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.getRoles();
                if (to.name == "Users.Create") {
                    vm.$refs.name.focus();
                } else {
                    vm.status = false;
                    let uri = { uri: "showUser", data: { user_id: to.params.user_id } };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function (response) {
                            vm.user = response.data.data;
                            vm.user.password = vm.user.mobile_no;
                            vm.user.avatar = vm.$store.getters.assetUrl + "/users/" + vm.user.avatar;
                            vm.$refs.user.focus();
                        })
                        .catch(function (error) {
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            });
        },

        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },

            getRoles() {
                let vm = this;
                if(this.$store.getters.roles.length > 0){
                    vm.roles = vm.$store.getters.roles
                }else{
                    vm.$store.dispatch("post", { uri: "getRoles" })
					.then(function (response) {
						vm.roles = response.data.data;
						vm.$store.dispatch('setRoles', vm.roles)
					})
					.catch(function (error) {
						vm.errors = error.response.data;
						vm.$store.dispatch("error", error.response.data.message);
					});
                }
            },


            edit(user) {
                this.$router.push("/users/create/" + user.user_id + "/edit");
            },

            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateUsers", data: vm.meta })
                    .then(function (response) {
                        vm.users = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            isTrashed() {
                let vm = this;
                vm.meta.trashed = !vm.meta.trashed;
                vm.index();
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                 vm.user.branch_id = vm.$store.getters.user.branch_id;
                let uri = { uri: "addUser", data: vm.user };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "User is successfully created");
                        vm.$router.push("/users");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            update() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateUser", data: vm.user };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "User is successfully updated");
                        vm.$router.push("/users");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            onImageChange(e) {
                e.preventDefault();
                let vm = this;
                let image = e.target.files[0];
                let reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = (e) => {
                    vm.user.avatar = e.target.result;
                    console.log(vm.user.avatar);
                };
            },

            deleteUser(user) {
                if (confirm("Do you want to continue... ?")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteUser", data: user };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "User is successfully updated");
                            vm.index();
                            vm.trashed();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
        },
    };
</script>
