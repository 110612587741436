<template>
    <div class="content-wrapper">
        <div class="content">
            <div class="container-fluid">
                 <div style="text-align: center;">
                <a href="#" >
                    <img src="../../src/assets/AccessDeniedError.png" alt="AccessDenied" />
                </a>
            </div>
            </div>
        </div>
    </div>
</template>
