<template>
	<div class="content-wrapper">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<i class="fa fa-home"></i>
					Dashboard
				</li>
				<li class="breadcrumb-item active">Change Branch</li>
			</ol>
		</nav>
		<div class="content">
			<div class="container-fluid">
				<div class="row">
					
						
					<div class="col-sm-6">
                        <form class="card" @submit.prevent="updateBranch">
                            <div class="card-header">
                                <strong>Update Branch</strong>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label><b>Current Branch </b></label><br>
                                            <label>{{$store.getters.user.branch.branch_name}}</label>
                                            <span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
                                        </div>
                                    </div>
									<div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Branches *</label>
                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.branch_id }" v-model="user.branch_id">
                                                <option value="">Select Branch</option>
                                                <option v-for="(branch,key) in branches" :key="key" :value="branch.branch_id">{{ branch.branch_name}}</option>
                                            </select>
                                            <span v-if="errors.branch_id" class="invalid-feedback">{{ errors.branch_id[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button class="btn btn-sm btn-outline-success" type="submit">
										<span>
											<i class="fas fa-save"></i> Update
										</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'Proj-Profile',
		data(){
            return{
                status:true,
                user:{
					branch_id:'',
					user_id:''
				},
				branches:[],
                roles:[],
				users:[],
				errors:[]
            }
        },
		
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.getBranches();    
			});
        },
		methods:{
			getBranches() {
                let vm = this;
				vm.user.branch_id = vm.$store.getters.user.branch_id;
                 let uri = {'uri':'getOtherBranches','data':vm.user};
				vm.$store.dispatch('post',uri)
                    .then(function (response) {
                        vm.branches = response.data.data;
                        console.log(vm.branches);
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

			updateBranch() {
				let vm = this;
				let loader = vm.$loading.show();
				vm.user.user_id = vm.$store.getters.user.user_id;
                let uri = {'uri':'changeBranch','data':vm.user};
				vm.$store.dispatch('post',uri)
				.then(function (response) {
                    console.log(response.data);
					loader.hide();
					vm.$store.dispatch('success','Branch is successfully updated');
                    vm.$store.dispatch("setUser", response.data.data);
					vm.$router.push("/dashboard");
					
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			
          
		}
	}
</script>
