<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">Session</li>
                <li class="breadcrumb-right" >
                    <router-link v-can="'sessions.summary'" to="/sessions/report" class="btn btn-sm btn-success">
                        <i class="fa fa-list"></i> Session Summary
                    </router-link>
                    <a v-can="'sessions.download_report'" target="_blank" :href="$store.getters.pdfUrl+'report'" class="btn btn-sm btn-success">
                        <i class="fa fa-download"></i> Download Report
                    </a>
                </li>
            </ol>
        </nav>

        <section class="content" v-if="status">
            <div class="container-fluid1">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card card-dark card-tabs">
                            <div class="card-header p-0">
                                <ul class="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
                                    <li class="nav-item"  v-if="meta.status==0">
                                        <a ref="page1" :class="meta.status ? 'nav-link' : 'nav-link active'" id="page1-tab" data-toggle="pill" href="#page1" role="tab" aria-controls="page1" aria-selected="false">
                                            <span class="line_break">(F1)</span>
                                            Start Session
                                        </a>
                                    </li>
                                    <li class="nav-item"  v-if="meta.status==1">
                                        <a ref="page2" @click="openTab('page2')" :class="meta.status ? 'nav-link active' : 'nav-link'" id="page2-tab" data-toggle="pill" href="#page2" role="tab" aria-controls="page2" aria-selected="false">
                                            <span class="line_break">(F2)</span>
                                            Deposit
                                        </a>
                                    </li>
                                    <li class="nav-item"  v-if="meta.status==1">
                                        <a ref="page3" @click="openTab('page3')" class="nav-link" id="page3-tab" data-toggle="pill" href="#page3" role="tab" aria-controls="page3" aria-selected="false">
                                            <span class="line_break">(F3)</span>
                                            Deposit & BuyIn
                                        </a>
                                    </li>
                                    <li class="nav-item"  v-if="meta.status==1">
                                        <a ref="page4" @click="openTab('page4')" class="nav-link" id="page4-tab" data-toggle="pill" href="#page4" role="tab" aria-controls="page4" aria-selected="false">
                                            <span class="line_break">(F4)</span>
                                            BuyIn
                                        </a>
                                    </li>
                                    <li class="nav-item"  v-if="meta.status==1">
                                        <a ref="page5" @click="openTab('page5')" class="nav-link" id="page5-tab" data-toggle="pill" href="#page5" role="tab" aria-controls="page5" aria-selected="false">
                                            <span class="line_break">(F5)</span>
                                            Cashout
                                        </a>
                                    </li>
                                    <li class="nav-item"  v-if="meta.status==1">
                                        <a ref="page6" @click="openTab('page6')" class="nav-link" id="page6-tab" data-toggle="pill" href="#page6" role="tab" aria-controls="page6" aria-selected="false">
                                            <span class="line_break">(F6)</span>
                                            Withdraw
                                        </a>
                                    </li>
                                    <li class="nav-item"  v-if="meta.status==1">
                                        <a ref="page7" @click="openTab('page7')" class="nav-link" id="page7-tab" data-toggle="pill" href="#page7" role="tab" aria-controls="page7" aria-selected="false">
                                            <span class="line_break">(F7)</span>
                                            Experience Fees
                                        </a>
                                    </li>
                                    <li class="nav-item"  v-if="meta.status==1">
                                        <a ref="page8" @click="openTab('page8')" class="nav-link" id="page8-tab" data-toggle="pill" href="#page8" role="tab" aria-controls="page8" aria-selected="false">
                                            <span class="line_break">(F8)</span>
                                            Tips
                                        </a>
                                    </li>
                                    <li class="nav-item"  v-if="meta.status==1">
                                        <a ref="page9" @click="openTab('page9')" class="nav-link" id="page9-tab" data-toggle="pill" href="#page9" role="tab" aria-controls="page9" aria-selected="false">
                                            <span class="line_break">(F9)</span>
                                            Exchange Chips
                                        </a>
                                    </li>
                                    <li class="nav-item"  v-if="meta.status==1">
                                        <a ref="page10" @click="openTab('page10')" class="nav-link" id="page10-tab" data-toggle="pill" href="#page10" role="tab" aria-controls="page10" aria-selected="false">
                                            <span class="line_break">(F10)</span>
                                            Float Check
                                        </a>
                                    </li>
                                    <li class="nav-item"  v-if="meta.status==1">
                                        <a ref="page11" @click="openTab('page11')" class="nav-link" id="page11-tab" data-toggle="pill" href="#page11" role="tab" aria-controls="page11" aria-selected="false">
                                            <span class="line_break">(F11)</span>
                                            Summary
                                        </a>
                                    </li>
                                    <li class="nav-item"  v-if="meta.status==1">
                                        <a ref="page12" @click="openTab('page12')" class="nav-link" id="page12-tab" data-toggle="pill" href="#page12" role="tab" aria-controls="page12" aria-selected="false">
                                            <span class="line_break">(F1)</span>
                                            End Session
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content" id="custom-tabs-two-tabContent">
                                <div :class="meta.status ? 'tab-pane fade' : 'tab-pane fade active show'" id="page1" role="tabpanel" aria-labelledby="page1-tab">
                                    <StartSession ref="startSession"></StartSession>
                                </div>
                                <div :class="meta.status ? 'tab-pane fade active show' : 'tab-pane fade'" id="page2" role="tabpanel" aria-labelledby="page2-tab">
                                    <Deposit ref="deposit" :session_log_id="session?.session_log_id"></Deposit>
                                </div>
                                <div class="tab-pane fade" id="page3" role="tabpanel" aria-labelledby="page3-tab">
                                    <DepositBuyIn ref="depositBuyIn" :session_log_id="session?.session_log_id"></DepositBuyIn>
                                </div>
                                <div class="tab-pane fade" id="page4" role="tabpanel" aria-labelledby="page4-tab">
                                    <BuyIn ref="buyIn" :session_log_id="session?.session_log_id"></BuyIn>
                                </div>
                                <div class="tab-pane fade" id="page5" role="tabpanel" aria-labelledby="page5-tab">
                                    <CashOut ref="cashOut" :session_log_id="session?.session_log_id"></CashOut>
                                </div>
                                <div class="tab-pane fade" id="page6" role="tabpanel" aria-labelledby="page6-tab">
                                    <Withdraw ref="withdraw" :session_log_id="session?.session_log_id"></Withdraw>
                                </div>
                                <div class="tab-pane fade" id="page7" role="tabpanel" aria-labelledby="page7-tab">
                                    <ExperienceFees ref="experienceFees" :session_log_id="session?.session_log_id"></ExperienceFees>
                                </div>
                                <div class="tab-pane fade" id="page8" role="tabpanel" aria-labelledby="page8-tab">
                                    <Tips ref="tips" :session_log_id="session?.session_log_id"></Tips>
                                </div>
                                <div class="tab-pane fade" id="page9" role="tabpanel" aria-labelledby="page9-tab">
                                    <ExchangeChips ref="exchangeChips" :session_log_id="session?.session_log_id"></ExchangeChips>
                                </div>
                                <div class="tab-pane fade" id="page10" role="tabpanel" aria-labelledby="page10-tab">
                                    <FloatCheck ref="floatCheck" :session_log_id="session?.session_log_id"></FloatCheck>
                                </div>
                                <div class="tab-pane fade" id="page11" role="tabpanel" aria-labelledby="page11-tab">
                                    <Summary ref="summary" :session_log_id="session?.session_log_id"></Summary>
                                </div>
                                <div class="tab-pane fade" id="page12" role="tabpanel" aria-labelledby="page12-tab">
                                    <EndSession ref="endSession" :session_log_id="session?.session_log_id"></EndSession>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import StartSession from "@/views/session/StartSession.vue";
    import Deposit from "@/views/session/Deposit.vue";
    import DepositBuyIn from "@/views/session/DepositBuyIn.vue";
    import BuyIn from "@/views/session/BuyIn.vue";
    import CashOut from "@/views/session/CashOut.vue";
    import Withdraw from "@/views/session/Withdraw.vue";
    import ExperienceFees from "@/views/session/ExperienceFees.vue";
    import Tips from "@/views/session/Tips.vue";
    import ExchangeChips from "@/views/session/ExchangeChips.vue";
    import FloatCheck from "@/views/session/FloatCheck.vue";
    import Summary from "@/views/session/Summary.vue";
    import EndSession from "@/views/session/EndSession.vue";
    export default {
        name: "Sessions",
        components: { StartSession, Deposit, DepositBuyIn, BuyIn, CashOut, Withdraw, ExperienceFees, Tips, ExchangeChips, EndSession, FloatCheck, Summary },
        data() {
            return {
                status:false,
                meta:{
                    status:"",
                    branch_id:this.$store.getters?.user?.branch_id,
                },
                session: {},
                errors: [],
            };
        },

        mounted() {
            let vm = this;
            window.addEventListener("keydown", vm.escapeListener);
            vm.checkSession()
        },

        unmounted() {
            window.removeEventListener("keydown", this.escapeListener);
        },

        methods: {

            checkSession() {
                let vm = this;
                let uri = { uri: "checkSession", data: vm.meta };
                let loader = vm.$loading.show();
                vm.status = false;
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide()
                    vm.status = true;
                    vm.meta.status = response['data'].status
                    vm.session = response['data'].session
                })
                .catch(function (error) {
                    loader.hide()
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            openTab(page) {
                if(page=='page2') {
                    this.$refs.deposit.getPaymentModes();
                    this.$refs.deposit.discard();
                }
                if(page=='page3') {
                    this.$refs.depositBuyIn.getPaymentModes();
                }
                if(page=='page4') {
                    this.$refs.buyIn.getPromotions();
                }
                if(page=='page5') {
                    this.$refs.cashOut.discard();
                }
                if(page=='page6') {
                    this.$refs.withdraw.getPaymentModes();
                }
                if(page=='page7') {
                    this.$refs.experienceFees.getTables();
                }
                if(page=='page8') {
                    this.$refs.tips.getEmployees();
                }
                if(page=='page9') {
                    this.$refs.exchangeChips.discard();
                }
                if(page=='page10') {
                    this.$refs.floatCheck.discard();
                }
                if(page=='page11') {
                    this.$refs.summary.getDenominations();
                }
                if(page=='page12') {
                    this.$refs.endSession.discard();
                }
            },

            escapeListener(event) {
                if (event.key === "F1") {
                    this.$refs.page1 ? this.$refs.page1.click() : this.$refs.page12.click()
                }
                if (event.key === "F2") {
                    this.$refs.page2.click()
                }
                if (event.key === "F3") {
                    this.$refs.page3.click()
                }
                if (event.key === "F4") {
                    this.$refs.page4.click()
                }
                if (event.key === "F5") {
                    this.$refs.page5.click()
                }
                if (event.key === "F6") {
                    this.$refs.page6.click()
                }
                if (event.key === "F7") {
                    this.$refs.page7.click()
                }
                if (event.key === "F8") {
                    this.$refs.page8.click()
                }
                if (event.key === "F9") {
                    this.$refs.page9.click()
                }
                if (event.key === "F10") {
                    this.$refs.page10.click()
                }
                if (event.key === "F11") {
                    this.$refs.page11.click()
                }
                if (event.key === "F12") {
                    this.$refs.page12.click()
                }
            }
        },
    };
</script>
<style scoped>
    .nav-link {
        text-align: center;
    }

    .line_break {
        display: block;
    }
</style>