<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Customer Credit Report</li>
                <li class="breadcrumb-right">
                    <button type="button" @click="generateReport('PDF')" class="btn btn-sm btn-primary">
                        <i class="fa fa-file-pdf"></i>
                        PDF
                    </button>
                    <button type="button" @click="generateReport('Excel')" class="btn btn-sm btn-success">
                        <i class="fa fa-file-excel"></i>
                        Excel
                    </button>
                    <button type="button" @click="generateReport('Data')" class="btn btn-sm btn-info">
                        <i class="fa fa-filter"></i>
                        Generate
                    </button>
                    <button type="button" @click="reset" class="btn btn-sm btn-danger">
                        <i class="fa fa-undo"></i>
                        Reset
                    </button>
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Customer Credit Report</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="customer_id">Customer </label>
                                            <Customer :initialize="meta.customer_name" @input="selectCustomer($event)" ref="customer_id"></Customer>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="from_date">From Date</label>
                                            <input class="form-control form-control-sm" type="date" placeholder="Search" v-model="meta.from_date" />
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="from_date">To Date</label>
                                            <input class="form-control form-control-sm" type="date" placeholder="Search" v-model="meta.to_date" />
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <iframe :src="source" style="width:100%; height:450px;" frameborder="0"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import moment from 'moment'
    let Customer = require('../../components/Customer.vue').default;
    export default {
        name: "Customer.Ledger",
        components: { Customer },
        data() {
            return {
                source:"",
                meta: {
                    branch_id:this.$store.getters.user.branch_id,
                    from_date:moment().add(-30, 'days').format('YYYY-MM-DD'),
                    to_date:moment().format('YYYY-MM-DD'),
                    customer_id:'',
                    customer_name:''
                },
                errors: [],
            };
        },

        methods: {

            selectCustomer(e){
                let vm = this;
                const customer = e.target.value;
                vm.meta.customer_id = customer.customer_id;
                vm.meta.customer_name = customer.customer_name;
                vm.generateReport('Data');
            },

            generateReport(report_type) {
                let vm = this;
                vm.source = vm.$store.state.pdfUrl+"customerCredit?from_date=" + vm.meta.from_date + '&to_date=' + vm.meta.to_date + '&customer_id=' + vm.meta.customer_id + '&report_type=' + report_type;
            },

            reset() {
                let vm = this;
                vm.meta.from_date = moment().add(-30, 'days').format('YYYY-MM-DD');
                vm.meta.to_date = moment().format('YYYY-MM-DD');
                vm.meta.customer_id = "";
                vm.meta.customer_name = "";
            }
        }
    };
</script>
