<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">
                    <a href="#"><i class="fa fa-cogs"></i>Configuration</a>
                </li>
                <li class="breadcrumb-item active">Permissions</li>
                <li class="breadcrumb-right">
                    <button type="button" @click="addPermissions()" class="btn btn-sm btn-success">
                        Add Permission
                    </button>
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Permissions</h3>
                                <div class="card-tools d-flex pb-2">
                                    <select class="form-control form-control-sm mr-2"
                                        :class="{ 'is-invalid': errors.application_type }"
                                        v-model="role.application_type" @change="getRoleAbilities">
                                        <option value="">Select Application Type</option>
                                        <option v-for="application_type, key in application_types" :key="key"
                                            :value="application_type">{{ application_type
                                            }}</option>
                                    </select>
                                    <span v-if="errors.application_type" class="invalid-feedback">{{
                                        errors.application_type[0] }}</span>

                                    <select class="form-control form-control-sm"
                                        :class="{ 'is-invalid': errors.role_id }" v-model="role.role_id"
                                        @change="getRoleAbilities">
                                        <option value="">Select Role</option>
                                        <option v-for="role, key in roles" :key="key" :value="role.role_id">{{ role.role
                                            }}</option>
                                    </select>
                                    <span v-if="errors.role_id" class="invalid-feedback">{{ errors.role_id[0] }}</span>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-6" v-for="role_ability, i in role_abilities" :key="i">
                                        <div class="table-responsive text-wrap">
                                            <table class="table table-sm table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th colspan="3" class="bold text-primary">
                                                            {{ role_ability[0]?.module.module_name }} :: {{
                                                                role_ability[0]?.module?.application_type }}
                                                        </th>
                                                        <th class="text-center">
                                                            <!-- {{ role_ability[0].status }} -->
                                                            <div
                                                                class="custom-control custom-switch  custom-switch-on-primary">
                                                                <input type="checkbox" class="custom-control-input"
                                                                    role="switch1" :checked="role_ability[0].status"
                                                                    :value="role_ability[0].status"
                                                                    @change="enableDisablePermission(role_ability[0])"
                                                                    :id="role_ability[0].ability_id">
                                                                <label class="custom-control-label"
                                                                    :for="role_ability[0].ability_id"></label>
                                                            </div>

                                                        </th>

                                                    </tr>
                                                    <tr class="text-wrap">
                                                        <th width="5%" class="text-center">#</th>
                                                        <th width="40%">Ability</th>
                                                        <th width="40%">Description</th>
                                                        <th width="5%" class="text-center">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="ability, j in role_ability" :key="j">
                                                        <td class="text-center">{{ j + 1 }}</td>
                                                        <!-- <td>{{ getAbilityStatus(role_ability, ability) }}</td> -->
                                                        <td>{{ ability.ability }}</td>
                                                        <td>{{ ability.description }}</td>
                                                        <td class="text-center">
                                                            <div
                                                                class="custom-control custom-switch  custom-switch-on-primary">
                                                                <input type="checkbox" class="custom-control-input"
                                                                    role="switch" :disabled="ability.is_disabled"
                                                                    :checked="ability.status" :value="ability.status"
                                                                    @change="deleteAuthorization(ability)"
                                                                    :id="'ability_' + ability.ability_id">
                                                                <label class="custom-control-label"
                                                                    :for="'ability_' + ability.ability_id"></label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "Permissions1.Index",
    data() {
        return {
            role: {
                role_id: '',
                application_type: '',
            },
            role_abilities: [],
            application_types: [],
            roles: [],
            errors: [],
        };
    },
    mounted() {
        let vm = this;
        vm.role.role_id = vm.$store?.getters?.user?.role_id;
        vm.getRoles();
        vm.getApplicationTypes();
    },

    methods: {
        // getAbilityStatus(role_ability, ability) {
        //     ability.is_disabled = false
        //     let temp_status = role_ability.filter(function (element) {
        //         let temp_ability = element.ability.split(".")
        //         if (temp_ability.length && temp_ability[1] == 'view') {
        //             return element
        //         }
        //     })
        //     if (temp_status.length) {
        //         if (temp_status[0].status == false) {
        //             ability.is_disabled = true
        //         }
        //     }
        //     let ability_view = ability.ability.split(".")
        //     if (ability_view.length) {
        //         if (ability_view[1] == 'view') {
        //             ability.is_disabled = false
        //         }
        //     }
        //     return ability.ability
        // },
        getRoles() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getRoles" };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.roles = response.data.data;
                    vm.getRoleAbilities()
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getApplicationTypes() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getApplicationTypes" };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.application_types = response.data[0];
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getRoleAbilities() {
            let vm = this;
            let loader = vm.$loading.show();
            this.$store.dispatch('post', { uri: 'getPermissionStatus', data: { 'role_id': vm.role.role_id, 'application_type': vm.role.application_type } })
                .then(response => {
                    loader.hide();
                    vm.role_abilities = response.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                });
        },

        addPermissions() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store.dispatch('post', { uri: 'addPermissions', data: { role_id: vm.role.role_id } })
                .then(() => {
                    loader.hide();
                    vm.$store.dispatch('success', 'Permissions updated successfully!');
                    vm.getRoleAbilities();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message);
                })
        },

        // deleteAuthorization(role_ability, authorization, even) {
        //     let vm = this;
        //     let temp_ability = authorization.ability.split(".")
        //     let role_abilities = []
        //     if (temp_ability.length) {
        //         if (temp_ability[1] == 'view' && !even.target.checked) {
        //             role_ability.map(function (element) {
        //                 element.status = false
        //                 role_abilities.push(element)
        //             })
        //         }
        //     }
        //     let uri = {
        //         uri: 'deleteAuthorization',
        //         data: {
        //             role_id: vm.role.role_id,
        //             ability_id: authorization.ability_id,
        //             status: authorization.status,
        //             role_abilities: role_abilities
        //         }
        //     }
        //     vm.$store.dispatch("post", uri)
        //         .then(function () {
        //             vm.getRoleAbilities()
        //         })
        //         .catch(function (error) {
        //             vm.errors = error.response.data.error;
        //             vm.$store.dispatch("error", error.response.data.message);
        //         });
        // }

        deleteAuthorization(ability) {
            let vm = this;
            ability.role_id = vm.role.role_id;
            vm.$store.dispatch("post", { uri: 'deleteAuthorization', data: ability })
                .catch(function (error) {
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        enableDisablePermission(role_ability) {
            console.log("rr----", role_ability)
            let vm = this;
            let loader = vm.$loading.show();
            // role_ability.role_id = vm.role.role_id;
            let data = {
                module_id: role_ability?.module?.module_id,
                role_id: vm.role.role_id,
                status: !role_ability.status
            };

            vm.$store.dispatch("post", { uri: 'enableDisablePermissions', data: data })
                // .catch(function (error) {
                //     vm.errors = error.response.data.error;
                //     vm.$store.dispatch("error", error.response.data.message);
                // });
                .then(() => {
                    loader.hide();
                    vm.$store.dispatch('success', 'Permissions   updated successfully!');
                    vm.getRoleAbilities();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message);
                })
        }
    },
};
</script>