<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Sessions</li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Sessions</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label for="session_type">Session Type *</label>
                                            <select class="form-control form-control-sm"  :class="{'is-invalid': errors.session_type}" v-model="meta.session_type" @change="index">
                                                <option value="">Select One</option>
                                                <option value="Open">Opening </option>
                                                <option value="Experience Fees">Experience Fees</option>
                                                <option value="Tips">Tips</option>
                                                <option value="Exchange Chips">Exchange Chips</option>
                                                <option value="End">Ending</option>
                                            </select>
                                            <span v-if="errors.session_type" class="invalid-feedback">{{ errors.session_type[0] }}</span>
                                        </div>
                                    </div>
                              
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label for="from_date">From Date *</label>
                                            <input class="form-control form-control-sm" type="date" v-model="meta.from_date" placeholder="From Date"  @change="index"/>
                                            <span v-if="errors.from_date" class="invalid-feedback">{{ errors.from_date[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label for="to_date">To Date *</label>
                                            <input class="form-control form-control-sm" type="date" v-model="meta.to_date" placeholder="To Date" @change="index" />
                                            <span v-if="errors.to_date" class="invalid-feedback">{{ errors.to_date[0] }}</span>
                                        </div>
                                    </div>
                                     <div class="col-sm-2">
                                        <div class="form-group">
                                            <label for="user_id">User </label>
                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.user }" v-model="meta.user_id" @change="index">
                                                <option value="">Select All</option>
                                                <option v-for="(user,key) in users" :key="key" :value="user.user_id">{{ user.name}}</option>
                                            </select>
                                            <span v-if="errors.user_id" class="invalid-feedback">{{ errors.user_id[0] }}</span>
                                        </div>
                                    </div>
                                     <div class="col-sm-2" v-if="meta.session_type=='Tips'">
                                        <div class="form-group">
                                            <label for="employee_id">Employee </label>
                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.employee }" v-model="meta.employee_id"  @change="index">
                                                <option value="">Select All</option>
                                                <option v-for="(employee,key) in employees" :key="key" :value="employee.employee_id">{{ employee.name}}</option>
                                            </select>
                                            <span v-if="errors.employee_id" class="invalid-feedback">{{ errors.employee_id[0] }}</span>
                                        </div>
                                    </div>
                                      <div class="col-sm-2" v-if="meta.session_type=='Experience Fees'">
                                        <div class="form-group">
                                            <label for="table_id">Table </label>
                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.table }" v-model="meta.table_id"  @change="index">
                                                <option value="">Select All</option>
                                                <option v-for="(table,key) in tables" :key="key" :value="table.table_id">{{ table.table}}</option>
                                            </select>
                                            <span v-if="errors.table_id" class="invalid-feedback">{{ errors.table_id[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th>Session Date</th>
                                            <th>Session Time</th>
                                            <th>Session Type</th>
                                            <th>User</th>
                                            <th v-if="meta.session_type=='Experience Fees'">Table</th>
                                            <th v-if="meta.session_type=='Tips'">Employee</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(session,key) in sessions" :key="key" @click="viewSession(session)">
                                            <td class="text-center">{{meta.from+key}}</td>

                                            <td>{{session.session_date}}</td>
                                            <td>{{session.session_time}}</td>
                                            <td>{{session.session_type}}</td>
                                            <td>{{session.user.name}}</td>
                                            <td v-if="meta.session_type=='Experience Fees'">{{session.table?.table}}</td>
                                            <td v-if="meta.session_type=='Tips'">{{session.employee?.name}}</td>
                                        </tr>
                                        <tr v-if="sessions.length <= 0">
                                            <td colspan="7" style="text-align: center;">No Records Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Customer.Index",
        components: { Pagination },
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "session_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                    branch_id:this.$store.getters.user.branch_id,
                    from_date:"",
                    to_date: "",
                    user_id:"",
                    employee_id:"",
                    table_id:"",
                    session_type:'Experience Fees'
                },
                users:[],
                employees:[],
                tables:[],
                sessions: [],
                errors: [],
            };
        },

        mounted() {
            let vm = this;
            vm.index();
            vm.getUsers();
        },

        methods: {
           
            index() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "paginateSessions", data: vm.meta })
                .then(function (response) {
                    vm.sessions = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            viewSession(session) {
                this.$router.push("/sessions/" + session.session_id + "/view");
            },
            
            getUsers() {
                let vm = this;
                let uri = { uri: "getBranchUsers", data: vm.meta };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.users = response.data.data;
                    vm.getEmployees();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                }); 
            },

            getEmployees() {
                let vm = this;
                let uri = { uri: "getBranchEmployees", data: vm.meta };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.employees = response.data.data;
                    vm.getTables();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getTables() {
                let vm = this;
                let uri = { uri: "getBranchTables", data: vm.meta };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.tables = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            onPageChange(page) {
                let vm = this;
                vm.meta.page = page;
                vm.index();
            },
        },
    };
</script>
