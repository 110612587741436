<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Consolidated Expenses</li>
                <li class="breadcrumb-right">
                    <button type="button" @click="generateReport('PDF')" class="btn btn-sm btn-primary">
                        <i class="fa fa-file-pdf"></i>
                        PDF
                    </button>
                    <button type="button" @click="generateReport('Excel')" class="btn btn-sm btn-success">
                        <i class="fa fa-file-excel"></i>
                        Excel
                    </button>
                    <button type="button" @click="reset" class="btn btn-sm btn-danger">
                        <i class="fa fa-undo"></i>
                        Reset
                    </button>
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Consolidated Expenses</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="from_date">From Date</label>
                                            <input class="form-control form-control-sm" type="date" placeholder="Search" v-model="meta.from_date" @change="generateReport('Data')" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="from_date">To Date</label>
                                            <input class="form-control form-control-sm" type="date" placeholder="Search" v-model="meta.to_date" @change="generateReport('Data')" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="account_id">Account</label>
                                            <select class="form-control form-control-sm" :class="{'is-invalid': errors.account_id}" v-model="meta.account_id"  @change="generateReport('Data')">
                                                <option value="">Select Account</option>
                                                <option v-for="(account,key) in accounts" :key="key" :value="account.account_id">{{ account.account}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="payment_mode_id">Payment Mode</label>
                                            <select class="form-control form-control-sm" :class="{'is-invalid': errors.payment_mode_id}" v-model="meta.payment_mode_id"  @change="generateReport('Data')">
                                                <option value="">Select Payment Mode</option>
                                                <option v-for="(payment_mode,key) in payment_modes" :key="key" :value="payment_mode.payment_mode_id">{{ payment_mode.payment_mode}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <iframe :src="source" style="width:100%; height:450px;" frameborder="0"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import moment from 'moment'
    export default {
        name: "Expenses.Daily",
        data() {
            return {
                source:"",
                meta: {
                    branch_id:this.$store.getters.user.branch_id,
                    from_date:moment().add(-30, 'days').format('YYYY-MM-DD'),
                    to_date:moment().format('YYYY-MM-DD'),
                    account_id:"",
                    payment_mode_id:"",
                },
                accounts:[],
                payment_modes:[],
                users: [],
                expenses: [],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.getAccounts();
        },

        methods: {

            getAccounts() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getAccounts" })
                .then(function (response) {
                    vm.accounts = response.data.data;
                    vm.getPaymentModes()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getPaymentModes() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "paymentModes" })
                .then(function (response) {
                    vm.payment_modes = response.data.data;
                    vm.generateReport()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            generateReport(report_type) {
                let vm = this;
                vm.source = vm.$store.state.pdfUrl+"consolidatedExpenseReport?from_date=" + vm.meta.from_date + '&to_date=' + vm.meta.to_date + '&branch_id=' + vm.meta.branch_id + '&account_id=' + vm.meta.account_id + '&payment_mode_id=' + vm.meta.payment_mode_id + '&report_type=' + report_type;
            },

            reset() {
                let vm = this;
                vm.meta.from_date = moment().add(-30, 'days').format('YYYY-MM-DD');
                vm.meta.to_date = moment().format('YYYY-MM-DD');
                vm.meta.account_id = "";
                vm.meta.payment_mode_id = "";
                vm.generateReport('Data')
            }
        }
    };
</script>
