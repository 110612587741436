<template>
	<div class="content-wrapper">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<i class="fa fa-home"></i>
					Dashboard
				</li>
				<li class="breadcrumb-item active">Profile</li>

                <div style="margin-left: auto;">
                    <a type="button" class="btn btn-sm btn-secondary" target="_blank"  @click="clearCache">
                        <i class="ri-file-reduce-line"></i>Cache Clear
                    </a>
                </div>
			</ol>
		</nav>
		<div class="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-sm-3">
							<div class="card">
                             <div class="card-header">
								<strong>Update Avatar</strong>
                            </div>
                            <div class="card-body">
								<div class="text-center" v-if="user.avatar">
									<img width="200" :src="user.avatar">
								</div>
								<div class="form-group">
									<label class="form-label" for="customFile">Choose file</label>
									<input type="file" class="form-control" id="customFile" :class="{'is-invalid': errors.avatar}" @change="onImageChange($event)" accept="image/*">
								</div>
								<span v-if="errors.avatar" class="invalid-feedback">{{ errors.avatar[0] }}</span>
							</div>
						</div>
					</div>

					<div class="col-sm-9">
                        <form class="card" @submit.prevent="updateProfile">
                            <div class="card-header">
                                <strong>Update Profile</strong>
                            </div>
                            <div class="card-body">
                                <div class="row">
									<div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="tax_name">Name *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.name}" placeholder="Name" v-model="user.name" ref="name" />
                                            <span v-if="errors.name" class="invalid-feedback">{{ errors.name[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="username">Username *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.username}" placeholder="Username" v-model="user.username" />
                                            <span v-if="errors.username" class="invalid-feedback">{{ errors.username[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="email">Email *</label>
                                            <input type="email" class="form-control form-control-sm" :class="{'is-invalid': errors.email}" placeholder="Email Address" v-model="user.email" />
                                            <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="mobile_no">Mobile Number *</label>
                                            <input type="number" class="form-control form-control-sm" :class="{'is-invalid': errors.mobile_no}" placeholder="Mobile Number" v-model="user.mobile_no" />
                                            <span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
                                        </div>
                                    </div>
									<div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="address">Address</label>
                                            <textarea type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.address}" placeholder="Address" v-model="user.address"></textarea>
                                            <span v-if="errors.address" class="invalid-feedback">{{ errors.address[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button class="btn btn-sm btn-outline-success" type="submit">
										<span>
											<i class="fas fa-save"></i> Update
										</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'Proj-Profile',
		data(){
            return{
                status:true,
                user:{
					user_id:'',
					name: "",
                    email: "",
                    username: "",
                    mobile_no: "",
                    address: "",
                    avatar: "",
					current_password:'',
					new_password:'',
					confirm_password:'',
				},
                roles:[],
				users:[],
				errors:[]
            }
        },

		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.user.user_id=vm.$store.getters.user.user_id;
                let loader = vm.$loading.show();
				let uri = { uri: "showUser", data: vm.user };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.user=response.data.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error',error.response.data.message);
                });
			});
        },
		methods:{
			updateProfile() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'profile','data':vm.user};
				vm.$store.dispatch('post',uri)
				.then(function (response) {
                    console.log(response.data);
					loader.hide();
					vm.$store.dispatch('success','Profile is successfully updated');
                    vm.$store.dispatch("setUser", response.data.data);
                    vm.errors = [];
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},
            clearCache() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'clearCaches'};
				vm.$store.dispatch('post',uri)
				.then(function (response) {
                    console.log(response.data);
					loader.hide();
					vm.$store.dispatch('success','Cache Cleared Successfully');
                    vm.$store.response.data;
                    vm.errors = [];
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			onImageChange(e) {
                e.preventDefault();
                let vm = this;
                let image = e.target.files[0];
                let reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = (e) => {
                    vm.user.avatar = e.target.result;
                };
            },

            updatePassword() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'password','data':vm.user};
				vm.$store.dispatch('post',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Password is successfully updated');
					vm.$router.push('/login');
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			}
		}
	}
</script>
