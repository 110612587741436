<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Transaction</li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-4">
                        <div class="card card-widget widget-user">
                            <div class="widget-user-header bg-primary">
                                <h3 class="widget-user-username">{{ transaction?.customer?.customer_name }}</h3>
                            </div>
                            <div class="widget-user-image">
                                <img class="elevation-2" :src="transaction?.customer?.avatar" alt="Photo" />
                            </div>
                            <div class="card-body p-0" style="margin-top: 70px;">
                                <table class="table table-striped table-bordered table-sm">
                                    <tbody>
                                        <tr>
                                            <td>Transaction No</td>
                                            <td>{{transaction.transaction_no}}</td>
                                        </tr>
                                        <tr>
                                            <td>Transaction Date-Time</td>
                                            <td>{{transaction.transaction_date_time}}</td>
                                        </tr>
                                        <tr>
                                            <td>Transaction Type</td>
                                            <td>{{transaction.transaction_type}}</td>
                                        </tr>
                                        <tr>
                                            <td>Amount</td>
                                            <td>{{ transaction.amount }}</td>
                                        </tr>
                                        <tr>
                                            <td>Status</td>
                                            <td>{{ transaction.status }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Denominations</h3>
                            </div>
                            <div class="card-body">
                                <div class="row" v-if="transaction.transaction_type=='BuyIn'">
                                    <div class="col-sm-4" v-for="(stack,key) in stacks" :key="key">
                                        <div class="form-check">
                                            <input name="stack_id" @click="changeStack(stack)" class="form-check-input" type="radio" :id="stack.stack_id">
                                            <label class="form-check-label" :for="stack.stack_id">
                                                {{ stack.stack }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Chips</th>
                                            <th>Quantity</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(denomination,key) in denominations" :key="key">
                                            <td>{{denomination.denomination_value}}</td>
                                            <td>
                                                <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.quantity}" placeholder="Quantity" v-model="denomination.quantity"  @keyup="checkDenomination(denomination)"/>
                                            </td>
                                            <td class="text-right">
                                                {{ denomination.total = (denomination.quantity * denomination.denomination_value).toFixed(2) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="text-right" colspan="2">Grand Total</th>
                                            <th class="text-right">{{ grandTotal }}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="transaction.amount == grandTotal" class="card-footer">
                                <button class="btn btn-sm btn-success float-right" @click="addTransaction()">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "TransactionDenomination.Create",

        data() {
            return {
                transaction: {},
                denominations: [],
                stacks:[],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.getDenominations();
        },

        computed: {
            grandTotal: function () {
                let vm = this;
                let grand_total = vm.denominations.reduce((prev, cur) => +prev + +cur.total, 0).toFixed(2);
                vm.grand_total = grand_total;
                return grand_total;
            },
        },

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                let uri = { uri: "showTransaction", data: { transaction_id: to.params.transaction_id } };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.transaction = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            });
        },

        methods: {
            getDenominations() {
                let vm = this;
                let uri = { uri: "getSessionDenominations", data: {branch_id:vm.$store.getters.user.branch_id}};
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.denominations = response.data;
                    vm.getStacks()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getStacks() {
                let vm = this;
                let uri = { uri: "getStacks"};
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.stacks = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            addTransaction() {
                let vm = this;
                let loader = vm.$loading.show();
                let data = {
                    transaction_id: vm.$route.params.transaction_id,
                    denominations: vm.denominations,
                };
                let uri = { uri: "addTransactionDenominations", data: data };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Denomination is successfully created");
                    vm.$router.push("/dashboard");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            changeStack(stack) {
                let vm = this;
                vm.denominations.forEach(denomination => {
                    denomination.quantity = 0
                    stack.stack_denominations.forEach(stack_denomination => {
                        if(denomination.denomination_id == stack_denomination.denomination_id) {
                            denomination.quantity = stack_denomination.quantity;
                            vm.checkDenomination(denomination);
                        }
                    });
                });
            },

             checkDenomination(denomination) {
                let vm = this;
                if(vm.transaction.transaction_type =='CashOut') {
                    let total_qty = denomination.open - denomination.current;
                    if(total_qty < denomination.quantity) {
                        vm.$store.dispatch("error", 'Please enter valid quantity');
                        return denomination.quantity = 0;
                    }
                }
                if(vm.transaction.transaction_type =='BuyIn') {
                    if(denomination.current < denomination.quantity) {
                        vm.$store.dispatch("error", 'Please enter valid quantity');
                        return denomination.quantity = 0;
                    }
                }
            },
        },
    };
</script>
