<template>
    <th @click="sort(fieldName)">
        {{ placeholder }}
        <span>
            <i v-if="keyword == fieldName && orderBy=='asc'" class="fas fa-sort-up"></i>
            <i v-else-if="keyword == fieldName && orderBy=='desc'" class="fas fa-sort-down"></i>
            <i v-else class="fas fa-sort"></i>
        </span> 
    </th>
</template>
<script type="text/javascript">
    export default {
        props: {
            fieldName:{
                default: ''
            },
            placeholder:{
                default: ''
            },
            orderBy:{
                default: ''
            },
            keyword:{
                default: ''
            }
        },
        methods: {

            sort(keyword){
                this.$emit('sort_fun', keyword)
            }
        
         }
    }
</script>