<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">
                    Session
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <form class="card" @submit.prevent="addSession()">
                            <div class="card-header">
                                <h3 class="card-title">{{ session.session_type }}</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="session_date">Session Date *</label>
                                            <input type="date" class="form-control form-control-sm" :class="{'is-invalid': errors.session_date}" placeholder="Session Date" v-model="session.session_date" disabled />
                                            <span v-if="errors.session_date" class="invalid-feedback">{{ errors.session_date[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="session_time">Session Time *</label>
                                            <input type="time" class="form-control form-control-sm" :class="{'is-invalid': errors.session_time}" placeholder="Session Time" v-model="session.session_time" disabled />
                                            <span v-if="errors.session_time" class="invalid-feedback">{{ errors.session_time[0] }}</span>
                                        </div>
                                    </div>

                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="session_type">Session Type *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.session_type}" placeholder="Session Type" v-model="session.session_type" disabled />
                                            <span v-if="errors.session_type" class="invalid-feedback">{{ errors.session_type[0] }}</span>
                                        </div>
                                    </div>

                                    <div class="col-sm-3" v-if="session.session_type=='Experience Fees'">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Table *</label>
                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.table_id }" v-model="session.table_id">
                                                <option value="">Select table</option>
                                                <option v-for="(table,key) in tables" :key="key" :value="table.table_id">{{ table.table}}</option>
                                            </select>
                                            <span v-if="errors.table_id" class="invalid-feedback">{{ errors.table_id[0] }}</span>
                                        </div>
                                    </div>

                                    <div class="col-sm-3" v-if="session.session_type=='Tips'">
                                        <div class="form-group">
                                            <label for="dealer_id">Employee *</label>
                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.dealer_id }" v-model="session.dealer_id">
                                                <option value="">Select Employee</option>
                                                <option v-for="(employee,key) in employees" :key="key" :value="employee.user_id">{{ employee.name}}</option>
                                            </select>
                                            <span v-if="errors.dealer_id" class="invalid-feedback">{{ errors.dealer_id[0] }}</span>
                                        </div>
                                    </div>

                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="note">Note </label>
                                            <textarea class="form-control form-control-sm" :class="{'is-invalid': errors.note}" placeholder="Note" v-model="session.note"></textarea>
                                            <span v-if="errors.note" class="invalid-feedback">{{ errors.note[0] }}</span>
                                        </div>
                                    </div>

                                    <div class="col-sm-12">
                                        <table class="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">Chips</th>
                                                    <th class="text-center">Opening</th>
                                                    <th class="text-center">BuyIn</th>
                                                    <th class="text-center">CashOut</th>
                                                    <th class="text-center">ExpFees</th>
                                                    <th class="text-center">Tips</th>
                                                    <th class="text-center">ExIn</th>
                                                    <th class="text-center">ExOut</th>
                                                    <th class="text-center">Total</th>
                                                     <th v-if="session.session_type=='Exchange Chips'" class="text-center">Inward</th>
                                                    <th  v-else class="text-center">Qty</th>
                                                    <th class="text-center">Total</th>
                                                    <th v-if="session.session_type=='Exchange Chips'" class="text-center">To Chips</th>
                                                    <th v-if="session.session_type=='Exchange Chips'" class="text-center">Outward</th>
                                                    <th v-if="session.session_type=='Exchange Chips'" class="text-center">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(denomination,key) in denominations" :key="key">
                                                    <td class="text-center">{{ denomination.denomination_value }}</td>
                                                    <td class="text-center">{{ denomination.open }}</td>
                                                    <td class="text-center">{{ denomination.buy_in }}</td>
                                                    <td class="text-center">{{ denomination.cash_out }}</td>
                                                    <td class="text-center">{{ denomination.exp_fees }}</td>
                                                    <td class="text-center">{{ denomination.tips }}</td>
                                                    <td class="text-center">{{ denomination.ex_in }}</td>
                                                    <td class="text-center">{{ denomination.ex_out }}</td>
                                                    <td class="text-center">{{ denomination.current }}</td>
                                                    <td>
                                                        <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.quantity}" placeholder="Quantity" v-model="denomination.quantity" @keyup="checkDenomination(denomination)" />
                                                    </td>
                                                    <td class="text-right">
                                                        {{ denomination.amount = (denomination.quantity * denomination.denomination_value).toFixed(2) }}
                                                    </td>
                                                    <td v-if="session.session_type=='Exchange Chips'">
                                                        <select class="form-control form-control-sm" :class="{'is-invalid': errors.quantity}" v-model="denomination.to_denomination_id" @change="checkToDenomination(denomination)">
                                                            <option value="">Select One</option>
                                                            <option v-for="(deno,index) in denominations" :key="index" :value="deno.denomination_id">
                                                                {{ deno.denomination_value }}
                                                            </option>
                                                        </select>
                                                    </td>
                                                    <td v-if="session.session_type=='Exchange Chips'">
                                                        <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.to_quantity}" placeholder="To Quantity" v-model="denomination.to_quantity" @keyup="checkToDenomination(denomination)" />
                                                    </td>
                                                    <td class="text-right" v-if="session.session_type=='Exchange Chips'">
                                                        {{ (denomination.exchange_amount*1).toFixed(2) }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th class="text-right" colspan="10">Grand Total</th>
                                                    <th class="text-right">{{ amount }}</th>
                                                    <th v-if="session.session_type=='Exchange Chips'" class="text-right" colspan="2">Grand Total</th>
                                                    <th v-if="session.session_type=='Exchange Chips'" class="text-right">{{ exchangeAmount }}</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer" v-if="session.session_type=='Exchange Chips' && amount == exchangeAmount">
                                <div class="float-right">
                                    <router-link to="/sessions" class="btn btn-sm btn-outline-danger" type="button"> 
                                        <i class="fas fa-ban"></i> Discard 
                                    </router-link>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span> <i class="fas fa-save"></i> Submit </span>
                                    </button>
                                </div>
                            </div>
                            <div class="card-footer" v-if="session.session_type!='Exchange Chips' && amount!=0">
                                <div class="float-right">
                                    <router-link to="/sessions" class="btn btn-sm btn-outline-danger" type="button"> 
                                        <i class="fas fa-ban"></i> Discard 
                                    </router-link>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span> <i class="fas fa-save"></i> Submit </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import moment from 'moment'
    export default {
        name: "Session.Create",
        data() {
            return {
                flag: "",
                status: "",
                session: {
                    session_log_id:'',
                    session_type: "Open",
                    session_date: moment().format('YYYY-MM-DD'),
                    session_time: moment().format('HH:mm:ss'),
                    branch_id: this.$store.getters.user.branch_id,
                    user_id: this.$store.getters.user.user_id,
                    table_id: "",
                    employee_id: "",
                    note: "",
                    denominations:[]
                },
                denominations: [],
                employees: [],
                tables: [],
                errors: [],
            };
        },
        computed: {
            amount: function () {
                let vm = this;
                return  vm.denominations.reduce((prev, cur) => +prev + +cur.amount, 0).toFixed(2);
            },
            exchangeAmount: function () {
                let vm = this;
                return  vm.denominations.reduce((prev, cur) => +prev + +cur.exchange_amount, 0).toFixed(2);
            }
        },

        mounted() {
            let vm = this;
            vm.session.session_type = vm.$route.query.session_type;
            vm.session.session_log_id = vm.$route.query.session_log_id;
            vm.getDenominations()
        },
        methods: {
            getDenominations() {
                let vm = this;
                vm.session.branch_id = vm.$store.getters.user.branch_id;
                let uri = { uri: "getSessionDenominations", data: vm.session  };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.denominations = response.data;
                    vm.getEmployees()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getEmployees() {
                let vm = this;
                let uri = { uri: "getBranchUsers", data: vm.session  };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.employees = response.data.data;
                    vm.getTables()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getTables() {
                let vm = this;
                let uri = { uri: "getBranchTables", data: vm.session  };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.tables = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            checkDenomination(denomination) {
                let vm = this;
                if(vm.session.session_type=='Experience Fees' || vm.session.session_type=='Tips' || vm.session.session_type=='Exchange Chips') {
                    let total_qty = denomination.open - denomination.current;
                    if(total_qty < denomination.quantity) {
                        vm.$store.dispatch("error", 'Please enter valid quantity');
                        denomination.quantity = 0;
                    }
                }
            },

            checkToDenomination(denomination) {
                let vm = this;
                let to_denominations = vm.denominations.filter(to_denomination =>{
                    return to_denomination.to_denomination_id == denomination.to_denomination_id && to_denomination.denomination_id == denomination.denomination_id;
                })
                if(to_denominations.length!=0) {
                    let denos = vm.denominations.filter(deno =>{
                        return deno.denomination_id == to_denominations[0].to_denomination_id
                    });
                    denomination.exchange_amount = (denomination.to_quantity * denos[0].denomination_value).toFixed(2);
                    if(parseFloat(denomination.exchange_amount) > parseFloat(denomination.amount) && parseFloat(denos[0].current) < parseFloat(denomination.to_quantity)) {
                        vm.$store.dispatch("error", 'Please enter valid quantity');
                        denomination.to_quantity = 0;
                        denomination.exchange_amount = 0;
                    }
                }
            },

            addSession() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.session.denominations = vm.denominations;
                let uri = { uri: "addSession", data: vm.session };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Session is successfully created");
                    vm.$router.push("/sessions");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
        },
    };
</script>
