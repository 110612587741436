<template>
    <div class="content-wrapper" v-if="purchase_order">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">Transaction</li>
                <li class="breadcrumb-right" v-can="'transactions.change_status'">
                    <div class="input-group">
                        <select class="form-control form-control-sm" v-model="transaction.status">
                            <option>Approved</option>
                            <option>Rejected</option>
                            <option>Pending</option>
                        </select>
                        <div class="input-group-append">
                            <button @click="changeTransactionStatus" class="btn btn-sm btn-success" type="button">Update</button>
                        </div>
                    </div>
                </li>
            </ol>
        </nav>

        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="card card-widget widget-user">
                            <div class="widget-user-header bg-primary">
                                <h3 class="widget-user-username">{{ transaction?.customer?.customer_name }}</h3>
                            </div>
                            <div class="widget-user-image">
                                <img class="elevation-2" :src="transaction?.customer?.avatar" alt="Photo" />
                            </div>
                            <div class="card-body p-0" style="margin-top: 70px;">
                                <table class="table table-striped table-bordered table-sm">
                                    <tbody>
                                        <tr>
                                            <td>Transaction No</td>
                                            <td>{{transaction.transaction_no}}</td>
                                        </tr>
                                        <tr>
                                            <td>Transaction Date-Time</td>
                                            <td>{{transaction.transaction_date_time}}</td>
                                        </tr>
                                        <tr>
                                            <td>Transaction Type</td>
                                            <td>{{transaction.transaction_type}}</td>
                                        </tr>
                                        <tr>
                                            <td>Amount</td>
                                            <td>{{ transaction.amount }}</td>
                                        </tr>
                                        <tr v-if="transaction.promotion_value!=0">
                                            <td>Promotion Value</td>
                                            <td>{{ transaction.promotion_value }}</td>
                                        </tr>
                                        <tr v-if="transaction.promotion_value!=0">
                                            <td>Total Amount</td>
                                            <td>{{ transaction.total_amount }}</td>
                                        </tr>
                                        <tr v-if="transaction.attachment">
                                            <td>Attachment</td>
                                            <td><a :href="transaction.attachment" target="_blank">Download</a></td>
                                        </tr>
                                        <tr>
                                            <td>Status</td>
                                            <td>
                                                <span v-if="transaction.status=='Pending'" class="badge badge-warning">
                                                    Pending
                                                </span>
                                                <span v-if="transaction.status=='Approved'" class="badge badge-success">
                                                    Approved
                                                </span>
                                                <span v-if="transaction.status=='Rejected'" class="badge badge-danger">
                                                    Rejected
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-8" v-if="transaction.transaction_type=='BuyIn' || transaction.transaction_type=='CashOut'">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Denominations</h3>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive text-nowrap">
                                    <table class="table table-bordered table-striped table-sm">
                                        <thead>
                                            <tr>
                                                <th class="text-center">#</th>
                                                <th class="text-center">Denominations</th>
                                                <th class="text-center">Quantity</th>
                                                <th class="text-center">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(transaction_denomination,key) in transaction.transaction_denominations" :key="key" @click="view_transaction(transaction)">
                                                <td class="text-center">{{meta.from+key}}</td>
                                                <td>{{transaction_denomination.denomination.denomination_name}}</td>
                                                <td>{{transaction_denomination.quantity}}</td>
                                                <td>{{transaction_denomination.total}}</td>
                                            </tr>
                                            <tr v-if="transaction.transaction_denominations == 0">
                                                <td colspan="20" style="text-align: center;">No Records Found</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "Transactions.View",
        data() {
            return {
                status: true,
                payment_request_status: true,
                payment_status: true,

                transaction: {},

                meta: {
                    search: "",
                    order_by: "desc",
                    keyword: "purchase_order_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },

                purchase_order: {
                    purchase_order_id: "",
                    purchase_order_number: "",
                    purchase_order_date: "",
                    vendor_id: "",
                    po_raised_by: "",
                    payment_term_id: "",
                    last_date_of_shipment: "",
                    currency_id: "",
                    material_value: "",
                    other_charges: "",
                    total: "",
                    remark: "",
                    vendors: [],
                    users: [],
                    payment_terms: [],
                    tax_documents: [],
                    deleted_tax_documents: [],
                    documents: [],
                    images: [],
                },
                payment_request: {
                    request_id: "",
                    request_no: "",
                    request_date: new Date().toISOString().slice(0, 10),
                    invoice_no: "",
                    invoice_date: "",
                    awb_no: "",
                    awb_date: "",
                    amount: "",
                    document_attached: "",
                    target_date: "",
                    invoice: "",
                    all_documents_attached: "",
                    comments: "",
                    authorization: 0,
                    payment_request_documents: [],
                },
                payment: {
                    purchase_order_id: "",
                    payment_request_id: "",
                    payment_no: "",
                    payment_date: new Date().toISOString().slice(0, 10),
                    amount: "",
                    payment_mode_id: "",
                    paid_by: "",
                    payment_details: "",
                    ld_clause: "",
                    comments: "",
                    swift_code: "",
                    payment_documents: [],
                },
                discussion: {
                    purchase_order_id: "",
                    payment_request_id: "",
                    payment_id: "",
                    user_id: "",
                    comment: "",
                },

                send_message: {
                    user_ids: [],
                    payment_request_id: "",
                },

                message_users: [],
                users: [],
                p_documents: [],
                pr_documents: [],
                view_payment_request: {},
                view_payment: {},
                discussions: [],
                delete_discussion: [],
                payment_modes: [],
                errors: [],
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                let uri = { uri: "showTransaction", data: { transaction_id: to.params.transaction_id } };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.transaction = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            });
        },

        methods: {
            changeTdsPaidStatus() {
                let vm = this;
                let data = {
                    transaction_id:vm.transaction.transaction_id,
                    is_tds_paid:vm.transaction.is_tds_paid?0:1,
                }
                let loader = vm.$loading.show();
                let uri = { uri: "transactions/changeTdsPaidStatus", data: data };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", "TDS status has been successfully updated");
                    vm.transaction = response.data.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            changeTransactionStatus() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "changeTransactionStatus", data: vm.transaction };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", "Transaction status has been successfully updated");
                    vm.transaction = response.data.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            }
        },
    };
</script>
