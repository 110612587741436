<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Credit & OBR Report</li>
                <li class="breadcrumb-right">
                    <a type="button" class="btn btn-sm btn-success" target="_blank"  @click="downloadExcel" :href="$store.getters.apiUrl+'emptyExcel'">
                        <i class="fa fa-download mr-1"></i>Download
                    </a>
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Credit & OBR Report</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3 g-2">
                                        <div class="form-group">
                                            <label for="from_date">Report Type</label>
                                            <select class="form-control form-control-sm" v-model="meta.credit_type" @change="index">
                                                <option value="">Select Report Type</option>
                                                <option value="Credit">Credit</option>
                                                <option value="OBR">OBR</option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-3 g-2">
                                        <div class="form-group">
                                            <label for="customer_id">Customer </label>
                                            <Customer :class="{'is-invalid': errors.customer_id}" :customClass="{'is-invalid': errors.customer_id}" :initialize="meta.customer_name" @selectCustomer="selectCustomer($event)" ref="customer_id"></Customer>
                                            <span v-if="errors.customer_id" class="invalid-feedback">{{ errors.customer_id[0] }}</span>
                                        </div>
                                    </div> -->
                                </div>

                                <div class="table-responsive">
                                    <table class="table table-bordered table-striped table-sm">
                                        <thead>
                                            <tr>
                                                <th class="text-center">#</th>
                                                <th>Customer Code</th>
                                                <th>Customer Name</th>
                                                <th>Mobile Number</th>
                                                <th class="text-right">Amount</th>
                                                <th>Transaction No</th>
                                                <th class="text-right">Credit Limite</th>
                                                <th class="text-right">Wallet Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(transaction,key) in transactions" :key="key">
                                                <td class="text-center">{{key+1}}</td>
                                                <td>{{ transaction.customer.customer_no }}</td>
                                                <td>{{ transaction.customer.customer_name }}</td>
                                                <td>{{ transaction.customer.mobile_no }}</td>
                                                <td class="text-right">{{ transaction.amount }}</td>
                                                <td>{{ transaction.transaction.transaction_no }}</td>
                                                <td class="text-right">{{ transaction.credit_limit }}</td>
                                                <td class="text-right">{{ transaction.wallet_balance }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    // import Pagination from "@/components/Pagination.vue";
    // let Customer = require('../../components/Customer.vue').default;
    export default {
        name: "User.Index",
        // components: { Pagination },
        data() {
            return {
                status: true,
                meta: {
                    credit_type: "Credit",
                    branch_id:this.$store.getters.user.branch_id
                },
                users: [],
                customers: [],
                transactions: [],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.index();
        },

        methods: {
            formatCurrency(amount) {
                // Convert number to Indian standard currency format
                const formattedAmount = new Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: 'INR'
                }).format(amount);

                return formattedAmount;
            },
            index() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "paginateCredits", data: vm.meta })
                .then(function (response) {
                    vm.transactions = response.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
        },
    };
</script>
