<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">
                    Employee
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <form class="card" @submit.prevent="submitForm()">
                            <div class="card-header">
                                <h3 class="card-title" v-if="status">New Employee</h3>
                                <h3 class="card-title" v-else>Update Employee</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="employee_no">Employee No *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.employee_no}" placeholder="Employee No" v-model="employee.employee_no" disabled="disabled" />
                                            <span v-if="errors.employee_no" class="invalid-feedback">{{ errors.employee_no[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="name">Name *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.name}" placeholder="Name" v-model="employee.name" ref="name" />
                                            <span v-if="errors.name" class="invalid-feedback">{{ errors.name[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="email">Email </label>
                                            <input type="email" class="form-control form-control-sm" :class="{'is-invalid': errors.email}" placeholder="Email Address" v-model="employee.email" />
                                            <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="mobile_no">Mobile No *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.mobile_no}" placeholder="Mobile No" v-model="employee.mobile_no" />
                                            <span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="aadhar">Aadhar No</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.aadhar}" placeholder="Aadhar No" v-model="employee.aadhar" />
                                            <span v-if="errors.aadhar" class="invalid-feedback">{{ errors.aadhar[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="pan">PAN No</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.pan}" placeholder="PAN No" v-model="employee.pan" />
                                            <span v-if="errors.pan" class="invalid-feedback">{{ errors.pan[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="pan">Account No</label>
                                            <input type="number" class="form-control form-control-sm" :class="{'is-invalid': errors.account_no}" placeholder="Account No" v-model="employee.account_no" />
                                            <span v-if="errors.account_no" class="invalid-feedback">{{ errors.account_no[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="ifsc_code">IFSC Code</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.ifsc_code}" placeholder="IFSC Code" v-model="employee.ifsc_code" />
                                            <span v-if="errors.ifsc_code" class="invalid-feedback">{{ errors.ifsc_code[0] }}</span>
                                        </div>
                                    </div>

                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="ifsc_code">Address</label>
                                            <textarea class="form-control form-control-sm" :class="{'is-invalid': errors.address}" placeholder="Address" v-model="employee.address"></textarea>
                                            <span v-if="errors.address" class="invalid-feedback">{{ errors.address[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link to="/employees" class="btn btn-sm btn-outline-danger" type="button"> <i class="fas fa-ban"></i> Discard </router-link>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                        <span v-else> <i class="fas fa-save"></i> Update </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "Employees.Create",
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "name",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },
                employee: {
                    name: "",
                    email: "",
                    mobile_no: "",
                    address: "",
                    aadhar: "",
                    pan: "",
                    account_no: "",
                    ifsc_code: "",
                    employee_no: "",
                    branch_id: "",
                },
                employees: [],
                errors: [],
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (to.name == "Employees.Create") {
                    vm.$refs.name.focus();
                    vm.getEmployeeNo();
                } else {
                    vm.status = false;
                    let uri = { uri: "showEmployee", data: { employee_id: to.params.employee_id } };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function (response) {
                            vm.employee = response.data.data;
                        })
                        .catch(function (error) {
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            });
        },

        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },

            edit(employee) {
                this.$router.push("/employees/create/" + employee.employee_id + "/edit");
            },

            getEmployeeNo() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getEmployeeNo" })
                    .then(function (response) {
                        vm.employee.employee_no = response.data["employee_no"];
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateEmployees", data: vm.meta })
                    .then(function (response) {
                        vm.employees = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            isTrashed() {
                let vm = this;
                vm.meta.trashed = !vm.meta.trashed;
                vm.index();
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.employee.branch_id = vm.$store.getters.user.branch_id;
                let uri = { uri: "addEmployee", data: vm.employee };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Employee is successfully created");
                    vm.$router.push("/employees");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            update() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateEmployee", data: vm.employee };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Employee is successfully updated");
                    vm.$router.push("/employees");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
        },
    };
</script>
