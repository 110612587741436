<template>
    <div class="hold-transition login-page">
        <div class="login-box">
            <div class="card">
                <div class="card-header text-center">
                    <img src="@/assets/logo.jpg" alt="Logo" height="80" />
					<h4>GOLDEN ACES CLUB</h4>
                </div>
                <div class="card-body">
                    <p class="login-box-msg">Kindly enter new password </p>
                    <form autocomplete="off" @submit.prevent="resetPassword">
						<div class="form-group mb-3">
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text">
										<span class="fas fa-envelope"></span>
									</div>
								</div>
								<input type="text" class="form-control" :class="{'is-invalid': errors.email}" placeholder="Enter your email" v-model="user.email" ref="email" tabindex="1">
								<span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
							</div>
						</div>
						<div class="form-group mb-3">
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text">
										<span class="fas fa-unlock"></span>
									</div>
								</div>
								<input type="text" class="form-control" :class="{'is-invalid': errors.reset_code}" placeholder="Enter reset code" v-model="user.reset_code" tabindex="2">
								<span v-if="errors.reset_code" class="invalid-feedback">{{ errors.reset_code[0] }}</span>
							</div>
						</div>
                        <div class="form-group mb-3">
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text">
										<span class="fas fa-lock"></span>
									</div>
								</div>
								<input type="password" class="form-control" :class="{'is-invalid': errors.new_password}" placeholder="Enter New Password" v-model="user.new_password" tabindex="2">
								<span v-if="errors.new_password" class="invalid-feedback">{{ errors.new_password[0] }}</span>
							</div>
						</div>
                        <div class="form-group mb-3">
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text">
										<span class="fas fa-lock"></span>
									</div>
								</div>
								<input type="password" class="form-control" :class="{'is-invalid': errors.confirm_password}" placeholder="Confirm Password" v-model="user.confirm_password" tabindex="2">
								<span v-if="errors.confirm_password" class="invalid-feedback">{{ errors.confirm_password[0] }}</span>
							</div>
						</div>
						<div class="form-group">
							<button method="submit" class="btn btn-primary btn-block">Submit</button>
						</div>
     
					</form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Proj-resetpassword",
        data() {
            return {
                user: {
                    email: "",
                    reset_code: "",
                    new_password:"",
                    confirm_password:""
                },
                errors: [],
            };
        },
        mounted() {
            this.$refs.email.focus();
        },
        methods: {
            resetPassword() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("auth", { uri: "resetPassword", data: vm.user })
				.then(function (response) {
					loader.hide();
					vm.$store.dispatch("setUser", response.data);
					vm.$router.push("/dashboard");
                    location.reload();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
            }
        },
    };
</script>

<style scoped>
    .card-body {
        padding: 30px;
    }
</style>
