<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Productivity Report</li>
                <li class="breadcrumb-right">
                    <a type="button" class="btn btn-sm btn-success" target="_blank"  @click="downloadExcel" :href="$store.getters.apiUrl+'emptyExcel'">
                        <i class="fa fa-download mr-1"></i>Download
                    </a>
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Productivity Report</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="from_date">Month</label>
                                            <input type="month" class="form-control form-control-sm" v-model="meta.month">
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group" style="margin-top: 26px !important;">
                                            <button class="btn btn-sm btn-primary mr-2" @click="index"><i class="fa fa-search mr-1"></i>Search</button>
                                            <button class="btn btn-sm btn-danger" @click="discard"><i class="fa fa-times mr-1"></i>Discard</button>
                                        </div>
                                    </div>
                                </div>

                                <div class="table-responsive">
                                    <table class="table table-bordered table-striped table-sm">
                                        <thead>
                                            <tr>
                                                <th class="text-center">#</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>


                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import moment from "moment";
    export default {
        name: "Report.ExperienceReport",
        data() {
            return {
                status: true,
                meta: {
                    branch_id:this.$store.getters.user.branch_id,
                    month:"",
                    order_by: "asc",
                    keyword: "start",
                },
                session_logs:[],
                total_days:0,
                total_working_days:0,
                gst:0,
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.discard()
        },

        methods: {
            index() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "employeeProductivityReport", data: vm.meta })
                .then(function (response) {
                    vm.session_logs = response.data.session_logs;
                    vm.total_days = response.data.total_days;
                    vm.total_working_days = response.data.total_working_days;
                    vm.total_experience_fees = response.data.total_experience_fees;
                    vm.average_experience_fees = response.data.average_experience_fees;
                    vm.forecasted_experience_fees = response.data.forecasted_experience_fees;
                    vm.total_gst = response.data.total_gst;
                    vm.balance_experience_fees = response.data.balance_experience_fees;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            discard() {
                let vm = this;
                vm.meta.month = moment().format('YYYY-MM');
                vm.index()
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },
        },

        computed: {
            totalActiveTables() {
                return this.session_logs.reduce((total, session_log) => {
                    return total + session_log.active_tables;
                }, 0);
            },
        },
    };
</script>
