<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Employees</li>
                <li class="breadcrumb-right">
                    <router-link to="/employees/create" class="btn btn-sm btn-success">
                        Add New
                    </router-link>
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Employees</h3>
                                <div class="card-tools">
                                    <div class="custom-control custom-switch custom-switch-off-success custom-switch-on-danger">
                                        <input type="checkbox" class="custom-control-input" id="trashed" v-model="meta.trashed" @click="isTrashed" />
                                        <label class="custom-control-label" for="trashed">Trashed</label>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <input class="form-control form-control-sm" type="text" placeholder="Search" v-model="meta.search" @keyup="search" />
                                </div>
                                <div class="table-responsive table-responsive-sm text-nowrap">
                                    <table class="table table-bordered table-striped table-sm">
                                        <thead>
                                            <tr>
                                                <th class="text-center">#</th>
                                                <th>Employee No</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Mobile No</th>
                                                <th>Aadhar No</th>
                                                <th>Pan No</th>
                                                <th class="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(employee,key) in employees" :key="key">
                                                <td class="text-center">{{meta.from+key}}</td>
                                                <td v-if="employee.employee_no">{{employee.employee_no}}</td>
                                                <td v-else>-</td>
                                                <td>{{employee.name}}</td>
                                                <td v-if="employee.email">{{employee.email}}</td>
                                                <td v-else>-</td>
                                                <td>{{employee.mobile_no}}</td>
                                                <td v-if="employee.aadhar">{{employee.aadhar}}</td>
                                                <td v-else>-</td>
                                                <td v-if="employee.pan">{{employee.pan}}</td>
                                                <td v-else>-</td>
                                                
                                                <td class="text-center" v-if="!meta.trashed">
                                                    <button class="btn btn-xs btn-outline-success" data-toggle="tooltip" data-placement="bottom" title="Edit">
                                                        <i class="fas fa-pencil-alt" @click="edit(employee)"></i>
                                                    </button>
                                                    <button class="btn btn-xs btn-outline-danger" data-toggle="tooltip" data-placement="bottom" title="Delete" @click="deleteEmployee(employee)">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                                <td class="text-center" v-else>
                                                    <button class="btn btn-xs btn-outline-success" @click="deleteEmployee(employee)" data-toggle="tooltip" data-placement="bottom" title="Trash">
                                                        <i class="fas fa-trash-restore"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr v-if="employees.length <= 0">
                                                <td colspan="20" style="text-align: center;">No Records Found</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Employee.Index",
        components: { Pagination },
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "name",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                    branch_id : this.$store.getters.user.branch_id
                },
                employee: {
                    employee_id: "",
                },
                employees: [],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.index();
        },

        methods: {
            edit(employee) {
                this.$router.push("/employees/" + employee.employee_id + "/edit");
            },


             search(){
               let vm = this;
               vm.meta.page = 1;
               vm.index();

            },

            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateEmployees", data: vm.meta })
                    .then(function (response) {
                        vm.employees = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            isTrashed() {
                let vm = this;
                vm.meta.trashed = !vm.meta.trashed;
                if(vm.meta.trashed)
                    vm.meta.page = 1
                vm.index();
            },
            
            deleteEmployee(employee) {
                if (confirm("Do you want to continue... ?")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    console.log(employee);
                    let uri = { uri: "deleteEmployee", data: employee };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "Employee is successfully deleted");
                            vm.index();
                            vm.trashed();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
        },
    };
</script>
