<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Footfall Report</li>
                <li class="breadcrumb-right">
                    <button class="btn btn-sm btn-primary mr-1" @click="search">
                        <i class="fa fa-search mr-1"></i>Search
                    </button>
                    <button type="button" @click="reset" class="btn btn-sm btn-danger mr-1">
                        <i class="fa fa-undo"></i>
                        Reset
                    </button>
                    <a type="button" class="btn btn-sm btn-success" target="_blank"  @click="downloadExcel" :href="$store.getters.apiUrl+'emptyExcel'">
                        <i class="fa fa-download mr-1"></i>Download
                    </a>
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">{{ meta.report_type }} Report</h3>
                                <div class="card-tools">
                                    <label @click="changeReportType('Unique Footfall')">Unique Footfall : {{ meta.totalRows }}</label>
                                    <label class="pl-4">Total Footfall : {{ total_footfall }}</label>
                                    <label @click="changeReportType('New Signup')" class="pl-4">New Signup : {{ new_signup }}</label>
                                    <label class="pl-4">Total Customers : {{ total_customers }}</label>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label for="from_date">From Date *</label>
                                            <input class="form-control form-control-sm" type="date" placeholder="Search" v-model="meta.from_date" />
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label for="from_date">To Date *</label>
                                            <input class="form-control form-control-sm" type="date" placeholder="Search" v-model="meta.to_date" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="customer_id">Customer </label>
                                            <Customer :initialize="meta.customer_name" @selectCustomer="selectCustomer($event)" ref="customer_id"></Customer>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label for="customer_id">Group </label>
                                            <select class="form-control form-control-sm" v-model="meta.group_id">
                                                <option value="">Select Group</option>
                                                <option v-for="(group,key) in groups" :key="key" :value="group.group_id">
                                                    {{ group.group }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="customer_id">Min</label>
                                            <input type="number" class="form-control form-control-sm" placeholder="Min" v-model="meta.min">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="customer_id">Max</label>
                                            <input type="number" class="form-control form-control-sm" placeholder="Max" v-model="meta.max">
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th class="text-center" colspan="4">Customer Details</th>
                                                    <th class="text-center" colspan="3">Last Check-in</th>
                                                </tr>
                                                <tr>
                                                    <th class="text-center">#</th>
                                                    <th @click="sort('customer_no')">
                                                        Customer No
                                                        <span>
                                                            <i v-if="meta.keyword=='customer_no' && meta.order_by=='asc'"
                                                                class="fas fa-sort-up"></i>
                                                            <i v-else-if="meta.keyword=='customer_no' && meta.order_by=='desc'"
                                                                class="fas fa-sort-down"></i>
                                                            <i v-else class="fas fa-sort"></i>
                                                        </span>
                                                    </th>
                                                    <th @click="sort('customer_name')">
                                                        Customer Name
                                                        <span>
                                                            <i v-if="meta.keyword=='customer_name' && meta.order_by=='asc'"
                                                                class="fas fa-sort-up"></i>
                                                            <i v-else-if="meta.keyword=='customer_name' && meta.order_by=='desc'"
                                                                class="fas fa-sort-down"></i>
                                                            <i v-else class="fas fa-sort"></i>
                                                        </span>
                                                    </th>
                                                    <th>Group</th>
                                                    <th @click="sort('latest_logs.latest_start_date_time')">
                                                        Date
                                                        <span>
                                                            <i v-if="meta.keyword=='latest_logs.latest_start_date_time' && meta.order_by=='asc'"
                                                                class="fas fa-sort-up"></i>
                                                            <i v-else-if="meta.keyword=='latest_logs.latest_start_date_time' && meta.order_by=='desc'"
                                                                class="fas fa-sort-down"></i>
                                                            <i v-else class="fas fa-sort"></i>
                                                        </span>
                                                    </th>
                                                    <th @click="sort('latest_logs.latest_start_date_time')">
                                                        Days
                                                        <span>
                                                            <i v-if="meta.keyword=='latest_logs.latest_start_date_time' && meta.order_by=='asc'"
                                                                class="fas fa-sort-down"></i>
                                                            <i v-else-if="meta.keyword=='latest_logs.latest_start_date_time' && meta.order_by=='desc'"
                                                                class="fas fa-sort-up"></i>
                                                            <i v-else class="fas fa-sort"></i>
                                                        </span>
                                                    </th>
                                                    <th @click="sort('visits')">
                                                        Total Visits
                                                        <span>
                                                            <i v-if="meta.keyword=='visits' && meta.order_by=='asc'"
                                                                class="fas fa-sort-down"></i>
                                                            <i v-else-if="meta.keyword=='visits' && meta.order_by=='desc'"
                                                                class="fas fa-sort-up"></i>
                                                            <i v-else class="fas fa-sort"></i>
                                                        </span>
                                                    </th>
                                                    <th class="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(customer,key) in customers" :key="key">
                                                    <td class="text-center">{{meta.from+key}}</td>
                                                    <td>
                                                        <router-link :to="'/customers/'+customer.customer_id+'/view'">
                                                            {{ customer.customer_no }}
                                                        </router-link>
                                                    </td>
                                                    <td>
                                                        <router-link :to="'/customers/'+customer.customer_id+'/view'">
                                                            {{ customer.customer_name }}
                                                        </router-link>
                                                    </td>
                                                    <td>
                                                        <span class="badge badge-success mr-1" v-for="(customer_group,key) in customer.customer_groups" :key="key">
                                                            {{ customer_group?.group?.group }}
                                                        </span>
                                                        <span class="cursor badge badge-primary mr-1" data-toggle="modal" data-target="#add_groups" @click="updateCustomer(customer)">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                    </td>
                                                    <td class="text-center" v-if="customer.log && customer.log.start_date_time!=null">
                                                        {{ $formatDate(customer?.log?.start_date_time, 'short') }}
                                                    </td>
                                                    <td v-else></td>
                                                    <td class="text-center">{{ customer.days_since_last_visit }}</td>
                                                    <td class="text-center">{{ customer.visits }}</td>
                                                    <td class="text-center">
                                                        <button class="btn btn-xs btn-outline-secondary" @click.prevent="callCustomer(customer.mobile_no)">
                                                            <i class="fa fa-phone"></i>
                                                        </button>
                                                        <a class="btn btn-xs btn-outline-primary" :href="'mailto:'+customer.email">
                                                            <i class="fa fa-envelope"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr v-if="customers.length<=0">
                                                    <td colspan="8" class="text-center">
                                                        No Records Found
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="modal fade" id="add_groups">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Assign Groups</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-4" v-for="(group,key) in groups" :key="key">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :id="group.group_id" :value="group.group_id" v-model="customer_groups">
                                    <label class="form-check-label" :for="group.group_id">
                                        {{ group.group }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" ref="CloseGroup">
                            <i class="fa fa-times"></i> Close
                        </button>
                        <button type="button" class="btn btn-success" @click="addGroup()">
                            <i class="fas fa-save"></i> Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import Pagination from "@/components/Pagination.vue";
    let Customer = require('../../components/Customer.vue').default;
    export default {
        name: "Customer.Attendance",
        components: { Customer, Pagination },
        data() {
            return {
                meta: {
                    branch_id:this.$store.getters.user.branch_id,
                    from_date: moment().startOf('month').format('YYYY-MM-DD'),
                    to_date:moment().format('YYYY-MM-DD'),
                    customer_id:'',
                    customer_name:'',
                    group_id:'',
                    report_type:'Unique Footfall',
                    min:'',
                    max:'',
                    order_by: "desc",
                    keyword: "latest_logs.latest_start_date_time",
                    per_page: 50,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
                total_footfall:0,
                new_signup:0,
                total_customers:0,
                customers:[],
                customer_groups: [],
                groups: [],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.getGroups();
            vm.index();
        },
        methods: {

            index() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "paginateCustomerAttendance", data: vm.meta })
                .then(function (response) {
                    vm.customers = response.data.customers.data;
                    vm.total_footfall = response.data.total_footfall;
                    vm.new_signup = response.data.new_signup;
                    vm.total_customers = response.data.total_customers;
                    vm.meta.totalRows = response.data.customers.total;
                    vm.meta.lastPage = response.data.customers.last_page;
                    vm.meta.from = response.data.customers.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getGroups() {
                let vm = this;
                if(this.$store.getters.groups.length>0){
                    vm.groups = vm.$store.getters.groups;
                }
                else{
                    vm.$store.dispatch("post", { uri: "getGroups" })
                    .then(function (response) {
                        vm.groups = response.data.data;
                        vm.$store.dispatch('setGroups', vm.groups);
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

            updateCustomer(customer) {
                let vm = this;
                vm.customer_id = customer.customer_id;
                let selected_groups = customer.customer_groups;
                vm.customer_groups = [];
                selected_groups.forEach(selected_group => {
                    vm.customer_groups.push(selected_group.group_id)
                });
            },

            addGroup() {
                let vm = this;
                let loader = vm.$loading.show();
                let data = {
                    customer_id: vm.customer_id,
                    groups:vm.customer_groups
                }
                let uri = { uri: "addCustomerGroup", data: data };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Customer Group is successfully updated");
                    vm.$refs.CloseGroup.click();
                    vm.index()
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            selectCustomer(e){
                let vm = this;
                const customer = e.target.value;
                vm.meta.customer_id = customer.customer_id;
                vm.meta.customer_name = customer.customer_name;
            },

            search() {
                this.meta.page = 1;
                this.index();
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },

            sort(keyword) {
                this.meta.keyword = keyword;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.search();
            },

            reset() {
                let vm = this;
                vm.meta.from_date = moment().startOf('month').format('YYYY-MM-DD');
                vm.meta.to_date = moment().format('YYYY-MM-DD');
                vm.meta.report_type = "Unique Footfall";
                vm.meta.customer_id = "";
                vm.meta.customer_name = "";
                vm.meta.group_id = "";
                vm.meta.min = "";
                vm.meta.max = "";
                vm.search()
            },
            changeReportType(report_type) {
                let vm = this;
                vm.meta.report_type = report_type;
                vm.search()
            },

            callCustomer(to) {
                let vm = this;
                let loader = vm.$loading.show();
                let data = {
                    From: '0'+vm.$store.getters.user.mobile_no,
                    To:'0'+to
                }
                let uri = { uri: "customerCallConnect", data: data };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Call connected, please wait for sometime");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            }
        }
    };
</script>
