<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">
                    Withdraw Tips
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-4">
                        <form class="card" @submit.prevent="addTipsWithDraw()">
                            <div class="card-header">
                                <h3 class="card-title">{{ user.name }} : {{ withdraw.balance }}</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="reference_date">Reference Date *</label>
                                            <input type="date" class="form-control form-control-sm" :class="{'is-invalid': errors.reference_date}" placeholder="rReference Date" v-model="withdraw.reference_date" />
                                            <span v-if="errors.reference_date" class="invalid-feedback">{{ errors.reference_date[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="amount">Amount *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.amount}" placeholder="Amount" v-model="withdraw.amount" />
                                            <span v-if="errors.amount" class="invalid-feedback">{{ errors.amount[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="tds">TDS % *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.tds}" placeholder="TDS" v-model="withdraw.tds" />
                                            <span v-if="errors.tds" class="invalid-feedback">{{ errors.tds[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="tds_amount">TDS Amount *</label>
                                            <input readonly type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.tds_amount}" placeholder="TDS Amount" v-model="withdraw.tds_amount" />
                                            <span v-if="errors.tds_amount" class="invalid-feedback">{{ errors.tds_amount[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="total">Total *</label>
                                            <input readonly type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.total}" placeholder="Total" v-model="withdraw.total" />
                                            <span v-if="errors.total" class="invalid-feedback">{{ errors.total[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="note">Note</label>
                                            <textarea class="form-control form-control-sm" :class="{'is-invalid': errors.note}" placeholder="Note" v-model="withdraw.note"></textarea>
                                            <span v-if="errors.note" class="invalid-feedback">{{ errors.note[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link to="/tips" class="btn btn-sm btn-outline-danger" type="button">
                                        <i class="fas fa-ban"></i> Discard 
                                    </router-link>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <i class="fas fa-save"></i> Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-sm-8">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Withdraw</h3>
                            </div>
                            <div class="card-body">
                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th class="text-center">Ref No</th>
                                            <th class="text-center">Ref Date</th>
                                            <th class="text-center">Amount</th>
                                            <th class="text-center">TDS</th>
                                            <th class="text-center">Total</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="tips_withdraw,key in tips_withdraws" :key="key">
                                            <td class="text-center">{{ meta.from+key }}</td>
                                            <td class="text-center">{{ tips_withdraw.reference_no }}</td>
                                            <td class="text-center">{{ $formatDate(tips_withdraw.reference_date, 'short') }}</td>
                                            <td class="text-center">{{ tips_withdraw.amount }}</td>
                                            <td class="text-center">{{ tips_withdraw.tds }}%</td>
                                            <td class="text-center">{{ tips_withdraw.total }}</td>
                                            <td class="text-center">
                                                <button class="btn btn-xs btn-outline-danger" @click="deleteTipsWithdraw(tips_withdraw)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    import moment from 'moment'
    export default {
        name: "Tips.Withdraw",
        components: { Pagination },
        data() {
            return {
                user:{},
                meta: {
                    search: "",
                    order_by: "desc",
                    keyword: "tips_withdraw_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    employee_id:'',
                },
                withdraw:{
                    reference_date: moment().format('YYYY-MM-DD'),
                    employee_id:'',
                    balance:'',
                    amount:'',
                    tds:'10',
                    tds_amount:'',
                    total:'',
                    note:''
                },
                tips_withdraws:[],
                errors: [],
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.withdraw.balance = to.params.balance;
                vm.$store.dispatch("post", { uri: "showUser", data: { user_id: to.params.user_id } })
                .then(function (response) {
                    vm.user = response.data.data;
                    vm.withdraw.employee_id = vm.user.user_id;
                    vm.meta.employee_id = vm.user.user_id;
                    vm.index();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            });
        },

        watch: {
            'withdraw.amount':function() {
                this.calculateTotalAmount()
            },
            'withdraw.tds':function() {
                this.calculateTotalAmount()
            }
        },


        methods: {
            index() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "paginateTipsWithdraw", data: vm.meta })
                .then(function (response) {
                    vm.tips_withdraws = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            calculateTotalAmount() {
                let vm = this;
                vm.withdraw.tds_amount = ((vm.withdraw.amount * vm.withdraw.tds)/100).toFixed(0);
                vm.withdraw.total = +vm.withdraw.amount - +vm.withdraw.tds_amount;
            },

            addTipsWithDraw() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "addTipsWithdraw", data: vm.withdraw })
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Tips withdraw is successfully created");
                    vm.$router.push("/tips");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    console.log(vm.errors);
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            deleteTipsWithdraw(tips_withdraw) {
                if (confirm("Do you want to continue... ?")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    vm.$store.dispatch("post", { uri: "deleteTipsWithdraw", data: tips_withdraw })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Tips withdraw is successfully deleted");
                        vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
        },
    };
</script>
