<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Database</li>
                <li class="breadcrumb-right">
                    <button class="btn btn-primary mr-2" v-can="'databases.create'" data-placement="bottom" title="Edit" data-toggle="modal" data-target="#customer" @click="formCreate()"><i class="fas fa-plus"></i> New Customer</button>
                    <button class="btn btn-success" v-can="'databases.upload'" data-toggle="modal" data-target="#upload"><i class="fas fa-upload"></i> Upload Data</button>
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Database</h3>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <input class="form-control form-control-sm" type="text" placeholder="Search" v-model="meta.search" @keyup="search" v-if="!meta.trashed" />
                                </div>
                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th @click="sort('name')">Name
                                                <span>
                                                    <i v-if="meta.keyword=='name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th>Group</th>
                                            <th class="text-center" v-if="get_activity.length">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(database,key) in databases" :key="key">
                                            <td class="text-center">{{meta.from+key}}</td>
                                            <td>{{database.name}}</td>
                                            <td>
                                                <span class="badge badge-success mr-1" v-for="(database_group,key) in database.database_groups" :key="key">
                                                    {{ database_group?.group?.group }}
                                                </span>
                                            </td>
                                            <td class="text-center" v-if="get_activity.length">
                                                <button v-can="'databases.update'" class="btn btn-xs btn-outline-success" data-toggle="modal" data-target="#customer" @click="editDatabase(database)">
                                                    <i class="fas fa-edit"></i>
                                                </button>
                                                <button v-can="'databases.call'" class="btn btn-xs btn-outline-secondary" @click.prevent="callCustomer(database.mobile_no)">
                                                    <i class="fa fa-phone"></i>
                                                </button>
                                                <a v-can="'databases.message/mail'" class="btn btn-xs btn-outline-primary" :href="'mailto:'+database.email">
                                                    <i class="fa fa-envelope"></i>
                                                </a>
                                                <button v-can="'databases.delete'" class="btn btn-xs btn-outline-danger" @click="deleteDatabase(database)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr v-if="databases.length <= 0">
                                            <td colspan="6" class="text-center">No Records Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="modal fade" id="customer">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-if="status">New Customer</h5>
                        <h5 class="modal-title" v-else>Update Customer</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="customer_name">Name *</label>
                                    <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.name}" placeholder="Name" v-model="database.name" ref="name" />
                                    <span v-if="errors.name" class="invalid-feedback">{{ errors.name[0] }}</span>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="customer_name">Mobile No *</label>
                                    <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.mobile_no}" placeholder="Mobile No" v-model="database.mobile_no" />
                                    <span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="customer_name">Email *</label>
                                    <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.email}" placeholder="Email" v-model="database.email" />
                                    <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                </div>
                            </div>
                            <div class="col-sm-4" v-for="(group,key) in groups" :key="key">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :id="group.group_id" :value="group.group_id" v-model="database_groups">
                                    <label class="form-check-label" :for="group.group_id">
                                        {{ group.group }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <span v-if="status">
                            <button type="button" class="btn btn-success" @click.prevent="createDatabase()"><i class="fas fa-save"></i> Submit</button>
                        </span>
                        <span v-else>
                            <button type="button" class="btn btn-success" @click.prevent="updateDatabase()"><i class="fas fa-save"></i> Update</button>
                        </span>
                        <button type="button" class="btn btn-danger" data-dismiss="modal" ref="Close"><i class="fas fa-times"></i> Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="upload">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Upload</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="file">Excel File *</label>
                                    <input class="form-control" :class="{'is-invalid': errors.file}" type="file" id="file" ref="file" name="file" />
                                    <span v-if="errors.file" class="invalid-feedback">{{ errors.file[0] }}</span>
                                </div>
                                <div class="text-right">
                                    <a :href="$store.getters.assetUrl+'/database.xlsx'" target="_blank">Download Database Template</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" @click="uploadDatabase()"><i class="fas fa-upload"></i> Upload</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal" ref="closeUpload"><i class="fas fa-times"></i> Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Database",
        components: { Pagination },
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "database_id",
                    per_page: 50,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },
                database: {
                    database_id: "",
                    name: "",
                    mobile_no: "",
                    email: "",
                    group_id: "",
                    groups: [],
                },
                database_groups:[],
                groups:[],
                databases: [],
                errors: [],
                get_activity:[],

            };
        },

    mounted() {
             this.get_activity = this.$store.getters.permissions.filter(function (element) {
                return element.ability.ability.includes("databases.message/mail") || element.ability.ability.includes("databases.delete") || element.ability.ability.includes("databases.call") || element.ability.ability.includes("databases.update") ;
            });
            let vm = this;
            vm.index();
        },

        methods: {
            formCreate() {
                let vm = this;
                vm.status = true;
                vm.discardDatabaseFields();
            },

            index() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "paginateDatabase", data: vm.meta })
                .then(function (response) {
                    vm.databases = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    vm.getGroups();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getGroups() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getGroups" })
                .then(function (response) {
                    vm.groups = response.data.data;
                    console.log(vm.groups)
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            uploadDatabase() {
                let vm = this;
                let loader = vm.$loading.show();
                const formData = new FormData();
                formData.append("file", vm.$refs.file.files[0]);
                vm.$store.dispatch("upload", { uri: "uploadDatabase", data: formData })
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "File uploaded successfully");
                    vm.$refs.closeUpload.click();
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            deleteDatabase(database) {
                if (confirm("Do you want to continue... ?")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteDatabase", data: database };
                    vm.$store.dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Data is successfully deleted");
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },
            createDatabase() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.database.groups = vm.database_groups;
                let uri = { uri: "createDatabase", data: vm.database };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Data is successfully created");
                    vm.$refs.Close.click();
                    vm.errors = [];
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            editDatabase(database) {
                let vm = this;
                vm.status = false;
                vm.database.groups = [];
                vm.database_groups = [];
                vm.database = database;
                vm.database.database_groups.map(function(element){
                    vm.database_groups.push(element.group_id)
                })
                vm.errors = [];
            },

            updateDatabase() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.database.groups = vm.database_groups;
                let uri = { uri: "updateDatabase", data: vm.database };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Data is successfully updated");
                    vm.$refs.Close.click();
                    vm.errors = [];
                    vm.index();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            discard() {
                let vm = this;
                var file = document.getElementById("file");
                if (file.files.length > 0) {
                    file.value = null;
                }
                vm.errors = [];
                vm.index();
            },

            discardDatabaseFields() {
                let vm = this;
                vm.database.name = "";
                vm.database.email = "";
                vm.database.mobile_no = "";
                vm.database_groups = [];
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },

            search(){
               let vm = this;
               vm.meta.page = 1;
               vm.index();
            },
            callCustomer(to) {
                let vm = this;
                let loader = vm.$loading.show();
                let data = {
                    From: '0'+vm.$store.getters.user.mobile_no,
                    To:'0'+to
                }
                let uri = { uri: "customerCallConnect", data: data };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Call connected, please wait for sometime");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },
        },
    };
</script>
