<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Notification</li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-4">
                        <form class="card" @submit.prevent="create()">
                            <div class="card-header">
                                <h3 class="card-title">New Notification User</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="subject">Notification *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.subject}" placeholder="Subject" v-model="notification.subject" ref="subject" />
                                            <span v-if="errors.subject" class="invalid-feedback">{{ errors.subject[0] }}</span>
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Users </label>
                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.user }" v-model="notification_user.user_id">
                                                <option value="">Select User</option>
                                                <option v-for="(user,key) in users" :key="key" :value="user.user_id">{{ user.user}}</option>
                                            </select>
                                            <span v-if="errors.user" class="invalid-feedback">{{ errors.user[0] }}</span>
                                        </div>
                                    </div>

                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="message">Groups *</label>
                                            <textarea type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.message}" placeholder="Message" v-model="notification.message"></textarea>
                                            <span v-if="errors.message" class="invalid-feedback">{{ errors.message[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button class="btn btn-sm btn-outline-danger" type="button" @click="discard()"><i class="fas fa-ban"></i> Discard</button>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                        <span v-else> <i class="fas fa-save"></i> Update </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-sm-8">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Notifications</h3>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <input class="form-control form-control-sm" type="text" placeholder="Search" v-model="meta.search" @keyup="index" />
                                </div>
                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th>Subject</th>
                                            <th>Message</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(notification,key) in notifications" :key="key">
                                            <td class="text-center">{{meta.from+key}}</td>
                                            <td>{{notification.subject}}</td>
                                            <td>{{notification.message}}</td>
                                        </tr>
                                        <tr v-if="notifications.length <= 0">
                                            <td colspan="3" style="text-align: center;">No Records Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Notification",
        components: { Pagination },
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "notification_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },
                notification: {
                    notification_id: "",
                    notification: "",
                    message: "",
                },
                notification_users: [],
                users: [],
                errors: [],
            };
        },

        mounted() {
            let vm = this;
            vm.index();
        },

        methods: {
            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateNotifications", data: vm.meta })
                    .then(function (response) {
                        vm.notifications = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "addNotification", data: vm.notification };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Notification is successfully created");
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            discard() {
                let vm = this;
                vm.notification.subject = "";
                vm.notification.message = "";
                vm.errors = [];
                vm.status = true;
                vm.index();
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },

            getUsers() {
                let vm = this;
                let uri = { uri: "getUsers" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.roles = response.data.data;
                        console.log(vm.roles);
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
        },
    };
</script>
