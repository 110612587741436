<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-cogs"></i>
                        Configuration
                    </a>
                </li>
                <li class="breadcrumb-item active">Organization</li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-4">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Update Logo</h3>
                            </div>
                            <div class="card-body">
                                <div class="text-center" v-if="organization.logo">
                                    <img width="200" :src="organization.logo" />
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="customFile">Choose file</label>
                                    <input type="file" class="form-control" id="customFile" :class="{'is-invalid': errors.logo}" @change="onImageChange($event)" accept="image/*" />
                                </div>
                                <span v-if="errors.logo" class="invalid-feedback">{{ errors.logo[0] }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <form class="card" @submit.prevent="submitForm()">
                            <div class="card-header">
                                <h3 class="card-title">Update Organization</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="org_name">Org Name *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.org_name}" placeholder="Org Name" v-model="organization.org_name" />
                                            <span v-if="errors.org_name" class="invalid-feedback">{{ errors.org_name[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="tax_name">Mobile Number *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.mobile_no}" placeholder="Mobile Number" v-model="organization.mobile_no" />
                                            <span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="tax_name">Email Address </label>
                                            <input type="email" multiple class="form-control form-control-sm" :class="{'is-invalid': errors.email}" placeholder="Email Address" v-model="organization.email" />
                                            <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="phone_no">Phone Number </label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.phone_no}" placeholder="Phone Number" v-model="organization.phone_no" />
                                            <span v-if="errors.phone_no" class="invalid-feedback">{{ errors.phone_no[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="tax_name">GST Number </label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.gstin_no}" placeholder="GSTIN" v-model="organization.gstin_no" />
                                            <span v-if="errors.gstin_no" class="invalid-feedback">{{ errors.gstin_no[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="tax_name">Pan Number </label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.pan_no}" placeholder="Pan Number" v-model="organization.pan_no" />
                                            <span v-if="errors.pan_no" class="invalid-feedback">{{ errors.pan_no[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="from_date">Fiscal Year (From Date) *</label>
                                            <input type="date" class="form-control form-control-sm" :class="{'is-invalid': errors.from_date}" v-model="organization.from_date" />
                                            <span v-if="errors.from_date" class="invalid-feedback">{{ errors.from_date[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="to_date">Fiscal Year (To Date) *</label>
                                            <input type="date" class="form-control form-control-sm" :class="{'is-invalid': errors.to_date}" v-model="organization.to_date" />
                                            <span v-if="errors.to_date" class="invalid-feedback">{{ errors.to_date[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="cash_limit">Cash Limit *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.cash_limit}" placeholder="Cash Limit" v-model="organization.cash_limit" />
                                            <span v-if="errors.cash_limit" class="invalid-feedback">{{ errors.cash_limit[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="tds">TDS *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.tds}" placeholder="TDS" v-model="organization.tds" />
                                            <span v-if="errors.tds" class="invalid-feedback">{{ errors.tds[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="tax_name">Address </label>
                                            <textarea class="form-control form-control-sm" :class="{'is-invalid': errors.address}" placeholder="Address" v-model="organization.address"></textarea>
                                            <span v-if="errors.address" class="invalid-feedback">{{ errors.address[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span> <i class="fas fa-save"></i> Update </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "Proj-Currency.Index",
        data() {
            return {
                organization: {
                    org_id: "",
                    org_name: "",
                    mobile_no: "",
                    email: "",
                    phone_no: "",
                    address: "",
                    gstin_no: "",
                    pan_no: "",
                    from_date:"",
                    to_date:"",
                    cash_limit:"",
                    tds:"",
                    logo: "",
                },
                errors: [],
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store
                .dispatch("post", { uri: "showOrganization" })
                .then(function (response) {
                    vm.organization = response.data.data;
                    vm.organization.logo = vm.$store.getters.assetUrl + "organizations/" + vm.organization.logo;
                    vm.$refs.name.focus();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            });
        },

        methods: {
            submitForm() {
                let vm = this;
                {
                    vm.update();
                }
            },

            update() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateOrganization", data: vm.organization };
                vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    console.log(response);
                    vm.$store.dispatch("success", "Organization is successfully updated");

                    vm.errors = [];
                    vm.showOrganisation();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            showOrganisation() {
                let vm = this;
                vm.$store
                .dispatch("post", { uri: "showOrganization" })
                .then(function (response) {
                    vm.$store.dispatch("setOrganization", response.data.data);
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            onImageChange(e) {
                e.preventDefault();
                let vm = this;
                let image = e.target.files[0];
                let reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = (e) => {
                    vm.organization.logo = e.target.result;
                };
            },
        },
    };
</script>
