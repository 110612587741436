<template>
     <form @submit.prevent="submitTransaction">
        <div class="card-body">
            <div class="row mt-2">
                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="customer_id">Customer * </label>
                                <Customer :class="{'is-invalid': errors.customer_id}" :customClass="{'is-invalid': errors.customer_id}" :initialize="transaction.customer_name" :checkIn="false" @selectCustomer="selectCustomer($event)" ref="customer_id"></Customer>
                                <span v-if="errors.customer_id" class="invalid-feedback">{{ errors.customer_id[0] }}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="credit_limit">Credit Limit *</label>
                                <input disabled type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.credit_limit}" placeholder="Credit Limit" v-model="transaction.credit_limit" />
                                <span v-if="errors.credit_limit" class="invalid-feedback">{{ errors.credit_limit[0] }}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="wallet_balance">Wallet Balance *</label>
                                <input disabled type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.wallet_balance}" placeholder="Wallet Balance" v-model="transaction.wallet_balance" />
                                <span v-if="errors.wallet_balance" class="invalid-feedback">{{ errors.wallet_balance[0] }}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="transaction_date_time">Transaction Date & Time *</label>
                                <input disabled type="datetime-local" class="form-control form-control-sm" :class="{'is-invalid': errors.transaction_date_time}" v-model="transaction.transaction_date_time" />
                                <span v-if="errors.transaction_date_time" class="invalid-feedback">{{ errors.transaction_date_time[0] }}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="amount">Amount *</label>
                                <input type="number" class="form-control form-control-sm" :class="{'is-invalid': errors.amount}" placeholder="Amount" v-model="transaction.amount" ref="amount" @keyup="calculateTotalAmount" />
                                <span v-if="errors.amount" class="invalid-feedback">{{ errors.amount[0] }}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="promotion_id">Promotion</label>
                                <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.promotion_id }" v-model="transaction.promotion_id" @change="changePromotion">
                                    <option value="">Select Promotion</option>
                                    <option v-for="(promotion,key) in promotions" :key="key" :value="promotion.promotion_id">{{ promotion.promotion_name}}</option>
                                </select>
                                <span v-if="errors.promotion_id" class="invalid-feedback">{{ errors.promotion_id[0] }}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="total_amount">Total Amount *</label>
                                <input disabled type="number" class="form-control form-control-sm" :class="{'is-invalid': errors.total_amount}" placeholder="Total Amount" v-model="transaction.total_amount" />
                                <span v-if="errors.total_amount" class="invalid-feedback">{{ errors.total_amount[0] }}</span>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="note">Note</label>
                                <textarea class="form-control form-control-sm" :class="{'is-invalid': errors.note}" placeholder="Note" v-model="transaction.note"></textarea>
                                <span v-if="errors.note" class="invalid-feedback">{{ errors.reference_id[0] }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 mt-4">
                    <table class="table table-responsive-sm table-bordered table-striped table-sm">
                        <thead>
                            <tr>
                                <th class="text-center">Denomination</th>
                                <th class="text-center">Quantity</th>
                                <th class="text-center">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(denomination,key) in transaction.denominations" :key="key">
                                <td class="text-center">{{denomination.denomination_value}}</td>
                                <td>
                                    <input type="number" class="form-control form-control-sm" :class="{'is-invalid': errors.quantity}" placeholder="Quantity" v-model.number="denomination.quantity"  @keyup="checkDenomination(denomination)"/>
                                </td>
                                <td class="text-center">
                                    {{ denomination.total = (denomination.quantity * denomination.denomination_value).toFixed(2) }}
                                </td>
                            </tr>
                            <tr>
                                <th class="text-center" colspan="2">Grand Total</th>
                                <th class="text-center">{{ grandTotal }}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="card-footer" v-if="(transaction.amount!='') && (transaction.total_amount!=0) && ((transaction.total_amount * 1).toFixed(2) == grandTotal)">
            <div class="float-right">
                <button class="btn btn-sm btn-outline-success" type="submit">
                    <i class="fas fa-save"></i> Submit
                </button>
            </div>
        </div>
    </form>
</template>
<script>
    let Customer = require('@/components/Customer.vue').default;
    import moment from 'moment';
    export default {
        name: "Transaction.Create",
        components: { Customer },
        props: {
            session_log_id: {
                default: null
            }
        },
        data() {
            return {
                transaction: {
                    branch_id:this.$store.getters.user.branch_id,
                    user_id:this.$store.getters.user.user_id,
                    session_log_id:'',
                    customer_id:'',
                    customer_name:'',
                    credit_limit:0,
                    cash_limit:0,
                    wallet_balance:0,
                    transaction_date_time:'',
                    transaction_type:'BuyIn',
                    amount:'',
                    promotion_id:'',
                    promotion_value:0,
                    total_amount:'',
                    note:'',
                    denominations:[],
                },
                promotions:[],
                errors: [],
            };
        },

        computed: {
            grandTotal: function () {
                let vm = this;
                return vm.transaction.denominations.reduce((prev, cur) => +prev + +cur.total, 0);
            },
        },

        mounted() {
            let vm = this;
            vm.transaction.session_log_id = vm.session_log_id
            vm.$refs.customer_id.focus();
            setInterval(vm.setTransactionTime, 1000);
        },

        methods: {
            setTransactionTime() {
                let vm = this;
                vm.transaction.transaction_date_time = moment().format('YYYY-MM-DDTHH:mm');
            },

            getPromotions() {
                let vm = this;
                if (vm.$store.getters.promotions.length > 0) {
                    vm.promotions = vm.$store.getters.promotions
                }
                else {
                    let uri = { uri: "getPromotions", data: vm.transaction };
                    vm.$store.dispatch("post", uri)
                        .then(function (response) {
                            vm.promotions = response.data.data;
                            vm.$store.dispatch('setPromotions', vm.promotions);
                            vm.discard()
                        })
                        .catch(function (error) {
                            vm.errors = error.response.data;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },

            selectCustomer(e){
                let vm = this;
                const customer = e.target.value;
                vm.transaction.customer_id = customer.customer_id;
                vm.transaction.customer_name = customer.customer_name;
                vm.transaction.credit_limit = customer.credit_limit;
                vm.getCustomerWallet()
                vm.$refs.amount.focus();
            },

            getCustomerWallet() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "transactions/getCustomerWallet",data:vm.transaction })
                .then(function (response) {
                    vm.transaction.wallet_balance = response.data['opening_balance'];
                    vm.getDenominations()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getDenominations() {
                let vm = this;
                let uri = { uri: "getBranchDenominations", data: vm.transaction};
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.transaction.denominations = response.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            checkDenomination(denomination) {
                let vm = this;
                if (denomination.denomination_quantity < denomination.quantity) {
                    vm.$store.dispatch("error", 'Please enter valid quantity');
                    return denomination.quantity = 0;
                }
            },

            changePromotion() {
                let vm = this;
                let promotions = vm.promotions.filter((promotion) => {
                    return promotion.promotion_id == vm.transaction.promotion_id;
                });
                if(promotions.length!=0) {
                    vm.transaction.promotion_value = promotions[0].promotion_value;
                    vm.calculateTotalAmount()
                }
                else{
                    vm.transaction.promotion_value = 0;
                    vm.calculateTotalAmount()
                }
            },

            calculateTotalAmount() {
                let vm = this;
                vm.transaction.total_amount = +vm.transaction.promotion_value + +vm.transaction.amount;
            },

            submitTransaction() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "submitTransaction", data: vm.transaction };
                vm.$store.dispatch("post", uri).then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Transaction is successfully created");
                    vm.discard()
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            discard() {
                let vm = this;
                vm.transaction.customer_id = '';
                vm.transaction.customer_name = '';
                vm.transaction.credit_limit = 0;
                vm.transaction.cash_limit = 0;
                vm.transaction.wallet_balance = 0;
                vm.transaction.amount = '';
                vm.transaction.promotion_id = '';
                vm.transaction.promotion_value = 0;
                vm.transaction.total_amount = '';
                vm.transaction.note = '';
                vm.transaction.denominations = [];
                vm.errors = [];
                vm.$refs.customer_id.focus();
            }
        },
    };
</script>