<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-cogs"></i>
                        Configuration
                    </a>
                </li>
                <li class="breadcrumb-item active">Branch</li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-4">
                        <form class="card" @submit.prevent="submitForm()">
                            <div class="card-header">
                                <h3 class="card-title" v-if="status">New Branch</h3>
                                <h3 class="card-title" v-else>Update Branch</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="branch_name">Branch Name *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.branch_name}" placeholder="Branch Name" v-model="branch.branch_name" />
                                            <span v-if="errors.branch_name" class="invalid-feedback">{{ errors.branch_name[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="branch_code">Branch Code *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.branch_code}" placeholder="Branch Code" v-model="branch.branch_code" />
                                            <span v-if="errors.branch_code" class="invalid-feedback">{{ errors.branch_code[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="state_code">State Code *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.state_code}" placeholder="State Code" v-model="branch.state_code" />
                                            <span v-if="errors.state_code" class="invalid-feedback">{{ errors.state_code[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="pincode">Pincode *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.pincode}" placeholder="Pincode" v-model="branch.pincode" />
                                            <span v-if="errors.pincode" class="invalid-feedback">{{ errors.pincode[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="description">Description</label>
                                            <textarea type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.description}" placeholder="Description" v-model="branch.description"></textarea>
                                            <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button class="btn btn-sm btn-outline-danger" type="button" @click="discard()"><i class="fas fa-ban"></i> Discard</button>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                        <span v-else> <i class="fas fa-save"></i> Update </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-sm-8">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Branches</h3>
                                <div class="card-tools">
                                    <div class="custom-control custom-switch custom-switch-off-success custom-switch-on-danger">
                                        <input type="checkbox" class="custom-control-input" id="trashed" v-model="meta.trashed" @click="isTrashed" />
                                        <label class="custom-control-label" for="trashed">Trashed</label>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <input class="form-control form-control-sm" type="text" placeholder="Search" v-model="meta.search" @keyup="search" v-if="!meta.trashed"/>
                                </div>
                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th @click="sort('branch_name')">Branch
                                                <span>
                                                    <i v-if="meta.keyword=='branch_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='branch_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('branch_code')">Branch Code
                                                <span>
                                                    <i v-if="meta.keyword=='branch_code' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='branch_code' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('state_code')">State Code
                                                <span>
                                                    <i v-if="meta.keyword=='state_code' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='state_code' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('pincode')">Pincode
                                                <span>
                                                    <i v-if="meta.keyword=='pincode' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='pincode' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(branch,key) in branches" :key="key">
                                            <td class="text-center">{{meta.from+key}}</td>
                                            <td>{{branch.branch_name}}</td>
                                            <td>{{branch.branch_code}}</td>
                                            <td>{{branch.state_code}}</td>
                                            <td>{{branch.pincode}}</td>
                                            <td class="text-center" v-if="!meta.trashed">
                                                <button class="btn btn-xs btn-outline-success" data-toggle="tooltip" data-placement="bottom" title="Edit" @click="edit(branch)">
                                                    <i class="fas fa-pencil-alt"></i>
                                                </button>
                                                <button class="btn btn-xs btn-outline-danger" data-toggle="tooltip" data-placement="bottom" title="Delete" @click="deleteBranch(branch)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                            <td class="text-center" v-else>
                                                <button class="btn btn-xs btn-outline-success" data-toggle="tooltip" data-placement="bottom" title="Restore" @click="deleteBranch(branch)">
                                                    <i class="fas fa-trash-restore"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr v-if="branches.length <= 0">
                                            <td colspan="20" style="text-align: center;">No Records Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Branch",
        components: { Pagination },
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "branch_name",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },
                branch: {
                    branch_id: "",
                    branch_name: "",
                    branch_code: "",
                    state_code:"",
                    pincode:"",
                    description: "",
                },
                branches: [],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.index();
        },

        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },


            search(){
               let vm = this;
               vm.meta.page = 1;
               vm.index();

            },

            edit(branch) {
                let vm = this;
                (vm.status = false), (vm.branch = branch), vm.$refs.branch.focus();
            },

            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateBranches", data: vm.meta })
                    .then(function (response) {
                        vm.branches = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            isTrashed() {
                let vm = this;
                vm.meta.trashed = !vm.meta.trashed;
                if(vm.meta.trashed)
                    vm.meta.page = 1
                vm.index();
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "addBranch", data: vm.branch };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Branch is successfully created");
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            discard() {
                let vm = this;
                vm.branch.branch_name = "";
                vm.branch.branch_code = "";
                vm.branch.description = "";
                vm.errors = [];
                vm.status = true;
                vm.index();
            },

            update() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateBranch", data: vm.branch };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Branch is successfully updated");
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            deleteBranch(branch) {
                if (confirm("Do you want to continue... ?")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteBranch", data: branch };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "Branch is successfully deleted");
                            vm.discard();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
        },
    };
</script>
