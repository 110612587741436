<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Playtime Report</li>
                <li class="breadcrumb-right">
                    <a type="button" class="btn btn-sm btn-success" target="_blank"  @click="downloadExcel" :href="$store.getters.apiUrl+'emptyExcel'">
                        <i class="fa fa-download mr-1"></i>Download
                    </a>
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Playtime Report</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="from_date">From Date</label>
                                            <input type="date" class="form-control form-control-sm" v-model="meta.from_date">
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="from_date">To Date</label>
                                            <input type="date" class="form-control form-control-sm" v-model="meta.to_date">
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group" style="margin-top: 26px !important;">
                                            <button class="btn btn-sm btn-primary mr-2" @click="index"><i class="fa fa-search mr-1"></i>Search</button>
                                            <button class="btn btn-sm btn-danger" @click="discard"><i class="fa fa-times mr-1"></i>Discard</button>
                                        </div>
                                    </div>
                                </div>

                                <div class="table-responsive">
                                    <table class="table table-bordered table-striped table-sm">
                                        <thead>
                                            <tr>
                                                <th class="text-center">#</th>
                                                <th @click="sort('customer_no')">
                                                    Customer No
                                                    <span>
                                                        <i v-if="meta.keyword=='customer_no' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='customer_no' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th @click="sort('customer_name')">
                                                    Customer Name
                                                    <span>
                                                        <i v-if="meta.keyword=='customer_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='customer_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th class="text-center" @click="sort('total_log_hours')">
                                                    Total Log Hours
                                                    <span>
                                                        <i v-if="meta.keyword=='total_log_hours' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='total_log_hours' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                                <th class="text-center" @click="sort('total_play_hours')">
                                                    Total Play Hours
                                                    <span>
                                                        <i v-if="meta.keyword=='total_play_hours' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='total_play_hours' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(customer,key) in customers" :key="key">
                                                <td class="text-center">{{meta.from+key}}</td>
                                                <td>{{ customer.customer_no }}</td>
                                                <td>{{ customer.customer_name }}</td>
                                                <td class="text-center">{{ convertSecondsToHoursAndMinutes(customer.total_log_hours) }}</td>
                                                <td class="text-center">{{ convertSecondsToHoursAndMinutes(customer.total_play_hours) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import moment from "moment";
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Report.PlayTimeReport",
        components: { Pagination },
        data() {
            return {
                status: true,
                meta: {
                    branch_id:this.$store.getters.user.branch_id,
                    from_date:"",
                    to_date:"",
                    order_by: "asc",
                    keyword: "customer_no",
                    per_page:50,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
                customers:[],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.discard()
        },

        methods: {
            index() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "customerPlayTimeReport", data: vm.meta })
                .then(function (response) {
                    vm.customers = response.data.data;
                    vm.meta.totalRows = response.data.total;
                    vm.meta.lastPage = response.data.last_page;
                    vm.meta.from = response.data.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    console.log(error);
                    // vm.errors = error.response.data.errors;
                    // vm.$store.dispatch("error", error.response.data.message);
                });
            },
            discard() {
                let vm = this;
                vm.meta.from_date = moment().format('YYYY-MM-DD');
                vm.meta.to_date = moment().format('YYYY-MM-DD');
                vm.index()
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            convertSecondsToHoursAndMinutes(seconds) {
                const duration = moment.duration(seconds, 'seconds');
                const hours = Math.floor(duration.asHours());
                const minutes = duration.minutes();
                return `${hours} hr ${minutes} min`;
            }
        },

        computed: {
            totalActiveTables() {
                return this.session_logs.reduce((total, session_log) => {
                    return total + session_log.active_tables;
                }, 0);
            },
        },
    };
</script>
