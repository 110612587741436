<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Tips Report</li>
                <li class="breadcrumb-right">
                    <button type="button" @click="reset" class="btn btn-sm btn-danger mr-1">
                        <i class="fa fa-undo"></i>
                        Reset
                    </button>
                    <a type="button" class="btn btn-sm btn-success" target="_blank"  @click="downloadExcel" :href="$store.getters.apiUrl+'emptyExcel'">
                        <i class="fa fa-download mr-1"></i>Download
                    </a>
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Tips Report</h3>
                                <!-- <div class="card-tools">
                                    <div class="form-group">
                                        <select class="form-control form-control-sm" v-model="meta.option" @change="index">
                                            <option>Year</option>
                                            <option>Month</option>
                                            <option>Daily</option>
                                        </select>
                                    </div>
                                </div> -->
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="from_date">Range</label>
                                            <select class="form-control form-control-sm" v-model="meta.range" @change="changeRage">
                                                <option value="">Select Range</option>
                                                <option>Finiancial Year (22-23)</option>
                                                <option>Finiancial Year (23-24)</option>
                                                <option>Finiancial Year (24-25)</option>
                                                <option>Year</option>
                                                <option>Month</option>
                                                <option>Daily</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="from_date">From Date</label>
                                            <input class="form-control form-control-sm" type="date" v-model="meta.from_date" @change="index" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="from_date">To Date</label>
                                            <input class="form-control form-control-sm" type="date" v-model="meta.to_date" @change="index" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="user_id">Employee</label>
                                            <select class="form-control form-control-sm" v-model="meta.user_id" @change="index">
                                                <option value="">Select Employee</option>
                                                <option v-for="(user,key) in users" :key="key" :value="user.user_id">
                                                    {{ user.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th rowspan="2" class="text-center">#</th>
                                                    <th rowspan="2" class="text-center">Employee ID</th>
                                                    <th rowspan="2" class="text-center">Employee Name</th>
                                                    <th rowspan="2" class="text-center">Status</th>
                                                    <th colspan="2" class="text-center">Tips</th>
                                                    <th colspan="2" class="text-center">Withdraw</th>
                                                    <th colspan="2" class="text-center">TDS</th>
                                                    <th rowspan="2" class="text-center">Balance</th>
                                                    <th rowspan="2" class="text-center" v-can="'report.tips.withdraw'">Action</th>
                                                </tr>
                                                <tr>

                                                    <th class="text-center">Opening</th>
                                                    <th class="text-center">Current</th>
                                                    <th class="text-center">Opening</th>
                                                    <th class="text-center">Current</th>
                                                    <th class="text-center">Opening</th>
                                                    <th class="text-center">Current</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(user,key) in sessions" :key="key">
                                                    <td class="text-center">{{meta.from+key}}</td>
                                                    <td class="text-center">{{ user.user_id }}</td>
                                                    <td class="text-center">{{ user.name }}</td>
                                                    <td class="text-center">
                                                        <span v-if="user.deleted_at" class="badge badge-danger">Inactive</span>
                                                        <span v-else class="badge badge-success">Active</span>
                                                    </td>
                                                    <td class="text-center">{{ user.opening_tips }}</td>
                                                    <td class="text-center">{{ user.tips }}</td>
                                                    <td class="text-center">{{ user.opening_withdraw }}</td>
                                                    <td class="text-center">{{ user.withdraw }}</td>
                                                    <td class="text-center">{{ user.opening_tds }}</td>
                                                    <td class="text-center">{{ user.tds }}</td>
                                                    <td class="text-center">{{ user.balance }}</td>
                                                    <td class="text-center">
                                                        <!-- <button class="btn btn-xs btn-outline-primary" title="View">
                                                            <i class="fas fa-eye"></i>
                                                        </button> -->
                                                        <router-link v-can="'report.tips.withdraw'" :to="'/tips/withdraw/'+user.user_id+'/'+user.balance" class="btn btn-xs btn-outline-success" title="Withdraw">
                                                            <i class="fas fa-credit-card"></i>
                                                        </router-link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import moment from 'moment'
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Transaction.Tips",
        components: { Pagination },
        data() {
            return {
                meta: {
                    branch_id:this.$store.getters.user.branch_id,
                    option:'Daily',
                    range:'Finiancial Year (24-25)',
                    from_date: '',
                    to_date: '',
                    user_id:'',
                    order_by: "asc",
                    keyword: "name",
                    per_page: 25,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
                day : moment().format('dddd'),
                sessions:[],
                users: [],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.getUsers();
            vm.changeRage();
        },
        methods: {
            index() {
                let vm = this;
                vm.$store
                .dispatch("post", { uri: "paginateTips", data: vm.meta })
                .then(function (response) {
                    vm.sessions = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getUsers() {
                let vm = this;
                if(vm.$store.getters.branch_users.length>0){
                    vm.users = vm.$store.getters.branch_users;
                }
                else{
                    let uri = { uri: "getBranchUsers", data: vm.meta  };
                    vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        vm.users = response.data.data;
                        vm.$store.dispatch('setBranchUsers', vm.users);
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

            changeRage() {
                if(this.meta.range=='Finiancial Year (22-23)') {
                    this.meta.from_date = moment('2022-04-01').format('YYYY-MM-DD');
                    this.meta.to_date = moment('2023-03-31').format('YYYY-MM-DD');
                }
                if(this.meta.range=='Finiancial Year (23-24)') {
                    this.meta.from_date = moment('2023-04-01').format('YYYY-MM-DD');
                    this.meta.to_date = moment('2024-03-31').format('YYYY-MM-DD');
                }
                if(this.meta.range=='Finiancial Year (24-25)') {
                    this.meta.from_date = moment('2024-04-01').format('YYYY-MM-DD');
                    this.meta.to_date = moment('2025-03-31').format('YYYY-MM-DD');
                }
                if(this.meta.range=='Year') {
                    this.meta.from_date = moment().startOf('year').format('YYYY-MM-DD');
                    this.meta.to_date = moment().format('YYYY-MM-DD');
                }
                if(this.meta.range=='Month') {
                    this.meta.from_date = moment().startOf('month').format('YYYY-MM-DD');
                    this.meta.to_date = moment().format('YYYY-MM-DD');
                }
                if(this.meta.range=='Daily') {
                    this.meta.from_date = moment().format('YYYY-MM-DD');
                    this.meta.to_date = moment().format('YYYY-MM-DD');
                }
                this.index();
            },

            reset() {
                let vm = this;
                vm.meta.from_date = "";
                vm.meta.to_date = "";
                vm.meta.user_id = "";
                vm.meta.option = "Daily";
                vm.meta.range = "Finiancial Year";
                vm.changeRage()
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            // downloadExcel() {
            //     let vm = this;
            //     let uri = { uri: "emptyExcel"};
            //     vm.$store.dispatch("post", uri)
            //     .then(function (response) {
            //         window.
            //     })
            //     .catch(function (error) {
            //         vm.errors = error.response.data;
            //         vm.$store.dispatch("error", error.response.data.message);
            //     });
            // }
        }
    };
</script>
