<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Sessions</li>
                <li class="breadcrumb-item active">Summary</li>
                <li class="breadcrumb-right">
                    <select class="form-control form-control-sm" v-model="report.session_log_id" @change="getSessionReport()">
                        <option v-for="(session_log,key) in session_logs" :key="key" :value="session_log.session_log_id">
                            {{ session_log.start }} - {{ session_log.end }}
                        </option>
                    </select>
                </li>
                <li class="breadcrumb-right">
                    <a target="_blank" :href="$store.getters.pdfUrl+'turn_arround_time?session_log_id='+report.session_log_id" class="btn btn-sm btn-success">
                        <i class="fa fa-download"></i> Turnaround Time
                    </a>
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card card-dark card-tabs">
                            <div class="card-header p-0 pt-1">
                                <ul class="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
                                    <li class="pt-2 px-3"><h3 class="card-title">Session</h3></li>
                                    <li class="nav-item">
                                        <a class="nav-link active" id="page1-tab" data-toggle="pill" href="#page1" role="tab" aria-controls="page1" aria-selected="true">Summary</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="page2-tab" data-toggle="pill" href="#page2" role="tab" aria-controls="page2" aria-selected="false">BuyIn</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="page3-tab" data-toggle="pill" href="#page3" role="tab" aria-controls="page3" aria-selected="false">CashOut</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="page4-tab" data-toggle="pill" href="#page4" role="tab" aria-controls="page4" aria-selected="false">Deposit</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="page5-tab" data-toggle="pill" href="#page5" role="tab" aria-controls="page5" aria-selected="false">Withdraw</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="page6-tab" data-toggle="pill" href="#page6" role="tab" aria-controls="page6" aria-selected="true">Experience Fees</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="page7-tab" data-toggle="pill" href="#page7" role="tab" aria-controls="page7" aria-selected="true">Tips</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="page8-tab" data-toggle="pill" href="#page8" role="tab" aria-controls="page8" aria-selected="true">Exchange Chips</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="page9-tab" data-toggle="pill" href="#page9" role="tab" aria-controls="page9" aria-selected="true">Float Check</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="page10-tab" data-toggle="pill" href="#page10" role="tab" aria-controls="page10" aria-selected="true">Logs</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="card-body">
                                <div class="tab-content" id="custom-tabs-two-tabContent">
                                    <div class="tab-pane fade active show" id="page1" role="tabpanel" aria-labelledby="page1-tab">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="card card-widget widget-user">
                                                    <div class="widget-user-header bg-secondary">
                                                        <h3 class="widget-user-username">
                                                            {{ session_log?.user?.name }}
                                                        </h3>
                                                        <h5 class="widget-user-desc">
                                                            {{ session_log?.branch?.branch_name }}
                                                        </h5>
                                                    </div>
                                                    <div class="widget-user-image">
                                                        <img class="img-circle elevation-2" src="@/assets/logo.png" alt="Logo" />
                                                    </div>
                                                    <div class="card-body p-0 mt-5">
                                                        <ul class="nav flex-column">
                                                            <li class="nav-item">
                                                                <div class="nav-link"> 
                                                                    Start Time
                                                                    <span class="float-right">
                                                                        {{ session_log?.start }}
                                                                    </span> 
                                                                </div>
                                                            </li>
                                                            <li class="nav-item">
                                                                <div class="nav-link"> 
                                                                    End Time
                                                                    <span class="float-right">
                                                                        {{ session_log?.end }}
                                                                    </span> 
                                                                </div>
                                                            </li>
                                                            <li class="nav-item">
                                                                <div class="nav-link"> 
                                                                    Total Time
                                                                    <span class="float-right">
                                                                        {{ totalTime(session_log) }}
                                                                    </span> 
                                                                </div>
                                                            </li>
                                                            <li class="nav-item">
                                                                <div class="nav-link"> 
                                                                    Deposit
                                                                    <span class="float-right">
                                                                        {{ deposit }}
                                                                    </span> 
                                                                </div>
                                                            </li>
                                                            <li class="nav-item">
                                                                <div class="nav-link"> 
                                                                    Withdraw
                                                                    <span class="float-right">
                                                                        {{ withdraw }}
                                                                    </span> 
                                                                </div>
                                                            </li>
                                                            <li class="nav-item">
                                                                <div class="nav-link"> 
                                                                    BuyIn
                                                                    <span class="float-right">
                                                                        {{ buy_in }}
                                                                    </span> 
                                                                </div>
                                                            </li>
                                                            <li class="nav-item">
                                                                <div class="nav-link"> 
                                                                    CashOut
                                                                    <span class="float-right">
                                                                        {{ cash_out }}
                                                                    </span> 
                                                                </div>
                                                            </li>
                                                            <li class="nav-item">
                                                                <div class="nav-link"> 
                                                                    Experience Fees
                                                                    <span class="float-right">
                                                                        {{ experience_fee }}
                                                                    </span> 
                                                                </div>
                                                            </li>
                                                            <li class="nav-item">
                                                                <div class="nav-link"> 
                                                                    Tips
                                                                    <span class="float-right">
                                                                        {{ tip }}
                                                                    </span> 
                                                                </div>
                                                            </li>
                                                            <li class="nav-item">
                                                                <div class="nav-link"> 
                                                                    <strong>Float Cash</strong>
                                                                    <span class="float-right">
                                                                        <strong>{{ difference }}</strong>
                                                                    </span> 
                                                                </div>
                                                            </li>
                                                            <li class="nav-item">
                                                                <div class="nav-link" style="color: green;"> 
                                                                    <strong>Footfall</strong>
                                                                    <span class="float-right">
                                                                        <strong>{{ logs?.length }}</strong>
                                                                    </span> 
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h3 class="card-title">Denominations</h3>
                                                    </div>
                                                    <div class="card-body">
                                                        <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center">#</th>
                                                                    <th class="text-center">Chips</th>
                                                                    <th class="text-center">Open</th>
                                                                    <th class="text-center">Close</th>
                                                                    <th class="text-center">Difference</th>
                                                                    <th class="text-center">Value</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(denomination,key) in denominations" :key="key">
                                                                    <td class="text-center">{{ key+1 }}</td>
                                                                    <td class="text-center">
                                                                        {{ denomination.denomination_name }}
                                                                    </td>
                                                                    <td class="text-center">
                                                                        {{ denomination.open }}
                                                                    </td>
                                                                    <td class="text-center">
                                                                        {{ denomination.close }}
                                                                    </td>
                                                                    <td class="text-center">
                                                                        {{ denomination.difference }}
                                                                    </td>
                                                                    <td class="text-center">
                                                                        {{ denomination.amount }}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th class="text-center" colspan="2">
                                                                        Total
                                                                    </th>
                                                                    <th class="text-center">
                                                                        {{ openTotal }}
                                                                    </th>
                                                                    <th class="text-center">
                                                                        {{ closeTotal }}
                                                                    </th>
                                                                    <th class="text-center">
                                                                        {{ diffTotal }}
                                                                    </th>
                                                                    <th class="text-center">
                                                                        {{ amountTotal }}
                                                                    </th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h3 class="card-title">Activities</h3>
                                                    </div>
                                                    <div class="card-body">
                                                        <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center">#</th>
                                                                    <th class="text-center">User</th>
                                                                    <th class="text-center">Login Date Time</th>
                                                                    <th class="text-center">Logout Date Time</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(activity,key) in activities" :key="key">
                                                                    <td class="text-center">{{ key+1 }}</td>
                                                                    <td class="text-center">{{activity?.user.name}}</td>
                                                                    <td class="text-center">{{activity.login_date_time}}</td>
                                                                    <td class="text-center">{{activity.logout_date_time}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="page2" role="tabpanel" aria-labelledby="page2-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h3 class="card-title">BuyIn</h3>
                                            </div>
                                            <div class="card-body">
                                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">#</th>
                                                            <th class="text-center">Transaction No</th>
                                                            <th class="text-center">Date Time</th>
                                                            <th class="text-center">Customer</th>
                                                            <th class="text-center">Amount</th>
                                                            <th class="text-center">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr  v-for="(transaction,key) in buy_ins" :key="key">
                                                            <td class="text-center">
                                                                {{ key+1 }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction.transaction_no }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction.transaction_date_time }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction?.customer.customer_name }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction.amount }}
                                                            </td>
                                                            <td class="text-center">
                                                                <button type="button" data-toggle="modal" data-target="#transaction" @click="viewTransaction(transaction)" class="btn btn-xs btn-outline-info">
                                                                    <i class="fas fa-eye"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th class="text-center" colspan="4">
                                                                Total
                                                            </th>
                                                            <th class="text-center">
                                                                {{ buy_in }}
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="page3" role="tabpanel" aria-labelledby="page3-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h3 class="card-title">CashOut</h3>
                                            </div>
                                            <div class="card-body">
                                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">#</th>
                                                            <th class="text-center">Transaction No</th>
                                                            <th class="text-center">Date Time</th>
                                                            <th class="text-center">Customer</th>
                                                            <th class="text-center">Amount</th>
                                                            <th class="text-center">Fee</th>
                                                            <th class="text-center">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr  v-for="(transaction,key) in cash_outs" :key="key">
                                                            <td class="text-center">
                                                                {{ key+1 }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction.transaction_no }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction.transaction_date_time }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction?.customer.customer_name }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction.amount }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction.fee }}
                                                            </td>
                                                            <td class="text-center">
                                                                <button type="button" data-toggle="modal" data-target="#transaction" @click="viewTransaction(transaction)" class="btn btn-xs btn-outline-info">
                                                                    <i class="fas fa-eye"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th class="text-center" colspan="4">
                                                                Total
                                                            </th>
                                                            <th class="text-center">
                                                                {{ cash_out }}
                                                            </th>
                                                            <th class="text-center">
                                                                {{ fee }}
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="page4" role="tabpanel" aria-labelledby="page4-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h3 class="card-title">Deposit</h3>
                                            </div>
                                            <div class="card-body">
                                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">#</th>
                                                            <th class="text-center">Transaction No</th>
                                                            <th class="text-center">Date Time</th>
                                                            <th class="text-center">Reference Id</th>
                                                            <th class="text-center">Payment Mode</th>
                                                            <th class="text-center">Customer</th>
                                                            <th class="text-center">Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr  v-for="(transaction,key) in deposits" :key="key">
                                                            <td class="text-center">
                                                                {{ key+1 }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction.transaction_no }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction.transaction_date_time }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction.reference_id }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction?.payment_mode?.payment_mode }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction?.customer.customer_name }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction.amount }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th class="text-center" colspan="6">
                                                                Total
                                                            </th>
                                                            <th class="text-center">
                                                                {{ deposit }}
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="page5" role="tabpanel" aria-labelledby="page5-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h3 class="card-title">Withdraw</h3>
                                            </div>
                                            <div class="card-body">
                                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">#</th>
                                                            <th class="text-center">Transaction No</th>
                                                            <th class="text-center">Date Time</th>
                                                            <th class="text-center">Customer</th>
                                                            <th class="text-center">Amount</th>
                                                            <th class="text-center">TDS</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr  v-for="(transaction,key) in withdraws" :key="key">
                                                            <td class="text-center">
                                                                {{ key+1 }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction.transaction_no }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction.transaction_date_time }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction?.customer.customer_name }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction.amount }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ transaction.tds }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th class="text-center" colspan="4">
                                                                Total
                                                            </th>
                                                            <th class="text-center">
                                                                {{ withdraw }}
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="page6" role="tabpanel" aria-labelledby="page6-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h3 class="card-title">Experience Fees</h3>
                                            </div>
                                            <div class="card-body">
                                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">#</th>
                                                            <th class="text-center">Date Time</th>
                                                            <th class="text-center">Cashier</th>
                                                            <th class="text-center">Table</th>
                                                            <th class="text-center">Amount</th>
                                                            <th class="text-center">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr  v-for="(session,key) in experience_fees" :key="key">
                                                            <td class="text-center">
                                                                {{ key+1 }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ session.session_date }} {{ session.session_time }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ session?.user.name }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ session?.table.table }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ session.amount ==0 ? sumOfSession(session.session_denominations) : session.amount }} 
                                                            </td>
                                                            <td class="text-center">
                                                                <button type="button" data-toggle="modal" data-target="#session" @click="viewSession(session)" class="btn btn-xs btn-outline-info">
                                                                    <i class="fas fa-eye"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <!-- <tr>
                                                            <th class="text-center" colspan="4">
                                                                Total
                                                            </th>
                                                            <th class="text-center">
                                                                {{ experience_fee }}
                                                            </th>
                                                            <th></th>
                                                        </tr> -->
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="page7" role="tabpanel" aria-labelledby="page7-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h3 class="card-title">Tips</h3>
                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <label for="user_id">Cashier *</label>
                                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.user_id }" v-model="meta.user_id" @change="filterTips">
                                                                <option value="">Select Cashier</option>
                                                                <option v-for="(employee,key) in employees" :key="key" :value="employee.user_id">{{ employee.name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <label for="dealer_id">Employee *</label>
                                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.dealer_id }" v-model="meta.dealer_id" @change="filterTips">
                                                                <option value="">Select Employee</option>
                                                                <option v-for="(employee,key) in employees" :key="key" :value="employee.user_id">{{ employee.name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">#</th>
                                                            <th class="text-center">Date Time</th>
                                                            <th class="text-center">Cashier</th>
                                                            <th class="text-center">Employee</th>
                                                            <th class="text-center">Amount</th>
                                                            <th class="text-center">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr  v-for="(session,key) in tips" :key="key">
                                                            <td class="text-center">
                                                                {{ key+1 }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ session.session_date }} {{ session.session_time }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ session?.user?.name }}
                                                            </td>
                                                            <td v-if="session.dealer_id" class="text-center">
                                                                {{ session?.dealer?.name }}
                                                            </td>
                                                            <td v-if="session.employee_id" class="text-center">
                                                                {{ session?.employee?.name }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ session.amount = session.amount == 0 ? sumOfSession(session.session_denominations) : session.amount }}
                                                            </td>
                                                            <td class="text-center">
                                                                <button type="button" data-toggle="modal" data-target="#session" @click="viewSession(session)" class="btn btn-xs btn-outline-info">
                                                                    <i class="fas fa-eye"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th class="text-center" colspan="4">
                                                                Total
                                                            </th>
                                                            <th class="text-center">
                                                                {{ tipTotal }}
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="page8" role="tabpanel" aria-labelledby="page8-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h3 class="card-title">Exchange Chips</h3>
                                            </div>
                                            <div class="card-body">
                                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">#</th>
                                                            <th class="text-center">Date Time</th>
                                                            <th class="text-center">Cashier</th>
                                                            <th class="text-center">Amount</th>
                                                            <th class="text-center">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr  v-for="(session,key) in exchange_chips" :key="key">
                                                            <td class="text-center">
                                                                {{ key+1 }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ session.session_date }} {{ session.session_time }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ session?.user.name }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ sumOfSession(session.session_denominations) }}
                                                            </td>
                                                            <td class="text-center">
                                                                <button type="button" data-toggle="modal" data-target="#exchange" @click="viewSession(session)" class="btn btn-xs btn-outline-info">
                                                                    <i class="fas fa-eye"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th class="text-center" colspan="3">
                                                                Total
                                                            </th>
                                                            <th class="text-center">
                                                                {{ exchange_chip }}
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="page9" role="tabpanel" aria-labelledby="page9-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h3 class="card-title">Float Check</h3>
                                            </div>
                                            <div class="card-body">
                                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">#</th>
                                                            <th class="text-center">Date Time</th>
                                                            <th class="text-center">Cashier</th>
                                                            <th class="text-center">Amount</th>
                                                            <th class="text-center">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr  v-for="(session,key) in floats" :key="key">
                                                            <td class="text-center">
                                                                {{ key+1 }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ session.session_date }} {{ session.session_time }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ session?.user.name }}
                                                            </td>
                                                            <td class="text-center">
                                                                {{ sumOfSession(session.session_denominations) }}
                                                            </td>
                                                            <td class="text-center">
                                                                <button type="button" data-toggle="modal" data-target="#floatCheck" @click="viewSession(session)" class="btn btn-xs btn-outline-info">
                                                                    <i class="fas fa-eye"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="page10" role="tabpanel" aria-labelledby="page10-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h3 class="card-title">Customer CheckIn Logs</h3>
                                            </div>
                                            <div class="card-body">
                                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">#</th>
                                                            <th>Customer No</th>
                                                            <th>Customer Name</th>
                                                            <th>Mobile No</th>
                                                            <th>Check-In</th>
                                                            <th>Check-Out</th>
                                                            <th>No of Hours</th>
                                                            <th>Security</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(log,key) in logs" :key="key">
                                                            <td class="text-center">{{key + 1}}</td>
                                                            <td>{{ log.customer?.customer_no }}</td>
                                                            <td>{{ log.customer?.customer_name }}</td>
                                                            <td>{{ $mask(log.customer?.mobile_no) }}</td>
                                                            <td>{{ $formatDate(log.start_date_time, 'india') }}</td>
                                                            <td>{{ $formatDate(log.end_date_time, 'india') }}</td>
                                                            <td>{{ calculateDifference(log.start_date_time,log.end_date_time) }}</td>
                                                            <td>{{ log.start_user?.name }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header">
                                                <h3 class="card-title">Employee CheckIn Logs</h3>
                                            </div>
                                            <div class="card-body">
                                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">#</th>
                                                            <th>Employee ID</th>
                                                            <th>Employee Name</th>
                                                            <th>Mobile No</th>
                                                            <th>Check-In</th>
                                                            <th>Check-Out</th>
                                                            <th>No of Hours</th>
                                                            <th>Security</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(log,key) in employee_logs" :key="key">
                                                            <td class="text-center">{{key+1}}</td>
                                                            <td>{{ log.user?.user_id }}</td>
                                                            <td>{{ log.user?.name }}</td>
                                                            <td>{{ $mask(log.user?.mobile_no) }}</td>
                                                            <td>{{ $formatDate(log.start_date_time, 'india') }}</td>
                                                            <td>{{ $formatDate(log.end_date_time, 'india') }}</td>
                                                            <td>{{ calculateDifference(log.start_date_time,log.end_date_time) }}</td>
                                                            <td>{{ log.start_user?.name }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="modal fade" id="transaction">
            <div class="modal-dialog">
                <div class="modal-content" v-if="transaction">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            {{ transaction.transaction_type }} : {{ transaction.transaction_no }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive text-nowrap">
                            <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th class="text-center">Chips</th>
                                        <th class="text-center">Quantity</th>
                                        <th class="text-center">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(transaction_denomination,key) in transaction.transaction_denominations" :key="key">
                                        <td class="text-center">
                                            {{ key+1 }}
                                        </td>
                                        <td class="text-center">
                                            {{ transaction_denomination?.denomination.denomination_name }}
                                        </td>
                                        <td class="text-center">
                                            {{ transaction_denomination.quantity }}
                                        </td>
                                        <td class="text-center">
                                            {{ transaction_denomination?.denomination.denomination_value * transaction_denomination.quantity }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="session">
            <div class="modal-dialog">
                <div class="modal-content" v-if="session">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            {{ session.session_type }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive text-nowrap">
                            <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th class="text-center">Chips</th>
                                        <th class="text-center">Quantity</th>
                                        <th class="text-center">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(session_denomination,key) in session.session_denominations" :key="key">
                                        <td class="text-center">
                                            {{ key+1 }}
                                        </td>
                                        <td class="text-center">
                                            {{ session_denomination?.denomination.denomination_name }}
                                        </td>
                                        <td class="text-center">
                                            {{ session_denomination.quantity }}
                                        </td>
                                        <td class="text-center">
                                            {{ session_denomination?.denomination.denomination_value * session_denomination.quantity }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="exchange">
            <div class="modal-dialog">
                <div class="modal-content" v-if="session">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            {{ session.session_type }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive text-nowrap">
                            <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th class="text-center">Chips</th>
                                        <th class="text-center">Inward</th>
                                        <th class="text-center">Chips</th>
                                        <th class="text-center">Outword</th>
                                        <th class="text-center">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(session_denomination,key) in session.session_denominations" :key="key">
                                        <td class="text-center">
                                            {{ key+1 }}
                                        </td>
                                        <td class="text-center">
                                            {{ session_denomination?.denomination?.denomination_name }}
                                        </td>
                                        <td class="text-center">
                                            {{ session_denomination.quantity }}
                                        </td>
                                        <td class="text-center">
                                            {{ session_denomination?.to_denomination?.denomination_name }}
                                        </td>
                                        <td class="text-center">
                                            {{ session_denomination.to_quantity }}
                                        </td>
                                        <td class="text-center">
                                            {{ session_denomination?.denomination.denomination_value * session_denomination.quantity }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="floatCheck">
            <div class="modal-dialog">
                <div class="modal-content" v-if="session">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            {{ session.session_type }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive text-nowrap">
                            <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th class="text-center">Denomination</th>
                                        <th class="text-center">System</th>
                                        <th class="text-center">Manual</th>
                                        <th class="text-center">Difference</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(session_denomination,key) in session.session_denominations" :key="key">
                                        <td class="text-center">
                                            {{ key+1 }}
                                        </td>
                                        <td class="text-center">
                                            {{ session_denomination?.denomination?.denomination_name }}
                                        </td>
                                        <td class="text-center">
                                            {{ session_denomination.quantity }}
                                        </td>
                                        <td class="text-center">
                                            {{ session_denomination.to_quantity }}
                                        </td>
                                        <td class="text-center">
                                            {{ session_denomination.quantity - session_denomination.to_quantity }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    export default {
        name: "Report",
        data() {
            return {
                meta:{
                    branch_id:this.$store.getters.user.branch_id,
                    dealer_id:'',
                    user_id:'',
                },
                report:{
                    branch_id:this.$store.getters.user.branch_id,
                    session_log_id:'',
                },
                transaction:{},
                session:{},
                session_log:{},
                denominations:[],
                activities:[],
                deposit:0,
                withdraw:0,
                buy_in:0,
                cash_out:0,
                fee:0,
                tip:0,
                experience_fee:0,
                exchange_chip:0,
                difference:0,
                buy_ins:[],
                cash_outs:[],
                deposits:[],
                withdraws:[],
                tips:[],
                filter_tips:[],
                experience_fees:[],
                exchange_chips:[],
                floats:[],
                errors:[],
                employees:[],
                logs:[],
                employee_logs:[],
            };
        },

        mounted() {
            let vm = this;
            vm.getSessionLogs();
        },

        computed: {
            openTotal: function () {
                return this.denominations.reduce((prev, cur) => +prev + +cur.open, 0);
            },
            closeTotal: function () {
                return this.denominations.reduce((prev, cur) => +prev + +cur.close, 0);
            },
            diffTotal: function () {
                return this.denominations.reduce((prev, cur) => +prev + +cur.difference, 0);
            },
            amountTotal: function () {
                return this.denominations.reduce((prev, cur) => +prev + +cur.amount, 0);
            },
            tipTotal: function () {
                return this.tips.reduce((prev, cur) => +prev + +cur.amount, 0);
            },
        },

        methods: {
            getSessionLogs() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getSessionLogs", data: vm.report })
                .then(function (response) {
                    vm.session_logs = response.data.data;
                    if(vm.session_logs.length!=0) {
                        vm.report.session_log_id = vm.session_logs[0].session_log_id
                        vm.getSessionReport()
                    }
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getSessionReport() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "getSessionReport", data: vm.report })
                .then(function (response) {
                    loader.hide();
                    vm.session_log = response.data['session_log'];
                    vm.denominations = response.data['denominations'];
                    vm.activities = response.data['activities'];
                    vm.deposit = response.data['deposit'];
                    vm.withdraw = response.data['withdraw'];
                    vm.buy_in = response.data['buy_in'];
                    vm.cash_out = response.data['cash_out'];
                    vm.fee = response.data['fee'];
                    vm.tip = response.data['tip'];
                    vm.experience_fee = response.data['experience_fee'];
                    vm.exchange_chip = response.data['exchange_chip'];
                    vm.difference = response.data['difference'];
                    vm.buy_ins = response.data['buy_ins'];
                    vm.cash_outs = response.data['cash_outs'];
                    vm.deposits = response.data['deposits'];
                    vm.withdraws = response.data['withdraws'];
                    vm.tips = response.data['tips'];
                    vm.filter_tips = response.data['tips'];
                    vm.experience_fees = response.data['experience_fees'];
                    vm.exchange_chips = response.data['exchange_chips'];
                    vm.floats = response.data['floats'];
                    vm.logs = response.data['logs'];
                    vm.employee_logs = response.data['employee_logs'];
                    vm.getEmployees();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getEmployees() {
                let vm = this;
                let uri = { uri: "getBranchUsers", data: vm.meta  };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.employees = response.data.data;
                })
                .catch(function (error) {
                    vm.loader.hide();
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            filterTips() {
                let vm = this;
                if(vm.meta.dealer_id!='' && vm.meta.user_id!='') {
                    vm.tips = vm.filter_tips.filter((tip) => {
                        return tip.dealer_id == vm.meta.dealer_id && tip.user_id == vm.meta.user_id;
                    });
                }
                else if(vm.meta.dealer_id!='') {
                    vm.tips = vm.filter_tips.filter((tip) => {
                        return tip.dealer_id == vm.meta.dealer_id;
                    });
                }
                else if(vm.meta.user_id!='') {
                    vm.tips = vm.filter_tips.filter((tip) => {
                        return tip.user_id == vm.meta.user_id;
                    });
                }
                else {
                    vm.tips = vm.filter_tips
                }
            },

            viewTransaction(transaction) {
                this.transaction = transaction;
            },
            viewSession(session) {
                this.session = session;
            },
            sumOfSession(session_denominations) {
                return session_denominations.reduce((prev, cur) => +prev + +cur.amount, 0);
            },
            calculateDifference(from_date, to_date) {
                if(from_date==null || to_date==null || from_date=='' || to_date=='') {
                    return null;
                }
                else {
                    const start = moment(from_date);
                    const end = moment(to_date);
                    const duration = moment.duration(end.diff(start));
                    return `${duration.hours()} hr ${duration.minutes()} min`;
                }
            },

            totalTime(session_log) {
                if(session_log.end) {
                    const start = moment(session_log.start);
                    const end = moment(session_log.end);
                    const duration = moment.duration(end.diff(start));
                    const hours = Math.floor(duration.asHours());
                    const minutes = duration.minutes();
                    return `${hours} hr ${minutes} min`;
                }
                else {
                    const start = moment(session_log.start);
                    const end = moment();
                    const duration = moment.duration(end.diff(start));
                    const hours = Math.floor(duration.asHours());
                    const minutes = duration.minutes();
                    return `${hours} hr ${minutes} min`;
                }
            }
        },
    };
</script>
