<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-mug-hot"></i>
                        Food & Beverages
                    </a>
                </li>
                <li class="breadcrumb-item active">Items</li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <form class="card" @submit.prevent="submitForm()">
                            <div class="card-header">
                                <h3 class="card-title" v-if="status">New Item</h3>
                                <h3 class="card-title" v-else>Update Item</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="item_code">Item Code *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.item_code}" placeholder="Item Code" v-model="item.item_code" ref="item_code" />
                                            <span v-if="errors.item_code" class="invalid-feedback">{{ errors.item_code[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="item_name">Item Name *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.item_name}" placeholder="Item Name" v-model="item.item_name" />
                                            <span v-if="errors.item_name" class="invalid-feedback">{{ errors.item_name[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="short_name">Short Name *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.short_name}" placeholder="Short Name" v-model="item.short_name" />
                                            <span v-if="errors.short_name" class="invalid-feedback">{{ errors.short_name[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="item_category">Item Catgeory *</label>
                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.item_category_id }" v-model="item.item_category_id">
                                                <option value="">Select Item Catgeory</option>
                                                <option v-for="(item_category,key) in item_categories" :key="key" :value="item_category.item_category_id">{{ item_category.item_category}}</option>
                                            </select>
                                            <span v-if="errors.item_category_id" class="invalid-feedback">{{ errors.item_category_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="tax">Tax *</label>
                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.tax_id }" v-model="item.tax_id">
                                                <option value="">Select Tax</option>
                                                <option v-for="(tax,key) in taxs" :key="key" :value="tax.tax_id">{{ tax.tax_name}}</option>
                                            </select>
                                            <span v-if="errors.tax_id" class="invalid-feedback">{{ errors.tax_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="kitchen">Kitchen *</label>
                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.kitchen_id }" v-model="item.kitchen_id">
                                                <option value="">Select Kitchen</option>
                                                <option v-for="(kitchen,key) in kitchens" :key="key" :value="kitchen.kitchen_id">{{ kitchen.kitchen}}</option>
                                            </select>
                                            <span v-if="errors.kitchen_id" class="invalid-feedback">{{ errors.kitchen_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="amount">Amount *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.amount}" placeholder="Amount" v-model="item.amount" />
                                            <span v-if="errors.amount" class="invalid-feedback">{{ errors.amount[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="unit">Unit *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.unit}" placeholder="Unit" v-model="item.unit" />
                                            <span v-if="errors.unit" class="invalid-feedback">{{ errors.unit[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="size">Size *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.size}" placeholder="Size" v-model="item.size" />
                                            <span v-if="errors.size" class="invalid-feedback">{{ errors.size[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="description">Description </label>
                                            <textarea type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.description}" placeholder="Description" v-model="item.description"></textarea>
                                            <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link to="/items" class="btn btn-sm btn-outline-danger" type="button"> <i class="fas fa-ban"></i> Discard </router-link>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                        <span v-else> <i class="fas fa-save"></i> Update </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "Items.Create",
        data() {
            return {
                status: true,
                item: {
                    branch_id:this.$store.getters.user.branch_id,
                    item_code: "",
                    item_name: "",
                    short_name: "",
                    description: "",
                    item_category_id: "",
                    amount: "",
                    unit: "",
                    size: "",
                    tax_id: "",
                    kitchen_id: "",
                },
                items: [],
                item_categories: [],
                taxs: [],
                kitchens: [],
                errors: [],
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.getItemCategories();
                if (to.name == "Items.Create") {
                    vm.$refs.item_code.focus();
                } 
                else {
                    vm.status = false;
                    vm.$store.dispatch("post", { uri: "getItem", data: { item_id: to.params.item_id } })
                    .then(function (response) {
                        vm.item = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            });
        },

        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "addItem", data: vm.item };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Item is successfully created");
                    vm.$router.push("/items");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getItemCategories() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getItemCategories",data:{branch_id:vm.$store.getters.user.branch_id} })
                .then(function (response) {
                    vm.item_categories = response.data.data;
                    vm.getTaxs();
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getTaxs() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getTaxes" })
                .then(function (response) {
                    vm.taxs = response.data.data;
                    vm.getKitchens();
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getKitchens() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getKitchens",data:{branch_id:vm.$store.getters.user.branch_id} })
                .then(function (response) {
                    vm.kitchens = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            update() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateItem", data: vm.item };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Item is successfully updated");
                    vm.$router.push("/items");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            }
        },
    };
</script>
