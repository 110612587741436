<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">Transaction</li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-4">
                        <div class="card card-widget widget-user">
                            <div class="widget-user-header bg-primary">
                                <h3 class="widget-user-username">{{ transaction?.customer?.customer_name }}</h3>
                            </div>
                            <div class="widget-user-image">
                                <img class="elevation-2" :src="transaction?.customer?.avatar" alt="Photo" />
                            </div>
                            <div class="card-body p-0" style="margin-top: 70px;">
                                <table class="table table-striped table-bordered table-sm">
                                    <tbody>
                                        <tr>
                                            <td>Transaction No</td>
                                            <td>{{transaction.transaction_no}}</td>
                                        </tr>
                                        <tr>
                                            <td>Transaction Date-Time</td>
                                            <td>{{transaction.transaction_date_time}}</td>
                                        </tr>
                                        <tr>
                                            <td>Transaction Type</td>
                                            <td>{{transaction.transaction_type}}</td>
                                        </tr>
                                        <tr>
                                            <td>Amount</td>
                                            <td>{{ transaction.amount }}</td>
                                        </tr>
                                        <tr>
                                            <td>Status</td>
                                            <td>{{ transaction.status }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <form class="card" @submit.prevent="updateWithdraw">
                            <div class="card-header">
                                <h3 class="card-title">{{transaction.transaction_no}}</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="payment_mode_id">Payment Mode *</label>
                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.payment_mode_id }" v-model="transaction.payment_mode_id">
                                                <option value="">Select Payment Mode</option>
                                                <option v-for="(payment_mode,key) in payment_modes" :key="key" :value="payment_mode.payment_mode_id">{{ payment_mode.payment_mode}}</option>
                                            </select>
                                            <span v-if="errors.payment_mode_id" class="invalid-feedback">{{ errors.payment_mode_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="reference_id">Reference ID</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.reference_id}" placeholder="Reference ID" v-model="transaction.reference_id" />
                                            <span v-if="errors.reference_id" class="invalid-feedback">{{ errors.reference_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="is_tds_paid">TDS Paid *</label>
                                            <select class="form-control form-control-sm" :class="{'is-invalid': errors.is_tds_paid}" placeholder="TDS Paid ?" v-model="transaction.is_tds_paid">
                                                <option value="">Select One</option>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
                                            <span v-if="errors.is_tds_paid" class="invalid-feedback">{{ errors.is_tds_paid[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="attachment">Attachment</label>
                                            <input type="file" class="form-control form-control-sm" :class="{'is-invalid': errors.attachment}" @change="uploadAttachment($event)">
                                            <span v-if="errors.attachment" class="invalid-feedback">{{ errors.attachment[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="note">Note</label>
                                            <textarea type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.note}" placeholder="Note" v-model="transaction.note"></textarea>
                                            <span v-if="errors.note" class="invalid-feedback">{{ errors.note[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button class="btn btn-success" type="submit">
                                        <i class="fas fa-save"></i> Submit 
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "Transactions.Deposit",
        data() {
            return {
                status:true,
                transaction:{
                    transaction_id:'',
                    transaction_type:'Deposit',
                    customer_id:'',
                    customer_name:'',
                    amount:'',
                    status:'',
                    reference_id:'',
                    payment_mode_id:'',
                    is_tds_paid:'',
                    attachment:'',
                    note:''
                },
                payment_modes:[],
                errors: [],
            };
        },

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                let uri = { uri: "showTransaction", data: { transaction_id: to.params.transaction_id } };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.transaction = response.data.data;
                    vm.getPaymentModes()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            });
        },

        methods: {
            getPaymentModes() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "paymentModes" })
                .then(function (response) {
                    vm.payment_modes = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            uploadAttachment(e) {
                let vm = this;
                vm.transaction.attachment= e.target.files[0]
            },

            updateWithdraw() {
                let vm = this;
                const formData = new FormData()
                formData.append('transaction_id', vm.transaction.transaction_id)
                formData.append('user_id', vm.$store.getters.user.user_id)
                formData.append('branch_id', vm.$store.getters.user.branch_id)
                formData.append('reference_id', vm.transaction.reference_id)
                formData.append('payment_mode_id', vm.transaction.payment_mode_id)
                formData.append('is_tds_paid', vm.transaction.is_tds_paid)
                formData.append('note', vm.transaction.note)
                formData.append('attachment', vm.transaction.attachment)
                let loader = vm.$loading.show();
                let uri = { uri: "transactions/updateWithdraw", data: formData };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Withdraw information is successfully updated");
                    vm.$router.push("/dashboard");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            }
        },
    };
</script>
